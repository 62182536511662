import * as regex from '../../constants/regex'

export const isValidEmail = v => regex.EMAIL_REGEX.test(v)
export const isZipCode = v => regex.ZIP_CODE_REGEX.test(v)
export const isAlpha = v => regex.ALPHA_REGEX.test(v)
export const hasSpecialChars = v => regex.SPECIAL_CHAR_REGEX.test(v)
export const isDlDisplayNameValid = v => regex.DL_DISPLAY_NAME_REGEX.test(v)
export const isFirstCharCapital = v => regex.FIRST_CHAR_CAPITAL_REGEX.test(v)
export const hasTwoLetterCapital = v => regex.TWO_LETTER_CAPITAL_REGEX.test(v)
export const isNumber = v => regex.NUMBER_REGEX.test(v)
export const hasPeriod = v => regex.HAS_PERIOD_REGEX.test(v)
export const allLowerCase = v => regex.ALL_LOWER_CASE_REGEX.test(v)
export const hasNumbersOnly = v => regex.NUMBERS_ONLY_REGEX.test(v)

// Helper method to run on save configuration
export function* runValidator(validations, lineOfBusiness) {
  const validationResponses = yield validations
  const errorMsg = validationResponses.find(e => !!e)

  if (errorMsg) {
    const error = {
      ...errorMsg,
      ...(lineOfBusiness && { lineOfBusiness })
    }
    throw error
  }

  return false
}

export const getValidationError = (validations, state, item) =>
  validations.reduce((errorMsg, val) => {
    // Only run if error hasn't been found yet
    if (!errorMsg) {
      const errorResp = val.validation(state, item ?? val.values)

      if (errorResp) {
        // Error can be returned as a string or object
        const error =
          typeof errorResp === 'string' ? { message: errorResp } : errorResp

        return {
          // Use errorResp for error by default
          error,
          ...val
        }
      }
    }

    return errorMsg
  }, null) || {}
