export const lobSortOrder = {
  internet: 0,
  voice: 1,
  tv: 2,
  smartoffice: 3
}

export const lobTitles = {
  internet: 'Business Internet',
  voice: 'Business Voice',
  tv: 'Business Video',
  smartoffice: 'SmartOffice'
}

export const additionalLobTitles = {
  internet: 'Additional Internet Services',
  voice: 'Additional Voice Services',
  tv: 'Additional Video Services',
  smartoffice: 'Additional SmartOffice Services'
}

export const NO_NAME_PRODUCT = 'Unrecognized Product'
