import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { arrayToObject, sortBy } from '../../../utils/core'
import FormField from '../../form/FormField'
import Form from '../../form/Form'
import Button from '../../elements/Button'
import { bundleBuilderFieldPropType } from '../../../propTypes'

const BundleBuilderForm = ({ onCancel, onProceed, ...props }) => {
  const [bundleForm, setBundleForm] = useState(null)
  const [fields, setFields] = useState(arrayToObject(props.fields))
  const fieldArray = Object.values(fields).sort(sortBy('weight'))
  const hasSelectedField = fieldArray.some(({ isSelected }) => isSelected)

  function handleOnFieldChange(id, target) {
    const isCheckbox = target.type === 'checkbox'

    if (isCheckbox) {
      setFields(state => ({
        ...state,
        ...fields,
        [id]: {
          ...fields[id],
          isSelected: target.checked,
          ...(target.checked && { comment: '' }) // Clear value on unselect
        }
      }))
    } else {
      setFields(state => ({
        ...state,
        ...fields,
        [id]: {
          ...fields[id],
          comment: target.value
        }
      }))
    }
  }

  function renderField(field) {
    return (
      <StyledFieldContainer key={field.id}>
        <StyledCheckboxContainer>
          <div>{field.text}</div>
          <FormField.Checkbox
            name={`${field.id}-checkbox`}
            checked={field.isSelected}
            error={!hasSelectedField}
            isSubmitted={false}
            onChange={({ target }) => handleOnFieldChange(field.id, target)}
            ariaLabel={field.text}
          />
        </StyledCheckboxContainer>
        {field.isSelected && field.isCommentRequired && (
          <StyledTextAreaContainer>
            <StyledLabel>
              <span>&#42;</span> <strong>{field.commentLabel}</strong>:
            </StyledLabel>
            <StyledTextArea
              name={`${field.id}-textarea`}
              placeholder={field.commentPlaceholder}
              value={field.comment}
              error={!field.comment.trim()}
              isSubmitted={false}
              onChange={({ target }) => handleOnFieldChange(field.id, target)}
              ariaLabel={field.commentLabel}
            />
          </StyledTextAreaContainer>
        )}
      </StyledFieldContainer>
    )
  }

  return (
    <Form
      name="bundle-builder"
      form={bundleForm}
      isSubmitted
      onChange={({ form }) => setBundleForm(form)}
    >
      <h3>
        Please select your reason for routing to Bundle Builder for this order:
      </h3>
      <StyledFormContainer>{fieldArray.map(renderField)}</StyledFormContainer>
      <StyledLabel>
        <span>&#42;</span>
        <strong>At least one reason must be selected to proceed</strong>
      </StyledLabel>
      <StyledFooter>
        <StyledButton
          type="primary"
          onClick={onCancel}
        >
          Cancel
        </StyledButton>
        <StyledButton
          type="primary"
          disabled={!bundleForm || bundleForm.isInvalid}
          onClick={() => onProceed(fields, false)}
        >
          Proceed To Bundle Builder
        </StyledButton>
      </StyledFooter>
    </Form>
  )
}

BundleBuilderForm.propTypes = {
  fields: PropTypes.arrayOf(bundleBuilderFieldPropType),
  onCancel: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired
}

BundleBuilderForm.defaultProps = {
  fields: []
}

const StyledFormContainer = styled.div`
  margin: 30px 0;
`

const StyledFieldContainer = styled.div`
  margin-bottom: 1rem;
`

const StyledCheckboxContainer = styled.div`
  display: flex;

  > :first-child {
    flex: 1;
  }
`

const StyledTextAreaContainer = styled.div`
  margin: 10px auto;
  width: 80%;
`

const StyledLabel = styled.div`
  margin: 0 0 5px 0;

  span {
    color: ${({ theme }) => theme.colors?.danger};
  }
`

const StyledTextArea = styled(FormField.TextArea)`
  textarea {
    height: 95px;
  }
`

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

const StyledButton = styled(Button)`
  text-transform: uppercase;
  margin-left: 10px;
`

export default BundleBuilderForm
