import axios from 'axios'
import {
  POST_CUST_SERVICES_URL,
  GET_CUSTOMER_ORDER_STATUS_URL,
  GET_ORDER_SUBMIT_DETAILS,
  POST_SUBMIT_UPDATE_SF_URL,
  POST_REFRESH_OPPORTUNITY_DETAILS,
  GET_PROJECT_CODES_URL,
  GET_ORDER_MOVE_DETAILS_URL,
  SAVE_ORDER_MOVE_DETAILS_URL,
  SAVE_CONTINUE_BAN_MOVE_URL,
  CANCEL_BAN_MOVE_DETAILS_URL,
  GET_BAN_DETAILS_URL
} from '../constants/urls'
import { createRedirectError, getApiErrorMessage } from '../utils/error'
import {
  parseProductDetailsModel,
  parseMovesDetailInfo,
  parseNewServicesModel
} from '../utils/order'
import mockDocTypes from '../__tests__/__mocks__/data/documentUploadTypes'
import { parseSchedulingCalendars } from '../utils/scheduling'
import { stringifyQueryString } from '../utils/navigation'
import { banErrorMessage, banNotFoundMessage } from '../constants/order'

export async function getOrderServices({ fxbuyflowSessionId }) {
  try {
    const { data: res } = await axios.post(POST_CUST_SERVICES_URL, {
      fxbuyflowSessionId
    })
    const {
      buyflowContext,
      existingServices,
      newServices,
      additionalServices
    } = res.data

    return {
      buyflowContext,
      additionalServices,
      services: existingServices
        ? parseProductDetailsModel({
          ...existingServices,
          showBundleBuilderLink: newServices.showBundleBuilderLink
        })
        : parseNewServicesModel(res.data)
    }
  } catch (error) {
    throw createRedirectError(error)
  }
}

export async function getOrderStatus(body) {
  const { data } = await axios.post(GET_CUSTOMER_ORDER_STATUS_URL, body)
  return data
}

export async function getDocumentTypes() {
  // TODO update with real endpoint
  // const {data} = await axios.get()
  // return data

  return mockDocTypes
}

export async function processOrder(url, body, isOrderSubmit = false) {
  const { data: res } = await axios.post(url, body)

  if (isOrderSubmit) {
    axios.post(POST_SUBMIT_UPDATE_SF_URL, {
      sessionId: body.sessionId,
      opportunityId: body.opportunityId,
      orderStatus: res.status,
      fxbuyflowSessionId: body.fxbuyflowSessionId
    })
  }

  return res.data
    ? parseSchedulingCalendars({ status: res.status, ...res.data })
    : res
}

export async function refreshOpportunityDetails(fxbuyflowSessionId) {
  try {
    axios.post(POST_REFRESH_OPPORTUNITY_DETAILS, {
      fxbuyflowSessionId
    })
    return true
  } catch {
    return true
  }
}

export async function getOrderDetails(body) {
  try {
    const { data } = await axios.post(GET_ORDER_SUBMIT_DETAILS, body)
    return data
  } catch {
    return { orderSubmitRequest: {} }
  }
}

export async function getProjectCodes(
  fxbuyflowSessionId,
  searchText,
  searchField = 'name'
) {
  try {
    const queryString = stringifyQueryString({
      fxbuyflowSessionId,
      ...(!!searchText && { searchText, searchField })
    })
    const { data: res } = await axios.get(
      `${GET_PROJECT_CODES_URL}?${queryString}`
    )
    return res.data || []
  } catch {
    return []
  }
}

export async function getOrderMoveDetails(fxbuyflowSessionId) {
  try {
    const { data: res } = await axios.post(GET_ORDER_MOVE_DETAILS_URL, {
      fxbuyflowSessionId
    })

    return parseMovesDetailInfo(res.data)
  } catch {
    // default values
    return {
      previousSiteAddress: '',
      previousBillingAccountNumber: '',
      newAddress: '',
      previousBusinessName: '',
      newBusinessName: '',
      isMoveSelected: false,
      isNameChangeSelected: false,
      isMoveOrder: false
    }
  }
}

export async function saveOrderMoveDetails(request) {
  try {
    const { data: res } = await axios.post(SAVE_ORDER_MOVE_DETAILS_URL, request)
    return res.data
  } catch (error) {
    const errorMsg = getApiErrorMessage(error)
    throw new Error(errorMsg?.description)
  }
}

export async function getOrderMoveExistingServices(payload) {
  try {
    const { data: res } = await axios.post(GET_BAN_DETAILS_URL, payload)
    const movesServices = res.data?.movesServices
    if (movesServices?.previousSiteAddress) {
      return {
        services: parseProductDetailsModel(res.data.existingServices),
        existingLocationInfo: {
          previousBusinessName: movesServices?.previousBusinessName ?? '',
          previousSiteAddress: movesServices?.previousSiteAddress ?? ''
        }
      }
    }
    return {
      message: banErrorMessage
    }
  } catch {
    return {
      message: banNotFoundMessage
    }
  }
}

export async function saveOrderBanMoveDetails(payload) {
  try {
    const { data: res } = await axios.post(SAVE_CONTINUE_BAN_MOVE_URL, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function cancelOrderBanMoveDetails(payload) {
  try {
    const { data: res } = await axios.post(CANCEL_BAN_MOVE_DETAILS_URL, payload)
    return res.data
  } catch (error) {
    return false
  }
}
