import { takeLatest, put, select } from 'redux-saga/effects'
import * as actions from '../../actions'
import videoValidations from '../../utils/validations/tv'
import { configFieldNames } from '../../constants/form'
import { getValidationError } from '../../utils/validations/core'

export function* validateX1Saga({ payload }) {
  const state = yield select()
  const { item } = payload
  const { validations } = videoValidations
  const { error } = getValidationError(validations.x1, state, item)

  if (error) {
    yield put(
      actions.setConfigError(configFieldNames.tv, {
        message: error.message,
        onOkAction: error.onOkAction,
        onCancelAction: error.onCancelAction,
        payload
      })
    )
  } else {
    yield put(actions.configX1(item))
  }
}

export function* validateTvEquipmentSaga({ payload }) {
  const state = yield select()
  const { item, lineItem } = payload
  const { validations } = videoValidations
  const { error } = getValidationError(validations.tvEquipment, state, item)

  if (error) {
    yield put(
      actions.setConfigError(configFieldNames.tv, {
        message: error.message,
        onOkAction: error.onOkAction,
        onCancelAction: error.onCancelAction,
        payload
      })
    )
  } else {
    yield put(
      actions.updateConfigItem({
        item,
        lineItem
      })
    )
  }
}

export function* validateMDTASaga() {
  const state = yield select()
  const { tv = {} } = state.configuration || {}
  if (tv?.configuredItems) {
    const { error } = getValidationError(
      videoValidations.validations.mDTA,
      state
    )

    yield put(actions.clearConfigError(configFieldNames.tv))

    if (error) {
      yield put(actions.setConfigError(configFieldNames.tv, error))
      return error
    }
  }

  return false
}

export function* validateBoltonPackageAdded() {
  const state = yield select()
  const { error } = getValidationError(
    videoValidations.validations.videoPackageAdded,
    state
  )

  yield put(actions.clearConfigError(configFieldNames.tv))

  if (error) {
    yield put(actions.setConfigError(configFieldNames.tv, error))
    return error
  }
  return false
}

export function* validateSaveTv() {
  return [yield* validateBoltonPackageAdded(), yield* validateMDTASaga()]
}

function* rootSaga() {
  yield takeLatest(actions.VALIDATE_X1, validateX1Saga)
  yield takeLatest(actions.VALIDATE_TV_EQUIPMENT, validateTvEquipmentSaga)
}

export default rootSaga
