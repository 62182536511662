import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { arrayToObject, sortBy } from '../../../utils/core'
import Form from '../../form/Form'
import Button from '../../elements/Button'
import { bundleBuilderFieldPropType } from '../../../propTypes'
import Select from '../../elements/Select'

const LimitBundleBuilderForm = ({
  onCancel,
  onProceed,
  showSrTicketError,
  ...props
}) => {
  const [bundleForm, setBundleForm] = useState(null)
  const [fields, setFields] = useState(arrayToObject(props.fields))
  const fieldArray = Object.values(fields).sort(sortBy('weight'))
  const hasSelectedField = fieldArray.some(({ isSelected }) => isSelected)

  function handleOnChange({ target }) {
    const updatedFields = fieldArray.map(field => ({
      ...field,
      isSelected: field.id.toString() === target.value
    }))

    setFields(arrayToObject(updatedFields))
  }

  function handleContinueQuote() {
    if (hasSelectedField) {
      return onProceed(fieldArray, true)
    }
    return onCancel()
  }

  return (
    <Form
      name="bundle-builder-limit"
      form={bundleForm}
      isSubmitted
      onChange={({ form }) => setBundleForm(form)}
    >
      <StyledHeaderMessage>
        Click Continue to Quote to proceed to Frictionless. If the customer is
        unable to be quoted through Frictionless, select a Bundle Builder
        routing reason before clicking Continue to Quote.
      </StyledHeaderMessage>
      {!!showSrTicketError && (
        <StyledErrorMessage>
          No Remedy ticket has been created for an issue with this Opportunity.
          Please submit a Remedy ticket before continuing to Bundle Builder.
        </StyledErrorMessage>
      )}
      <StyledFormContainer>
        <StyledSelect
          id="bundle-builder-routing-select"
          onChange={handleOnChange}
        >
          <option value="">None</option>
          {fieldArray.map(field => (
            <option
              value={field.id}
              key={field.id}
            >
              {field.text}
            </option>
          ))}
        </StyledSelect>
      </StyledFormContainer>
      <StyledFooter>
        <StyledButton
          type="primary"
          onClick={() => handleContinueQuote()}
        >
          Continue to Quote
        </StyledButton>
      </StyledFooter>
    </Form>
  )
}

LimitBundleBuilderForm.propTypes = {
  fields: PropTypes.arrayOf(bundleBuilderFieldPropType),
  onCancel: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  showSrTicketError: PropTypes.bool
}

LimitBundleBuilderForm.defaultProps = {
  fields: []
}

const StyledFormContainer = styled.div`
  margin: 30px 0;
`

const StyledHeaderMessage = styled.p`
  font-size: 0.95rem;
`

const StyledSelect = styled(Select)`
  width: 438px;
  margin: 0 3.8rem;
`

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
`

const StyledButton = styled(Button)`
  text-transform: uppercase;
  margin-left: 10px;
`

const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors?.danger};
  margin: 10px 0;
  font-style: italic;
  font-size: 0.95rem;
`

export default LimitBundleBuilderForm
