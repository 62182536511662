export const GET_OFFERS = 'GET_OFFERS'
export const SET_ALL_OFFERS = 'SET_ALL_OFFERS'
export const SET_SELECTED_OFFER = 'SET_SELECTED_OFFER'
export const SELECT_OFFER = 'SELECT_OFFER'
export const SAVE_OFFER = 'SAVE_OFFER'
export const SET_OFFER_FILTERS = 'SET_OFFER_FILTERS'
export const SET_OFFER_SORT = 'SET_OFFER_SORT'
export const UPDATE_OFFER_FILTERS = 'UPDATE_OFFER_FILTERS'
export const SET_OFFER_REQUEST_DATA = 'SET_OFFER_REQUEST_DATA'
export const GET_OFFER_DEFAULTS = 'GET_OFFER_DEFAULTS'
export const UPDATE_OFFER = 'UPDATE_OFFER'
export const UPDATE_OFFER_FILTER_VISIBILITY = 'UPDATE_OFFER_FILTER_VISIBILITY'

export function getOffers() {
  return { type: GET_OFFERS }
}

export function setOffers(offers, shouldReplace) {
  return {
    type: SET_ALL_OFFERS,
    payload: { offers, shouldReplace }
  }
}

export function selectOffer(offer) {
  return {
    type: SELECT_OFFER,
    payload: { offer }
  }
}

export function setSelectedOffer(offer) {
  return {
    type: SET_SELECTED_OFFER,
    payload: { offer }
  }
}

export function saveOffer(currentRouteId) {
  return {
    type: SAVE_OFFER,
    payload: { currentRouteId }
  }
}

export function setOfferFilters(filters) {
  return {
    type: SET_OFFER_FILTERS,
    payload: { filters }
  }
}

export function setOfferSort(sort) {
  return {
    type: SET_OFFER_SORT,
    payload: { sort }
  }
}

export function updateOfferFilters(id, data) {
  return {
    type: UPDATE_OFFER_FILTERS,
    payload: { id, data }
  }
}

export function updateOfferFilterVisibility(id, isVisible) {
  return {
    type: UPDATE_OFFER_FILTER_VISIBILITY,
    payload: { id, isVisible }
  }
}

export function setOfferRequestData(payload) {
  return {
    type: SET_OFFER_REQUEST_DATA,
    payload
  }
}

export function getOfferDefaults() {
  return {
    type: GET_OFFER_DEFAULTS
  }
}

export function updateOffer(payload) {
  return {
    type: UPDATE_OFFER,
    payload
  }
}
