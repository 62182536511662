import { createMatchSelector } from 'connected-react-router'
import {
  sections as appSections,
  salesforcePath,
  orderRoutes
} from '../constants/navigation'
import { voiceSections } from '../constants/configuration'
import { selectCurrentDocAcceptanceType } from './document'
import { workflowStepIds } from '../constants/session'

const { orders: ordersSection } = appSections

export const selectSections = ({ navigation }) => {
  const { sections, routes } = navigation

  return Object.entries(sections).reduce(
    (all, [id, section]) => ({
      ...all,
      [id]: {
        ...section,
        routes: Object.entries(routes).reduce((allRoutes, [routeId, route]) => {
          if (route.section === section.id) {
            return { ...allRoutes, [routeId]: route }
          }
          return allRoutes
        }, {})
      }
    }),
    {}
  )
}

export const selectRoutes = ({ navigation }, section = ordersSection.id) => {
  const { routes } = navigation

  return Object.values(routes).reduce((allRoutes, route) => {
    if (section === route.section) {
      return { ...allRoutes, [route.id]: route }
    }
    return allRoutes
  }, {})
}

export const selectRoutesAsArray = (state, section = ordersSection.id) =>
  Object.values(selectRoutes(state, section)).sort((a, b) => a.index - b.index)

export const selectRouterMatch = state => {
  const { pathname } = state.router.location
  const path = pathname.includes(salesforcePath) ? salesforcePath : ''
  const ordersMatchSelector = createMatchSelector(`${path}/orders/:pageId`)
  return ordersMatchSelector(state) || {}
}

export const selectIsContinueDisabled = ({
  router,
  configuration,
  ...state
}) => {
  const { pageId } = router.location?.match?.params || {}
  const workflowStepId = state.session?.workflowStepId
  const acceptedDocument = selectCurrentDocAcceptanceType(state)

  switch (pageId) {
    case orderRoutes.configure.id: {
      const { currentSectionIndex = 0 } = configuration.voice || {}
      const hasVoice = !!configuration.voice

      if (
        workflowStepId >= workflowStepIds.PENDING_CONTRACT_ACCEPTANCE ||
        acceptedDocument
      ) {
        return false
      }

      return !!(hasVoice && currentSectionIndex < voiceSections.configValidated)
    }
    default: {
      return false
    }
  }
}
