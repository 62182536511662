import { put, select } from 'redux-saga/effects'
import * as actions from '../../actions'
import { configFieldNames } from '../../constants/form'
import officeValidation from '../../utils/validations/office'
import { getValidationError } from '../../utils/validations/core'

export function* validateSmartOffice() {
  const state = yield select()
  const { error } = getValidationError(officeValidation.validations, state)

  if (error) {
    yield put(actions.setConfigError(configFieldNames.office, error))
    return error
  }

  return false
}

export function* validateSaveSmartOffice() {
  // Call validations
  return [yield* validateSmartOffice()]
}
