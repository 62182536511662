import { all, takeLatest, put, select } from 'redux-saga/effects'
import voiceValidationSagas, { validateSaveVoice } from './voice'
import tvValidationSagas, { validateSaveTv } from './tv'
import { validateSaveInternet } from './internet'
import { validateSaveSmartOffice } from './office'
import { validateConfiguration } from './configuration'
import * as actions from '../../actions'
import { orderRoutes } from '../../constants/navigation'
import { configFieldNames } from '../../constants/form'
import { runValidator } from '../../utils/validations/core'
import { configTypes } from '../../constants/configuration'
import { selectIsAdvancedMoveOrder } from '../../selectors/order'
import { getMoveValidationError, getUnSelectedMoveLobs } from '../../utils/cart'
import { sortLinesOfBusiness } from '../../utils/configuration'

export function* validationDismissSaga({ payload }) {
  const { action, ...dismissPayload } = payload

  yield put(actions.resetForm(orderRoutes.configure.id))
  if (action) {
    const actionTypes = Array.isArray(action) ? action : [action]

    yield all(
      actionTypes.map(actionType =>
        put({
          type: actionType,
          payload: dismissPayload
        })
      )
    )
  }
}

export function* clearConfigErrorSaga({ type }) {
  switch (type) {
    case actions.CLEAR_CONFIG_ERROR_TV: {
      return yield put(actions.clearConfigError(configFieldNames.tv))
    }
    case actions.CLEAR_CONFIG_ERROR_INTERNET: {
      return yield put(actions.clearConfigError(configFieldNames.internet))
    }
    default: {
      return false
    }
  }
}

export function* handleEdgeEmailAlertSaga({ payload }) {
  const { name } = payload

  yield put(
    actions.setCustomerContact({
      hasOptedOutOfEmail: false
    })
  )
  yield put(actions.clearConfigError(name))
}

// Runs all validations for each LOB and throws error when one is found
export function* validateAll() {
  const { configuration } = yield select()
  const isAdvancedMoveOrder = yield select(selectIsAdvancedMoveOrder)

  try {
    // Works bc we only use form errors for BV but would need updated if expanded to other LOBs
    yield runValidator(validateConfiguration(), configTypes.voice)

    if (configuration.internet) {
      yield runValidator(validateSaveInternet(), configTypes.internet)
    }

    if (configuration.tv) {
      yield runValidator(validateSaveTv(), configTypes.tv)
    }

    if (configuration.voice) {
      yield runValidator(validateSaveVoice(), configTypes.voice)
    }

    if (configuration.smartoffice) {
      yield runValidator(validateSaveSmartOffice(), configTypes.office)
    }

    if (isAdvancedMoveOrder) {
      yield* validateConfigMoveSelections()
    }
  } catch (error) {
    if (error.lineOfBusiness) {
      yield put(
        actions.setConfigAccordionSections({ [error.lineOfBusiness]: true })
      )
    }
    throw error
  }
}

export function* validateConfigMoveSelections() {
  const configCart = yield select(({ cart }) => cart.summary)
  const { movesPartialDisconnectLobs = [] } = configCart.offer || {}

  const [unSelectedLobList, unSelectedDateLobList] = getUnSelectedMoveLobs(
    configCart.linesOfBusiness,
    movesPartialDisconnectLobs
  )

  const hasErrors = !!(unSelectedLobList.length || unSelectedDateLobList.length)
  if (hasErrors) {
    const error = getMoveValidationError(
      unSelectedLobList,
      unSelectedDateLobList
    )
    yield put(actions.setConfigError(configFieldNames.core, error))

    const [firstLineOfBusiness] = sortLinesOfBusiness([
      ...unSelectedLobList,
      ...unSelectedDateLobList
    ])
    yield put(
      actions.setConfigAccordionSections({ [firstLineOfBusiness]: true })
    )
    throw error
  }

  return false
}

export default function* rootSaga() {
  yield takeLatest(actions.HANDLE_VALIDATION_ALERT, validationDismissSaga)
  yield takeLatest(
    [actions.CLEAR_CONFIG_ERROR_TV, actions.CLEAR_CONFIG_ERROR_INTERNET],
    clearConfigErrorSaga
  )
  yield takeLatest(actions.HANDLE_EDGE_EMAIL_ALERT, handleEdgeEmailAlertSaga)
  yield all([voiceValidationSagas(), tvValidationSagas()])
}
