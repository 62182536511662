import styled, { css } from 'styled-components'
import icon from '../../assets/caretDown.svg'

const selectStyles = {
  small: css`
    height: 29px;
    font-size: 0.9rem;
    line-height: 0.9rem;
    padding-right: 30px;
    background-size: 1.1rem;
    background-position: right 4px center;
  `
}

const errorStyle = css`
  border: 2px solid ${({ theme }) => theme.colors?.danger};
`

const Select = styled.select.attrs(props => ({
  ...props,
  'aria-label': props.ariaLabel || props.name
}))`
  height: 36px;
  min-width: 220px;
  padding: 5px 45px 5px 10px;
  font-size: 1rem;
  line-height: 1rem;
  background-position: right 8px center;
  background-size: auto;
  font-weight: 500;
  color: ${({ theme }) => theme.colors?.inputText};
  border: 2px solid ${({ theme }) => theme.colors?.inputBorder};
  background-color: ${({ theme }) => theme.colors?.inputBackground};
  border-radius: 0;
  appearance: none;
  background-image: url(${icon});
  background-repeat: no-repeat;

  ${({ error }) => error && errorStyle}

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors?.primary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors?.disabled};
    opacity: 0.7;
    cursor: not-allowed;
  }

  ${({ size }) => selectStyles[size]}
`

export default Select
