export function toggleAllFilters(items, isSelected = true) {
  return items.map(filter =>
    filter.filters
      ? {
          ...filter,
          filters: toggleAllFilters(filter.filters, isSelected)
        }
      : { ...filter, isSelected }
  )
}

export function getFiltersByCategory(filters) {
  const groupedFilters = groupedByCategory(filters, 'categoryName')
  const hasCategory = !(Object.keys(groupedFilters).length === 0)

  if (hasCategory) {
    return Object.entries(groupedFilters).reduce((all, [key, lobFilters]) => {
      return [...all, { name: key, id: key, filters: lobFilters }]
    }, [])
  }

  return filters
}

function groupedByCategory(arr, key) {
  return arr.reduce((acc, item) => {
    if (item[key]) {
      return {
        ...acc,
        [item[key]]: [...(acc[item[key]] || []), item]
      }
    }
    return acc
  }, {})
}
