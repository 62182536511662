import { takeLatest, put, select } from 'redux-saga/effects'
import * as actions from '../actions'

export function* clearFormFieldStatusesSaga({ payload }) {
  const { formId, name } = payload
  const { activeFormId } = yield select(({ forms }) => forms)

  yield put(
    actions.updateForm({
      formName: formId || activeFormId,
      field: {
        name,
        error: false,
        success: false,
        warning: false
      }
    })
  )
}

export function* setFormFieldSaga({ payload }) {
  const { name, error, formId, ...field } = payload
  const { activeFormId } = yield select(({ forms }) => forms)

  yield put(
    actions.updateForm({
      formName: formId || activeFormId,
      isSubmitted: true,
      field: {
        name,
        error,
        ...field
      }
    })
  )
}

function* rootSaga() {
  yield takeLatest(
    actions.CLEAR_FORM_FIELD_STATUSES,
    clearFormFieldStatusesSaga
  )
  yield takeLatest(actions.SET_FORM_FIELD, setFormFieldSaga)
}

export default rootSaga
