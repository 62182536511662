import queryString from 'query-string'
import { bundleBuilderRedirectUrls } from '../constants/navigation'
import { getEnv } from './env'

const PAGE_PARAM_REGEX = /\/:pageId/

// Removes sections from end of path
export function truncatePath(path, amount = 1) {
  return path
    .split('/')
    .slice(0, -Math.abs(amount))
    .join('/')
}

export function parseQueryString(q) {
  return queryString.parse(q)
}

export function stringifyQueryString(q) {
  return queryString.stringify(q, { encode: false })
}

export function getNextRoute(routes, id) {
  const routeIndex = routes.findIndex(route => route.id === id)
  return routeIndex >= 0 ? routes[routeIndex + 1] : null
}

export function navigateParentWindow(url) {
  window.top.location.href = url
}

export function openParentWindow(url, target = '_blank') {
  const EC_NAVIGATION = { url, target }
  window.parent.postMessage({ EC_NAVIGATION }, '*')
}

export function getSalesforceURLs(path, paths = {}) {
  return Object.entries(paths).reduce(
    (urls, [key, value]) => ({
      ...urls,
      [key]: path + value
    }),
    {}
  )
}

export const constructOrdersPath = (pathname, path) =>
  PAGE_PARAM_REGEX.test(pathname)
    ? pathname.replace(PAGE_PARAM_REGEX, path)
    : `${pathname}${path}`

// Needed for IE support
export function downloadFile(
  data,
  fileName,
  fileType = 'application/octet-stream'
) {
  const binary = atob(data.replace(/\s/g, ''))
  const len = binary.length
  const buffer = new ArrayBuffer(len)
  const view = new Uint8Array(buffer)

  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i)
  }

  const blob = new Blob([view], { type: fileType })

  // IE
  if (window.navigator.msSaveOrOpenBlob) {
    // Decode base64 string, remove space for IE compatibility
    // https://stackoverflow.com/questions/36036280/base64-representing-pdf-to-blob-javascript
    window.navigator.msSaveOrOpenBlob(blob, fileName)
  } else {
    const anchor = document.createElement('a')
    anchor.download = fileName
    anchor.href = (window.webkitURL || window.URL).createObjectURL(blob)
    anchor.target = '_blank'
    anchor.dataset.downloadurl = `${fileType}:${anchor.download}:${anchor.href}`
    anchor.click()
    anchor.remove()
  }
}

export function getRedirectBundleBuilderUrlInfo(keyString) {
  const { HOST_ENV } = getEnv()
  const [opportunityId, sKey] = atob(keyString).split('/')
  const baseUrl = bundleBuilderRedirectUrls[HOST_ENV]

  return [`${baseUrl}${opportunityId}`, sKey]
}
