import * as actions from '../../actions'
import { errors } from '../content'
import { MODIFY_QUOTE } from '../configuration'

export default {
  validOfferError: {
    message: MODIFY_QUOTE,
    onOkAction: actions.CONFIRM_MODIFY_QUOTE,
    onCancelAction: actions.RESUBMIT_CANCEL_MODIFY_QUOTE
  },
  invalidOfferError: {
    message: errors.modifyQuoteOfferInvalid,
    onOkAction: actions.NAV_CONFIG_INVALID_OFFER,
    onCancelAction: actions.RESUBMIT_CANCEL_MODIFY_QUOTE
  },
  disconnectReasonError:
    'Disconnect Reason is required when a Line of Business is being removed.'
}
