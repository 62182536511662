import exactMath from 'exact-math'

// Default args to 0 if null/undefined
const addDefaultArgs = fn => (...args) => fn(...args.map(a => a || 0))

export const addDecimals = addDefaultArgs(exactMath.add)
export const subDecimals = addDefaultArgs(exactMath.sub)
export const divideDecimals = addDefaultArgs(exactMath.div)
export const multiplyDecimals = addDefaultArgs(exactMath.mul)

export const decimals = {
  add: addDecimals,
  sub: subDecimals,
  div: divideDecimals,
  mul: multiplyDecimals
}
