import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import RcCheckbox from 'rc-checkbox'

const Checkbox = ({ className, children, ...props }) => (
  <StyledCheckboxContainer
    className="checkbox-wrapper"
    size={props.size}
  >
    {props.showLabel && (
      <StyledCheckboxLabel
        htmlFor={props.ariaLabel || props.name}
        className={className}
        disabled={props.disabled}
      >
        {props.ariaLabel || props.name}
      </StyledCheckboxLabel>
    )}
    <StyledCheckbox
      id={props.name}
      prefixCls="checkbox"
      aria-label={props.ariaLabel || props.name}
      {...props}
    />
    {children && <StyledSpan error={props.error}>{children}</StyledSpan>}
  </StyledCheckboxContainer>
)

Checkbox.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
  showLabel: PropTypes.bool
}

const getCursor = ({ disabled }) => (disabled ? 'not-allowed' : 'pointer')
const getBorderColor = ({ theme, error }) =>
  error ? theme.colors?.danger : theme.colors?.inputBorder

const checkboxHeights = {
  default: '16px',
  large: '35px'
}

const StyledCheckboxContainer = styled.div`
  height: ${({ size }) => checkboxHeights[size] || checkboxHeights.default};
  line-height: ${({ size }) =>
    checkboxHeights[size] || checkboxHeights.default};
`

const StyledCheckboxLabel = styled.label`
  display: inline-block;
  cursor: ${getCursor};
`

const StyledCheckbox = styled(RcCheckbox)`
  /* Default */
  display: inline-block;
  white-space: nowrap;
  outline: none;
  position: relative;
  line-height: 1rem;
  vertical-align: middle;
  cursor: ${getCursor};
  height: ${checkboxHeights.default};

  .checkbox-inner {
    position: relative;
    top: -1px;
    left: 0;
    display: inline-block;
    width: ${checkboxHeights.default};
    height: ${checkboxHeights.default};
    background-color: ${({ theme }) => theme.colors?.inputBackground};
    border-width: 1px;
    border-style: solid;
    border-color: ${getBorderColor};
  }

  .checkbox-inner:after {
    position: absolute;
    display: block;
    left: 5px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: 2px solid transparent;
    border-top: 0;
    border-left: 0;
    content: ' ';
    transform: rotate(45deg);
  }

  .checkbox-input {
    position: absolute;
    left: 0;
    z-index: 1;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    cursor: ${getCursor};
    height: ${checkboxHeights.default};
    width: ${checkboxHeights.default};
  }

  /* Hover/Focus State */
  .checkbox-input:focus + .checkbox-inner {
    border-color: ${({ theme }) => theme.colors?.primary};
  }

  &:not(.checkbox-checked) .checkbox-input:hover + .checkbox-inner:after {
    border-color: ${({ theme }) => theme.colors?.light};
  }

  /* Checked */
  &.checkbox-checked .checkbox-inner {
    border-width: 2px;
    border-color: ${getBorderColor};
  }

  &.checkbox-checked .checkbox-inner:after {
    top: 1px;
    left: 3.5px;
    border-width: 1.5px;
    border-color: ${getBorderColor};
  }

  /* Disabled */
  &.checkbox-disabled .checkbox-inner {
    border-color: ${getBorderColor};
    background-color: ${({ theme }) => theme.colors?.light};
    opacity: 0.7;
  }

  &.checkbox-disabled.checkbox-checked .checkbox-inner:after {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors?.text};
  }

  &.checkbox-disabled .checkbox-inner-input,
  &.checkbox-disabled + span {
    cursor: ${getCursor};
  }

  ${({ size }) => checkboxStyles[size]};
`

const StyledSpan = styled.span`
  margin-left: 5px;
  line-height: ${checkboxHeights.default};
  cursor: ${getCursor};
`
/* Checkbox Size Styles */
const checkboxStyles = {
  large: css`
    padding-top: 1px;
    height: ${checkboxHeights.large};

    .checkbox-inner,
    .checkbox-input {
      width: ${checkboxHeights.large};
      height: ${checkboxHeights.large};
      border-width: 2px;
    }

    .checkbox-inner {
      border-width: 2px;
    }

    .checkbox-inner:after,
    &.checkbox-checked .checkbox-inner:after {
      width: 11.5px;
      height: 21px;
      top: 2px;
      left: 10px;
    }

    & + ${StyledSpan} {
      line-height: ${checkboxHeights.large};
    }
  `
}

export default Checkbox
