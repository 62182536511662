import { takeLatest, put, call, select } from 'redux-saga/effects'
import { getCalendar } from '../services/scheduling'
import {
  getUpdatedCalendarDate,
  getFilteredCalendar
} from '../utils/scheduling'
import * as actions from '../actions'
import * as formErrorIds from '../constants/form'
import {
  ABT_ADDRESS_CREATE_SUCCESS,
  ABT_REQUIRED,
  GENESIS_IHAT
} from '../constants/scheduling'
import { orderSubmitMessages } from '../constants/loading'
import { errors, success } from '../constants/content'

export function* getCalendarSaga() {
  try {
    const { fxbuyflowSessionId } = yield select(({ session }) => session)

    yield put(actions.togglePageLoading())
    yield put(actions.clearFormMessage(formErrorIds.timeSlot))
    yield put(actions.clearFormMessage(formErrorIds.abtMessage))
    yield put(actions.clearFormMessage(formErrorIds.genesisIhatMessage))

    let calendar = yield call(getCalendar, { fxbuyflowSessionId })

    if (calendar.installMessageCodes?.includes(ABT_REQUIRED)) {
      yield put(
        actions.updatePageLoadingMessage(
          orderSubmitMessages.abtCreatingHouseKey
        )
      )
      calendar = yield call(getCalendar, { fxbuyflowSessionId })

      if (calendar.installMessageCodes?.includes(ABT_ADDRESS_CREATE_SUCCESS)) {
        yield put(
          actions.setGlobalSuccess(formErrorIds.abtMessage, success.abtMessage)
        )
      } else {
        yield put(
          actions.setGlobalError(formErrorIds.abtMessage, errors.abtMessage)
        )
      }
    }

    if (calendar.installMessageCodes?.includes(GENESIS_IHAT)) {
      yield put(
        actions.setGlobalWarning(
          formErrorIds.genesisIhatMessage,
          calendar.genesisIhatMessage
        )
      )
    }

    const filteredCalendar = getFilteredCalendar(calendar)

    if (calendar.newTimeslotSelectionMessage) {
      yield put(
        actions.setGlobalWarning(
          formErrorIds.timeSlot,
          calendar.newTimeslotSelectionMessage
        )
      )
    }
    yield put(actions.setCalendar(filteredCalendar))
  } catch (error) {
    // TODO: Need to figure out how we are supposed to handle this
    yield put(
      actions.setCalendar({
        installationMessage: 'Error retrieving calendar dates'
      })
    )
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

export function* setCalendarDateSaga({ payload }) {
  const { calendarType, selectedDate } = payload
  const state = yield select()

  yield put(actions.updateSelectedCalendarDate(calendarType, selectedDate))
  const calendarItem = getUpdatedCalendarDate(state, payload)

  if (calendarItem) {
    const { calType, updatedDate } = calendarItem
    yield put(actions.updateSelectedCalendarDate(calType, updatedDate))
  }
}

function* rootSaga() {
  yield takeLatest(actions.GET_CALENDAR, getCalendarSaga)
  yield takeLatest(actions.SET_CALENDAR_DATE, setCalendarDateSaga)
}

export default rootSaga
