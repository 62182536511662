import React, { createContext, useContext } from 'react'

export const FormContext = createContext([
  { form: {} },
  {
    fieldMethods: {
      updateField: () => null,
      submitField: () => null
    }
  }
])

export const useForm = fieldName => {
  const [form, { formMethods, fieldMethods }] = useContext(FormContext)

  function fieldMethod(method) {
    return (...args) => method(fieldName, ...args)
  }

  // Scope values/methods for field name
  if (fieldName) {
    return [
      {
        ...(form.fields ? form.fields[fieldName] : {}),
        form
      },
      {
        ...formMethods,
        updateField: fieldMethod(fieldMethods.updateField),
        submitField: fieldMethod(fieldMethods.submitField)
      }
    ]
  }

  return [form, formMethods]
}

export const FormProvider = props => <FormContext.Provider {...props} />
