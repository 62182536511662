import axios from 'axios'
import {
  GET_SMARTOFFICE_PERMIT_BANNER_URL,
  POST_TN_MANAGEMENT_DIRECTORY_LISTINGS_URL,
  POST_TN_MANAGEMENT_NATIVE,
  POST_TN_MANAGEMENT_PORTABLE,
  POST_BBLABELEMAIL_PATH
} from '../constants/urls'
import {
  CRCP_RESTRICTED,
  VOICE_LINE_API_ERROR
} from '../constants/configuration'

export async function getHeaderLookups(body) {
  const { data: res } = await axios.post(
    POST_TN_MANAGEMENT_DIRECTORY_LISTINGS_URL,
    body
  )
  return res.data.directoryListings
}

export async function getNativeRateCenterDetails(params) {
  let data = null

  try {
    const { data: res } = await axios.post(POST_TN_MANAGEMENT_NATIVE, params)
    data = res.data
  } catch {
    throw new Error(VOICE_LINE_API_ERROR)
  }

  if (!data.rateCenterDetails.length) {
    throw new Error(
      CRCP_RESTRICTED.replace('<STATE_ABBREVIATION>', params.state)
    )
  }

  return data
}

export async function sendBBLabelEmail(params) {
  let data = null

  try {
    const { data: res } = await axios.post(
      POST_BBLABELEMAIL_PATH,
      params
    )
    data = res.data
  } catch (error) {
    data = "Email not sent: Internal error. Please wait a few minutes and try again. If this continues to happen, please create a trouble ticket."
  }
  return data
}

export async function getPortableRateCenterDetails(params) {
  const { data: res } = await axios.post(POST_TN_MANAGEMENT_PORTABLE, params)
  return res.data
}

export async function getSmartOfficePermitBanner(sessionId) {
  try {
    const { data: res } = await axios.get(
      `${GET_SMARTOFFICE_PERMIT_BANNER_URL}?fxbuyflowSessionId=${sessionId}`
    )
    return { smartOfficePermitBanner: res.data }
  } catch {
    return {
      smartOfficePermitBanner: {
        message: 'Permit Required',
        helpText:
          'If the location does NOT require a permit with the local municipality, it could result in a faster time-frame to schedule the SmartOffice installation',
        colorType: 1
      }
    }
  }
}
