import { takeLatest, put, call, select, all, take } from 'redux-saga/effects'
import * as actions from '../actions'
import * as documentServices from '../services/documents'
import { getContractFileName } from '../utils/documents'
import { downloadFile } from '../utils/navigation'
import { orderRouteIds } from '../constants/navigation'
import {
  docAcceptance,
  docSave,
  confirmCustBilling,
  docDelete,
  resubmitDocsExpired
} from '../constants/form'
import { errors, success } from '../constants/content'
import { documentTypes, documentStatus } from '../constants/documents'
import { selectAccountName, selectCustomerDetails } from '../selectors/customer'
import { selectCurrentDocAcceptanceType } from '../selectors/document'
import { workflowStepIds } from '../constants/session'

export function* verifyByPhoneSaga({ payload }) {
  const { agreed } = payload
  const customer = yield select(selectCustomerDetails)

  yield put(actions.clearFormError(docSave))
  yield put(actions.clearFormError(confirmCustBilling))
  yield put(actions.clearFormError(resubmitDocsExpired))

  if (agreed) {
    try {
      const accountName = yield select(selectAccountName)
      const { opportunityId, sessionId, fxbuyflowSessionId } = yield select(
        ({ session }) => session
      )
      yield put(actions.clearFormError(docAcceptance))
      yield put(actions.togglePageLoading())

      if (customer.isUpdated) {
        yield put(actions.saveCustomer(customer))
        yield take(actions.SET_CUSTOMER)
      }

      yield call(documentServices.verifyByPhone, {
        opportunityId,
        sessionId,
        accountName: getContractFileName(accountName),
        fxbuyflowSessionId
      })
      yield put(actions.setGlobalSuccess(docSave, success.docsVerifyByPhone))
      yield put(
        actions.setDocumentStatus({
          docType: documentTypes.phone,
          status: documentStatus.approved
        })
      )
    } catch ({ message }) {
      yield put(actions.setGlobalError(docSave, message || errors.docSave))
    } finally {
      yield put(actions.togglePageLoading(false))
    }
  } else {
    yield put(actions.setGlobalError(docAcceptance, errors.docsDecline))
  }
}

export function* generatePdfSaga() {
  const { opportunityId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )
  const customer = yield select(selectCustomerDetails)
  try {
    yield put(actions.clearFormError(docAcceptance))
    yield put(actions.clearFormError(docSave))
    yield put(actions.clearFormError(confirmCustBilling))
    yield put(actions.clearFormError(resubmitDocsExpired))
    yield put(actions.togglePageLoading())

    if (customer.isUpdated) {
      yield put(actions.saveCustomer(customer))
      yield take(actions.SET_CUSTOMER)
    }

    const guid = yield call(documentServices.generatePdf, {
      opportunityId,
      fxbuyflowSessionId
    })
    yield put(actions.setPdfGuid(guid))

    const pdfData = yield call(documentServices.getPdf, guid)
    yield put(actions.setPdfData(pdfData))
  } catch {
    yield put(actions.setGlobalError(docSave, errors.docSave))
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

export function* sendProposalSaga() {
  const { opportunityId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )
  const customer = yield select(selectCustomerDetails)
  try {
    yield put(actions.togglePageLoading())
    yield put(actions.clearDocumentStatus())
    yield put(actions.clearFormError(docSave))
    yield put(actions.clearFormError(resubmitDocsExpired))

    if (customer.isUpdated) {
      yield put(actions.saveCustomer(customer))
      yield take(actions.SET_CUSTOMER)
    }
    const proposalSent = yield call(documentServices.sendProposal, {
      opportunityId,
      fxbuyflowSessionId
    })

    if (proposalSent) {
      yield put(
        actions.setDocumentStatus({
          docType: documentTypes.send,
          status: documentStatus.pending
        })
      )
      yield put(actions.setGlobalSuccess(docSave, success.sendProposal))
    }
  } catch {
    yield put(actions.setGlobalError(docSave, errors.docSave))
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

export function* previewProposalSaga() {
  const { opportunityId } = yield select(({ session }) => session)
  const preview = yield call(documentServices.getPreviewProposal, {
    opportunityId
  })

  yield put(actions.setPreviewProposal(preview))
}

export function* saveDocumentsSaga({ payload }) {
  const { opportunityId, sessionId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )
  const postPayload = {
    ...payload,
    OpportunityId: opportunityId,
    SessionId: sessionId,
    fxbuyflowSessionId
  }
  try {
    yield call(documentServices.uploadDocuments, postPayload)
    yield put(actions.getDocuments())
  } catch {
    yield put(
      actions.setFormErrorAlert('uploadDocApiError', errors.documentUpload)
    )
  } finally {
    yield put(actions.toggleDocumentIsUploading())
  }
}

export function* checkDocumentAcceptanceSaga({ payload }) {
  const { form, isSignNow } = payload

  if (!form.errors[docAcceptance]) {
    try {
      const { opportunityId, sessionId, fxbuyflowSessionId } = yield select(
        ({ session }) => session
      )
      const { multiSite } = yield select(selectCustomerDetails)
      const { isMultiSiteToggleEnabled } = yield select(({ order }) => order)

      yield put(actions.clearFormError(docSave))
      yield put(actions.clearFormError(confirmCustBilling))
      yield put(actions.clearFormError(resubmitDocsExpired))
      yield put(actions.togglePageLoading())
      const { isAccepted, message } = yield call(
        documentServices.checkDocumentAcceptance,
        {
          opportunityId,
          sessionId,
          fxbuyflowSessionId,
          ...(!!multiSite && {
            multisite: {
              projectCode: multiSite.projectCode,
              projectId: multiSite.projectId,
              masterAccount: multiSite.masterAccount || '',
              isMultiSiteToggleEnabled
            }
          }),
          isSignNow
        }
      )

      if (isAccepted) {
        yield put(actions.navigateToNextOrderRoute(orderRouteIds.documents))
      } else {
        yield put(actions.setGlobalError(docSave, message))
      }
    } catch ({ message }) {
      yield put(actions.setGlobalError(docSave, message || errors.docSave))
    } finally {
      yield put(actions.togglePageLoading(false))
    }
  }
}

export function* getDocumentsSaga() {
  const { opportunityId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )

  const data = yield call(documentServices.getDocuments, {
    opportunityId,
    fxbuyflowSessionId
  })

  yield put(actions.updateDocuments(data))
}

export function* deleteDocumentSaga({ payload }) {
  const { uploaded } = yield select(({ documents }) => documents)
  const { sessionId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )
  try {
    yield put(actions.clearFormError(docDelete))

    yield call(documentServices.deleteDocument, {
      id: payload.id,
      SessionId: sessionId,
      fxbuyflowSessionId
    })

    const updatedDocuments = uploaded.filter(doc => doc.id !== payload.id)

    yield put(actions.updateDocuments(updatedDocuments))
  } catch {
    yield put(actions.setGlobalError(docDelete, errors.docDelete))
  }
}

export function* getAvailableDocumentsSaga({ payload } = {}) {
  const {
    accountNumber,
    opportunityId,
    sessionId,
    fxbuyflowSessionId
  } = yield select(({ session }) => session)

  const { message } = payload || {}

  try {
    if (message) {
      yield put(actions.togglePageLoading(message))
    } else {
      yield put(actions.togglePageLoading())
    }

    const { selectedDocAcceptance, availableDocAcceptanceTypes } = yield call(
      documentServices.getAvailableDocumentAcceptance,
      {
        opportunityId,
        sessionId,
        accountNumber,
        fxbuyflowSessionId
      }
    )

    yield all(
      Object.values(documentTypes).map(docType =>
        put(
          actions.setAvailableDocument(
            docType,
            availableDocAcceptanceTypes.includes(docType)
          )
        )
      )
    )
    if (selectedDocAcceptance) {
      yield put(
        actions.setDocumentStatus({
          docType: selectedDocAcceptance.docAcceptanceType,
          status: selectedDocAcceptance.status
        })
      )
    }
  } catch (error) {
    yield put(actions.setFormErrorAlert('getAvailableDocuments', error.message))
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

export function* clearDocumentStatusSaga() {
  const { id: docType } = yield select(selectCurrentDocAcceptanceType)
  if (docType) {
    yield put(actions.setDocumentStatus({ docType, status: false }))
  }

  yield put(actions.clearFormError('modifyQuote', 'configure'))
  yield put(actions.clearFormError('modifyQuoteValidOffer', 'configure'))
  yield put(actions.clearFormError('modifyQuoteInvalidOffer', 'configure'))
}

export function* cancelModifyQuoteSaga() {
  yield put(actions.clearFormError('modifyQuote', 'configure'))
  yield put(actions.clearFormError('modifyQuoteValidOffer', 'configure'))
  yield put(actions.clearFormError('modifyQuoteInvalidOffer', 'configure'))
}

export function* confirmModifyQuoteSaga() {
  const { opportunityId, sessionId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )

  yield call(
    documentServices.expireDocuments,
    opportunityId,
    sessionId,
    fxbuyflowSessionId
  )
  yield put(actions.clearDocumentStatus())
  yield put(
    actions.setSessionData({
      workflowStepId: workflowStepIds.PENDING_CONFIGURATION
    })
  )
}

export function* getMissingDocumentsSaga() {
  yield put(actions.setMissingDocuments([]))
  const { fxbuyflowSessionId } = yield select(({ session }) => session)
  const data = yield call(
    documentServices.getMissingDocuments,
    fxbuyflowSessionId
  )

  yield put(actions.setMissingDocuments(data))
}

export function* downloadDocumentSaga({ payload }) {
  const { document } = payload
  const { sessionId } = yield select(({ session }) => session)

  yield put(actions.togglePageLoading())
  const { documentName, content } = yield call(
    documentServices.getDocumentData,
    document.id,
    sessionId
  )
  downloadFile(content, documentName)
  yield put(actions.togglePageLoading(false))
}

export function* selectDocTypeSaga({ payload }) {
  const { docType } = payload

  if (docType.id === documentTypes.signNow) {
    const { opportunityId, fxbuyflowSessionId } = yield select(
      ({ session }) => session
    )

    // For testing below URL run "npx serve -s test -p 5050" in terminal
    // const url = 'http://localhost:5050'
    yield put(actions.togglePageLoading())
    const { data: url } = yield call(documentServices.signNow, {
      opportunityId,
      fxbuyflowSessionId
    })

    const bseeUrl = `${url}&sourceSystem=EC`

    yield put(actions.setSignNowURL(bseeUrl))
    yield put(actions.togglePageLoading(false))
  }

  return yield false
}

export function* signNowDocAcceptanceSaga({ payload }) {
  const { isDocumentSigned = false } = payload || {}

  if (isDocumentSigned) {
    yield put(actions.clearDocumentStatus())
    yield put(
      actions.setDocumentStatus({
        docType: documentTypes.signNow,
        status: documentStatus.pending
      })
    )
  }

  yield put(actions.saveOrder({ isContinue: true, isSignNow: true }))
}

export function* generateMoveFormPdfSaga() {
  const { opportunityId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )

  try {
    yield put(actions.clearFormError(docAcceptance))
    yield put(actions.clearFormError(docSave))
    yield put(actions.clearFormError(confirmCustBilling))
    yield put(actions.clearFormError(resubmitDocsExpired))
    yield put(actions.togglePageLoading())
    yield put(actions.setMoveFormPdfData(''))

    const guid = yield call(documentServices.generateMoveFormPdf, {
      opportunityId,
      fxbuyflowSessionId
    })
    yield put(actions.setMoveFormPdfGuid(guid))

    const pdfData = yield call(documentServices.getPdf, guid)
    yield put(actions.setMoveFormPdfData(pdfData))
  } catch {
    yield put(actions.setGlobalError(docSave, errors.docSave))
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

export function* refreshDocumentAcceptanceSaga() {
  yield put(actions.resetDocTypes())
  yield put(
    actions.getAvailableDocumentAcceptance({
      message: 'Refreshing Document Acceptance methods...'
    })
  )
}

function* rootSaga() {
  yield takeLatest(actions.VERIFY_BY_PHONE, verifyByPhoneSaga)
  yield takeLatest(actions.GENERATE_PDF, generatePdfSaga)
  yield takeLatest(actions.SEND_PROPOSAL, sendProposalSaga)
  yield takeLatest(actions.GET_PREVIEW_PROPOSAL, previewProposalSaga)
  yield takeLatest(
    actions.CHECK_DOCUMENT_ACCEPTANCE,
    checkDocumentAcceptanceSaga
  )
  yield takeLatest(actions.SAVE_DOCUMENTS, saveDocumentsSaga)
  yield takeLatest(actions.GET_DOCUMENTS, getDocumentsSaga)
  yield takeLatest(actions.DELETE_DOCUMENT, deleteDocumentSaga)
  yield takeLatest(actions.GET_AVAILABLE_DOCUMENTS, getAvailableDocumentsSaga)
  yield takeLatest(actions.CLEAR_DOCUMENT_STATUS, clearDocumentStatusSaga)
  yield takeLatest(actions.CANCEL_MODIFY_QUOTE, cancelModifyQuoteSaga)
  yield takeLatest(actions.CONFIRM_MODIFY_QUOTE, confirmModifyQuoteSaga)
  yield takeLatest(actions.GET_MISSING_DOCUMENTS, getMissingDocumentsSaga)
  yield takeLatest(actions.DOWNLOAD_DOCUMENT, downloadDocumentSaga)
  yield takeLatest(actions.SELECT_DOC_TYPE, selectDocTypeSaga)
  yield takeLatest(actions.SIGN_NOW_DOC_ACCEPTANCE, signNowDocAcceptanceSaga)
  yield takeLatest(actions.GENERATE_MOVE_FORM_PDF, generateMoveFormPdfSaga)
  yield takeLatest(
    actions.REFRESH_DOCUMENT_ACCEPTANCE,
    refreshDocumentAcceptanceSaga
  )
}

export default rootSaga
