import { orderRoutes } from '../constants/navigation'

export const UPDATE_FORM = 'UPDATE_FORM'
export const SET_ACTIVE_FORM = 'SET_ACTIVE_FORM'
export const CLEAR_FORM_FIELD_STATUSES = 'CLEAR_FORM_FIELD_STATUSES'
export const SET_CONFIG_FORM_ERROR = 'SET_CONFIG_FORM_ERROR'
export const CLEAR_CONFIG_ERROR_TV = 'CLEAR_CONFIG_ERROR_TV'
export const SET_FORM_FIELD = 'SET_FORM_FIELD'

export function updateForm(form) {
  return {
    type: UPDATE_FORM,
    payload: {
      form
    }
  }
}

export function setActiveForm(id) {
  return {
    type: SET_ACTIVE_FORM,
    payload: {
      id
    }
  }
}

export function resetForm(id) {
  return {
    type: UPDATE_FORM,
    payload: {
      id,
      form: { isSubmitted: false }
    }
  }
}

export function setFormField(name, field, formId) {
  return {
    type: SET_FORM_FIELD,
    payload: {
      name,
      formId,
      ...field
    }
  }
}

export function setFormError(name, error, formId) {
  return {
    type: SET_FORM_FIELD,
    payload: {
      name,
      error,
      isSubmitted: true,
      formId
    }
  }
}

export function setConfigError(name, error) {
  return setFormErrorAlert(name, error, orderRoutes.configure.id)
}

export function setFormAlert(name, alert, type) {
  const alertMessage = typeof alert === 'string' ? { message: alert } : alert

  return setFormField(name, {
    [type]: {
      name,
      onOkAction: CLEAR_FORM_FIELD_STATUSES,
      ...alertMessage,
      isAlert: true
    }
  })
}

export function setFormErrorAlert(name, error) {
  return setFormAlert(name, error, 'error')
}

export function setSuccessAlert(name, success) {
  return setFormAlert(name, success, 'success')
}

export function setWarningAlert(name, warning) {
  return setFormAlert(name, warning, 'warning')
}

export function setGlobalError(name, error, formId) {
  return {
    type: SET_FORM_FIELD,
    payload: {
      name,
      error,
      formId,
      success: false,
      isGlobal: true,
      isSubmitted: true
    }
  }
}

export function setGlobalWarning(name, warning) {
  return {
    type: SET_FORM_FIELD,
    payload: {
      name,
      warning,
      success: false,
      isGlobal: true,
      isSubmitted: true
    }
  }
}

export function setGlobalSuccess(name, success) {
  return {
    type: SET_FORM_FIELD,
    payload: {
      name,
      success,
      error: false,
      isGlobal: true,
      isSubmitted: true
    }
  }
}

export function clearFormMessage(name, formId) {
  return {
    type: CLEAR_FORM_FIELD_STATUSES,
    payload: {
      name,
      formId
    }
  }
}

export function clearFormError(name, formId) {
  return clearFormMessage(name, formId)
}

export const clearConfigError = name =>
  clearFormError(name, orderRoutes.configure.id)
