import * as actions from '../../actions'
import { errors } from '../content'

export default {
  customerOwnedEquipmentError: {
    message: errors.smartOfficeCustomerOwnedEquipment,
    onOkAction: actions.CLEAR_FORM_FIELD_STATUSES
  },
  minimumCamerasError: {
    message: errors.smartOfficeMinimumCameras
  },
  maximumCamerasError: {
    message: errors.smartOfficeMaximumCameras
  }
}
