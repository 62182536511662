import { selectConfigItemByType } from '../../selectors/configuration'
import {
  internetLineItemTypes,
  officeLineItemTypes,
  internetConfigurationItems
} from '../../constants/configuration'
import errors from '../../constants/errors/office'

export default {
  validations: [
    {
      validation: validateInternetEquipment,
      error: errors.customerOwnedEquipmentError
    },
    {
      validation: validateMinimumCameras,
      error: errors.minimumCamerasError
    },
    {
      validation: validateMaximumCameras,
      error: errors.maximumCamerasError,
      values: {
        maximumCameras: 15
      }
    }
  ]
}

export function validateInternetEquipment(state) {
  const selectedInternetEquipment = selectConfigItemByType(
    internetLineItemTypes.internetEquipment
  )(state)

  return (
    selectedInternetEquipment.id ===
    internetConfigurationItems.customerOwnedEquipment
  )
}

export function validateMinimumCameras(state) {
  const indoorCameras = selectConfigItemByType(
    officeLineItemTypes.indoorCameras
  )(state)
  const outdoorCameras = selectConfigItemByType(
    officeLineItemTypes.outdoorCameras
  )(state)

  return !indoorCameras?.quantity && !outdoorCameras?.quantity
}

export function validateMaximumCameras(state, values) {
  const { quantity: indoorCamerasQty = 0 } =
    selectConfigItemByType(officeLineItemTypes.indoorCameras)(state) || {}
  const { quantity: outdoorCamerasQty = 0 } =
    selectConfigItemByType(officeLineItemTypes.outdoorCameras)(state) || {}

  return indoorCamerasQty + outdoorCamerasQty > values.maximumCameras
}
