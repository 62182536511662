import {
  configTypes,
  videoLineItemTypes,
  voiceLineItemTypes
} from '../constants/configuration'

export const VALIDATE_INTERNET = 'VALIDATE_INTERNET'
export const VALIDATE_TV = 'VALIDATE_TV'
export const VALIDATE_VOICE = 'VALIDATE_VOICE'
export const VALIDATE_OFFICE = 'VALIDATE_OFFICE'
export const HANDLE_VALIDATION_ALERT = 'HANDLE_VALIDATION_ALERT'

// Internet
export const VALIDATE_INTERNET_EQUIPMENT = 'VALIDATE_INTERNET_EQUIPMENT'

// Voice
export const VALIDATE_VOICE_SECTION_ONE = 'VALIDATE_VOICE_SECTION_ONE'
export const VALIDATE_VOICE_SECTION_TWO = 'VALIDATE_VOICE_SECTION_TWO'
export const VALIDATE_VOICE_SECTION_THREE = 'VALIDATE_VOICE_SECTION_THREE'
export const voiceSectionActions = [
  VALIDATE_VOICE_SECTION_ONE,
  VALIDATE_VOICE_SECTION_TWO,
  VALIDATE_VOICE_SECTION_THREE
]
export const VALIDATE_VOICE_QUANTITY = 'VALIDATE_VOICE_QUANTITY'

// TV
export const VALIDATE_X1 = 'VALIDATE_X1'
export const VALIDATE_TV_EQUIPMENT = 'VALIDATE_TV_EQUIPMENT'
export const VALIDATE_EMPTY_OUTLETS = 'VALIDATE_EMPTY_OUTLETS'

// All LOBs that can be validated
export const validationLineTypes = {
  [configTypes.internet]: VALIDATE_INTERNET,
  [configTypes.tv]: VALIDATE_TV,
  [configTypes.voice]: VALIDATE_VOICE,
  [configTypes.office]: VALIDATE_OFFICE
}

export const LINE_ITEM_VALIDATION_ACTIONS = {
  [voiceLineItemTypes.voiceLines]: VALIDATE_VOICE_QUANTITY,
  [voiceLineItemTypes.huntGroup]: VALIDATE_VOICE_QUANTITY,
  [voiceLineItemTypes.tollFree]: VALIDATE_VOICE_QUANTITY,
  [voiceLineItemTypes.voiceMail]: VALIDATE_VOICE_QUANTITY,
  [videoLineItemTypes.primaryOutlet]: VALIDATE_TV_EQUIPMENT,
  [videoLineItemTypes.additionalOutlets]: VALIDATE_TV_EQUIPMENT,
  [videoLineItemTypes.x1]: VALIDATE_X1
}

export function validateX1(payload) {
  return {
    type: VALIDATE_X1,
    payload
  }
}

export function validateEmptyOutlets(payload) {
  return {
    type: VALIDATE_EMPTY_OUTLETS,
    payload
  }
}

export function validateConfigItem(lineOfBusiness, payload) {
  return {
    type: validationLineTypes[lineOfBusiness],
    payload
  }
}

export function validateVoiceSection(sectionIndex) {
  return {
    type: voiceSectionActions[sectionIndex - 1]
  }
}

export function handleValidationAlert(action, payload) {
  return {
    type: HANDLE_VALIDATION_ALERT,
    payload: { action, ...payload }
  }
}
