export default [
	{
	  toggleID: '188aef7d-1511-4647-a9aa-448d2b78c728',
	  featureID: '1',
	  division: 'NorthEast',
	  region: null,
	  corp: null,
	  role: null,
	  profileId: null,
	  profileName: null,
	  createdBy: null,
	  createdDate: '12/17/2019 00:00:00',
	  lastModifiedBy: null,
	  lastModifiedDate: '12/18/2019 14:48:17',
	  isActive: false,
	  isArchived: false
	},
	{
	  toggleID: 'e0c90452-7fbc-41a3-bb06-b97ebb477a5a',
	  featureID: '2',
	  division: 'NorthEast',
	  region: null,
	  corp: null,
	  role: null,
	  profileId: null,
	  profileName: null,
	  createdBy: null,
	  createdDate: '09/23/2019 00:00:00',
	  lastModifiedBy: null,
	  lastModifiedDate: '12/09/2019 18:40:51',
	  isActive: false,
	  isArchived: false
	},
	{
	  toggleID: '2ca7c075-b264-41bb-bcb4-70bb7fa7f8cb',
	  featureID: '3',
	  division: 'NorthEast',
	  region: null,
	  corp: null,
	  role: null,
	  profileId: null,
	  profileName: null,
	  createdBy: null,
	  createdDate: '09/24/2019 00:00:00',
	  lastModifiedBy: null,
	  lastModifiedDate: '12/09/2019 18:41:02',
	  isActive: false,
	  isArchived: false
	},
	{
	  toggleID: '9e209dd6-287d-45eb-9296-0ee0676b8b16',
	  featureID: '4',
	  division: 'NorthEast',
	  region: null,
	  corp: null,
	  role: null,
	  profileId: null,
	  profileName: null,
	  createdBy: null,
	  createdDate: '09/23/2019 00:00:00',
	  lastModifiedBy: null,
	  lastModifiedDate: '12/09/2019 18:41:13',
	  isActive: false,
	  isArchived: false
	}
  ]
  