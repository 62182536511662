import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Modal from './Modal'
import { errors } from '../../constants/content'

const errorModal = {
  title: 'Error',
  maskClosable: false,
  allowClose: false,
  width: '450px',
  content: <p>{errors.fatalError}</p>
}

class ErrorLogger extends Component {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    children: PropTypes.node
  }

  state = {
    hasRenderError: false
  }

  static getDerivedStateFromError() {
    return { hasRenderError: true }
  }

  componentDidMount() {
    // Catches all API errors
    axios.interceptors.response.use(
      response => response,
      error => {
        this.logResponseError(error)
        return Promise.reject(error)
      }
    )

    // Catches all non React errors
    window.addEventListener('error', ({ error }) => {
      if (error) {
        this.logStackTrace(error)
        Modal.error(errorModal)
      }
      return true
    })
  }

  // Catches all React render errors
  componentDidCatch(error) {
    this.logStackTrace(error)
    Modal.error({
      ...errorModal,
      className: 'modal-hide-error-footer'
    })
  }

  logStackTrace(error) {
    this.props.onError(error?.stack || error?.message)
  }

  logResponseError(error) {
    const { request, config } = error.response || {}

    this.props.onError({
      url: request?.responseURL,
      method: config?.method,
      data: config?.data,
      status: request?.status,
      response: request?.response
    })
  }

  render() {
    return !this.state.hasRenderError && this.props.children
  }
}

export default ErrorLogger
