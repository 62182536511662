import axios from 'axios'
import { getEnv } from './utils/env'

const { HOST_ENV } = getEnv()
const isLocal = HOST_ENV === 'local'

axios.interceptors.request.use(config => {
  const updatedConfig  = { ...config, timeout: 180000 }
  if (isLocal || config.method !== 'get') {
    return updatedConfig 
  }

  return {
    ...updatedConfig,
    // Invalidates cache for IE
    params: {
      ...config.params,
      ts: Date.now()
    }
  }
})

