import { GET_DOCUMENT_URL } from './urls'

export const paths = {
  opportunity: process.env.REACT_APP_SALESFORCE_OPPORTUNITY_PATH,
  officeCameras: process.env.REACT_APP_SALESFORCE_CAMERA_CART
}

export const urls = {
  helpChat: process.env.REACT_APP_HELP_CHAT_URL,
  viewDocument: GET_DOCUMENT_URL
}
