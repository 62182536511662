import React, { createContext, useContext } from 'react'
import { connect } from 'react-redux'
import {
  openParentWindow,
  navigateParentWindow,
  downloadFile
} from '../utils/navigation'

export const ParentWindowContext = createContext({
  urls: {},
  open: null,
  navigate: null,
  downloadFile
})

const useParentWindow = () => {
  const { urls } = useContext(ParentWindowContext)

  return {
    urls,
    open: openParentWindow,
    navigate: navigateParentWindow,
    downloadFile
  }
}

export const ParentWindowProvider = connect(state => ({
  urls: state.session.salesforce.urls
}))(({ children, ...props }) => (
  <ParentWindowContext.Provider value={props}>
    {children}
  </ParentWindowContext.Provider>
))

export default useParentWindow
