export function getFormErrors(fields = {}) {
  return Object.entries(fields).reduce(
    (errors, [name, { error }]) => ({
      ...errors,
      ...(error && { [name]: error })
    }),
    {}
  )
}

const checkedInputTypes = ['checkbox', 'radio']
export function getTargetValue(target) {
  const { type, value, checked } = target

  return checkedInputTypes.includes(type) ? checked : value
}
