import {
  takeEvery,
  call,
  take,
  put,
  delay,
  race,
  cancel
} from 'redux-saga/effects'
import * as actions from '../actions/polling'

export function* pollingSaga({ payload }) {
  while (true) {
    const { pollingAction, interval, data, timeoutDate } = payload

    if (timeoutDate <= new Date()) {
      yield cancel()
    }

    yield put({ type: pollingAction, payload: data })
    yield delay(interval)
  }
}

export function* rootPollingSaga(action) {
  while (true) {
    const { cancelAction } = yield race({
      pollResponse: call(pollingSaga, action),
      cancelAction: take(actions.STOP_POLLING)
    })

    if (action.payload.id === cancelAction.payload.id) {
      yield cancel()
    }

    return false
  }
}

function* rootSaga() {
  yield takeEvery(actions.START_POLLING, rootPollingSaga)
}

export default rootSaga
