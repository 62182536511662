import { configTypes, professionalInstallId } from '../constants/configuration'
import { selectHasExistingOfferPackage } from '../selectors/offers'
import { NO_NAME_SHOPPING_ELEMENT_REGEX } from '../constants/regex'
import { NO_NAME_PRODUCT } from '../constants/orderservices'

export function parseProductDetailsModel(services) {
  const packageOffer = services.isPackageOffer
    ? {
        name: services.packageDetails.packageName,
        prices: services.packageDetails.packagePrices
      }
    : {}

  const upsellLobs = (services.upsellDetails || []).reduce(
    (upsellOffers, upsell) => {
      return {
        ...upsellOffers,
        [upsell.lineOfBusiness]: {
          ...upsell,
          quantity: 1,
          name: upsell.upsellName,
          prices: upsell.upsellPrices,
          isUpsellItem: true
        }
      }
    },
    {}
  )

  const isPackageOffer =
    selectHasExistingOfferPackage(services) || services.isPackageOffer

  const model = {
    offerPackage: {},
    ...services,
    offer: packageOffer,
    productDetails: services.cartItems.reduce(
      (details, item) => ({
        ...details,
        [item.lineOfBusiness]: {
          ...item,
          cartItemDetails: mapCartItemDetails({
            item,
            upsellLobs,
            isPackageOffer
          })
        }
      }),
      {}
    )
  }

  // TODO: Remove when quote API is updated to use new model
  if (!model.installation) {
    const { cartItemDetails = [] } =
      model.productDetails.additionalOptions || {}

    return {
      ...model,
      installation: cartItemDetails.find(
        ({ id }) => id === professionalInstallId
      ),
      productDetails: {
        ...model.productDetails,
        additionalOptions: {
          ...model.productDetails.additionalOptions,
          cartItemDetails: cartItemDetails.filter(
            ({ id }) => id !== professionalInstallId
          )
        }
      }
    }
  }

  return model
}

export function mapCartItemNames(cartItems) {
  return cartItems.map(details => ({
    ...details,
    name: NO_NAME_SHOPPING_ELEMENT_REGEX.test(details.name)
      ? NO_NAME_PRODUCT
      : details.name
  }))
}

function mapCartItemDetails({ item, upsellLobs, isPackageOffer }) {
  const { lineOfBusiness: lob } = item

  if (isPackageOffer) {
    const upsellOffer = upsellLobs[lob]
    return mapCartItemNames(
      upsellOffer
        ? [upsellOffer, ...item.cartItemDetails]
        : item.cartItemDetails
    )
  }

  const shouldAddOffer = item.offer && lob !== configTypes.voice
  return mapCartItemNames(
    shouldAddOffer
      ? [item.offer, ...item.cartItemDetails]
      : item.cartItemDetails
  )
}

export function parseMovesDetailInfo(moveDetails) {
  const {
    newBusinessName = '',
    previousBusinessName = ''
  } = moveDetails?.businessNameChangeInfo

  const {
    newAddress = '',
    previousBillingAccountNumber = '',
    previousSiteAddress = ''
  } = moveDetails?.movesInfo

  return {
    previousSiteAddress: previousSiteAddress ?? '',
    previousBillingAccountNumber: previousBillingAccountNumber ?? '',
    newAddress: newAddress ?? '',
    previousBusinessName: previousBusinessName ?? '',
    newBusinessName: newBusinessName ?? '',
    isMoveSelected: moveDetails?.isMoveOrder && !!previousSiteAddress,
    isNameChangeSelected: moveDetails?.isBusinessNameChange,
    isMoveOrder: moveDetails?.isMoveOrder,
    isComcastTN: moveDetails?.isComcastTN,
    existingServices: moveDetails?.existingServices
      ? parseProductDetailsModel(moveDetails?.existingServices)
      : null,
    isBanCheckMoveOrder: moveDetails?.isBanCheckMoveOrder,
    isBanNameChangeSelected: moveDetails?.isBanNameChangeSelected,
    billingAccountNumber: moveDetails?.billingAccountNumber || '',
    isAdvancedMoveOrder: moveDetails?.isAdvancedMoveOrder ?? '',
    existingLocationInfo: moveDetails?.existingLocationInfo ?? {}
  }
}

export function getSaveMoveDetailsRequest(moveDetails) {
  return {
    opportunityId: moveDetails?.opportunityId,
    fxbuyflowSessionId: moveDetails?.fxbuyflowSessionId,
    isMoveOrder: moveDetails.isMoveOrder,
    isBusinessNameChange: moveDetails.isNameChangeSelected,
    movesInfo: {
      previousSiteAddress: moveDetails.previousSiteAddress || null,
      previousBillingAccountNumber:
        moveDetails.previousBillingAccountNumber || null,
      newAddress: moveDetails.newAddress || null
    },
    businessNameChangeInfo: {
      previousBusinessName: moveDetails.previousBusinessName || null,
      newBusinessName: moveDetails.newBusinessName || null
    },
    ...(moveDetails?.redirectToConfig && { isComcastTnReset: true })
  }
}

export function parseNewServicesModel(orderservices) {
  const { movesServices, newServices } = orderservices || {}

  if (movesServices?.isAdvancedMoveOrder) {
    return {
      ...newServices,
      sessionId: movesServices.sessionId
    }
  }

  return newServices
}
