import {
  all,
  takeEvery,
  select,
  put,
  fork,
  call,
  takeLatest
} from 'redux-saga/effects'
import * as actions from '../../actions'
import internetConfigSagas from './internet'
import tvConfigSagas from './tv'
import voiceConfigSagas from './voice'
import officeConfigSagas from './office'
import {
  selectBoltOnByLOB,
  selectInitialConfiguredVoiceLines,
  selectLineItemByType,
  selectVoiceAdditionalInfo
} from '../../selectors/configuration'
import { arrayToObject } from '../../utils/core'
import {
  getConfigItemsArray,
  mapConfigurationItems,
  getSyntheticId,
  configurationParser,
  checkSmartOfficeInCartItems
} from '../../utils/configuration'
import {
  MODIFY_QUOTE_PENDING_ESIG,
  MODIFY_QUOTE,
  configTypes,
  installLineItemTypes
} from '../../constants/configuration'
import { documentTypes, documentStatus } from '../../constants/documents'
import { selectCurrentDocAcceptanceType } from '../../selectors/document'
import { getOfferValidity } from '../../services/offers'
import modifyQuoteErrors from '../../constants/errors/configuration'
import { selectIsOrderRejected } from '../../selectors/order'
import { orderRoutes } from '../../constants/navigation'
import { appTypes, workflowStepIds } from '../../constants/session'
import { getResubmitWorkflowStep } from '../../services/session'
import { resubmitDocsExpired, configFieldNames } from '../../constants/form'
import { parseCartSummary } from '../../utils/cart'
import { upsellConfiguration } from '../../services/cart'
import {
  selectConfiguredCart,
  selectShouldConfirmSIKRemoval,
  selectIsSIKSelected
} from '../../selectors/cart'
import { errors } from '../../constants/content'
import { sendBBLabelEmail } from '../../services/configuration'

export function configurationLineItemSaga(lineItemSagas = {}) {
  return function* configurationSaga({ payload }) {
    const { lineItem, byPassConfirm, actionType, item } = payload
    const { type } = lineItem

    if (!byPassConfirm) {
      const confirmSik = yield select(selectShouldConfirmSIKRemoval(item))
      if (confirmSik && actionType && actionType !== actions.CONFIG_ON_MOUNT) {
        return yield put(
          actions.setWarningAlert(configFieldNames.internet, {
            message: errors.sikEligibility,
            onOkAction: [
              actions.configurationTypes[lineItem.lineOfBusiness],
              actions.CLEAR_FORM_FIELD_STATUSES
            ],
            payload: { ...payload, byPassConfirm: true }
          })
        )
      }
    }

    // if there is a validation action for this type, call put(VALIDATION_ACTION)
    if (actions.LINE_ITEM_VALIDATION_ACTIONS[type]) {
      return yield put({
        type: actions.LINE_ITEM_VALIDATION_ACTIONS[type],
        payload
      })
    }

    if (lineItemSagas[type]) {
      // If a specific saga is found run it
      return yield fork(lineItemSagas[type], { payload })
    }
    // Default call (generic setter)
    return yield put(actions.updateConfigItem(payload))
  }
}

export function* updateConfigItemSaga({ payload }) {
  let { item } = payload
  const currentLineItem = yield select(selectLineItemByType(item.lineItemType))
  const lineItem = payload.lineItem || currentLineItem
  const options = getConfigItemsArray(lineItem)
  const [firstOption] = options

  if (item.hasAncillaryPrices) {
    const selectedAncillary = item.ancillaryPrices.find(
      a => a.priceReferenceId === item.priceReferenceId
    )

    item = {
      ...item,
      ...(selectedAncillary || {
        priceReferenceId: null,
        isSelected: false,
        quantity: 0,
        monthlyPrice: 0,
        monthlyDiscount: 0,
        monthlyFinalPrice: 0,
        oneTimePrice: 0,
        oneTimeDiscount: 0,
        oneTimeFinalPrice: 0
      })
    }
  }

  if (item.useSyntheticId) {
    const configurationItems = options.map(opt =>
      getSyntheticId(opt) === getSyntheticId(item)
        ? item
        : { ...opt, isSelected: false, quantity: 0 }
    )

    return yield put(
      actions.setConfig({
        ...currentLineItem,
        selectedItem: item,
        configurationItems: mapConfigurationItems(configurationItems)
      })
    )
  }

  // When a selected dropdown option
  if (options.length > 1 && firstOption.maxLimit === 1) {
    const configurationItems = options.map(opt =>
      opt.id === item.id ? item : { ...opt, isSelected: false, quantity: 0 }
    )

    return yield put(
      actions.setConfig({
        ...currentLineItem,
        selectedItem: item,
        configurationItems: arrayToObject(configurationItems)
      })
    )
  }

  return yield put(
    actions.setConfig({
      ...currentLineItem,
      selectedItem: item.isSelected ? item : null,
      configurationItems: {
        ...currentLineItem.configurationItems,
        [item.id]: item
      }
    })
  )
}

export function* disableConfigSaga({ payload }) {
  const { lineItemType, isDisabled } = payload
  const lineItem = yield select(selectLineItemByType(lineItemType))

  yield put(
    actions.setConfig({
      ...lineItem,
      isDisabled
    })
  )
}

export function* updateCartWithConfig({ payload }) {
  yield put(actions.updateCartSummary(payload.lineItem))
}

export function* revertOfferConfiguration() {
  const { selectedOffer } = yield select(({ offers }) => offers)
  yield put(actions.getCartSummary(selectedOffer.id))
}

export function* modifyConfigQuote() {
  const { sessionId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )
  const { offerId } = yield select(({ cart }) => cart.summary)
  const isOrderRejected = yield select(selectIsOrderRejected)

  if (!isOrderRejected) {
    const acceptedDocument = yield select(selectCurrentDocAcceptanceType)
    const modifyQuoteError = {
      message:
        acceptedDocument?.id === documentTypes.send &&
        acceptedDocument?.status === documentStatus.pending
          ? MODIFY_QUOTE_PENDING_ESIG
          : MODIFY_QUOTE,
      onOkAction: actions.CONFIRM_MODIFY_QUOTE,
      onCancelAction: actions.CANCEL_MODIFY_QUOTE
    }
    return yield put(actions.setFormErrorAlert('modifyQuote', modifyQuoteError))
  }

  try {
    yield put(actions.togglePageLoading())
    const { isOfferValid } = yield call(getOfferValidity, {
      sessionId,
      offerId,
      fxbuyflowSessionId
    })

    if (!isOfferValid) {
      return yield put(
        actions.setFormErrorAlert(
          'modifyQuoteInvalidOffer',
          modifyQuoteErrors.invalidOfferError
        )
      )
    }

    return yield put(
      actions.setFormErrorAlert(
        'modifyQuoteValidOffer',
        modifyQuoteErrors.validOfferError
      )
    )
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

export function* navigateConfigInvalidOfferSaga() {
  yield put(actions.navigateToOrderRoute(orderRoutes.offers.id))
  yield put(
    actions.setSessionData({
      workflowStepId: workflowStepIds.PENDING_CONFIGURATION
    })
  )
}

export function* resubmitCancelModifyQuote() {
  const { sessionId, opportunityId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )

  try {
    yield put(actions.togglePageLoading())
    const { continueOrderRoute, description } = yield call(
      getResubmitWorkflowStep,
      {
        sessionId,
        opportunityId,
        fxbuyflowSessionId
      }
    )

    yield put(actions.cancelModifyQuote())
    yield put(actions.navigateToOrderRoute(continueOrderRoute.id))

    if (description) {
      yield put(
        actions.setGlobalError(
          resubmitDocsExpired,
          description,
          continueOrderRoute.id
        )
      )
    }
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

export function* addBoltOnPackageSaga({ payload }) {
  const { offerId, lineOfBusiness, byPassConfirm } = payload
  const { appType } = yield select(({ session }) => session)

  const confirmSik = yield select(selectIsSIKSelected)

  if (confirmSik && !byPassConfirm) {
    return yield put(
      actions.setWarningAlert(configFieldNames.internet, {
        message: errors.sikEligibility,
        onOkAction: [
          actions.ADD_BOLT_ON_PACKAGE,
          actions.CLEAR_FORM_FIELD_STATUSES
        ],
        payload: { ...payload, byPassConfirm: true }
      })
    )
  }

  if (!offerId) {
    return yield put(actions.removeBoltOn(lineOfBusiness))
  }

  try {
    const configCart = yield select(selectConfiguredCart)
    const currentConfig = yield select(({ configuration }) => configuration)

    const cartPayload = {
      ...configCart,
      offer: {
        ...configCart.offer,
        boltOns: configCart.offer.boltOns.map(boltOn => ({
          ...boltOn,
          selectedOfferId:
            lineOfBusiness === boltOn.lineOfBusiness
              ? offerId
              : boltOn.selectedOfferId
        }))
      }
    }

    yield put(actions.togglePageLoading())
    yield put(actions.setCartSummary(undefined))
    yield put(
      actions.replaceConfiguration({
        ...currentConfig,
        hideAllConfigurations: true
      })
    )

    const cart = yield call(upsellConfiguration, cartPayload)
    const hasSmartOffice = checkSmartOfficeInCartItems(cart)
    if (hasSmartOffice && appType === appTypes.NC) {
      yield put(actions.getSmartOfficePermitBanner())
    }
    yield put(actions.setCartSummary(parseCartSummary(cart)))
    yield put(actions.setupConfiguration(cart))
  } finally {
    yield put(actions.togglePageLoading(false))
  }
  return true
}

export function* removeBoltOnSaga({ payload }) {
  const { lineOfBusiness } = payload
  const currentBoltOn = yield select(selectBoltOnByLOB(lineOfBusiness))

  if (!currentBoltOn.selectedOfferId) {
    yield put(actions.toggleConfigBoltOn(lineOfBusiness, false))
  } else {
    try {
      const configCart = yield select(selectConfiguredCart)
      const currentConfig = yield select(({ configuration }) => configuration)

      const cartPayload = {
        ...configCart,
        offer: {
          ...configCart.offer,
          lineOfBusinesses: configCart.offer.lineOfBusinesses.filter(
            lob => lob !== lineOfBusiness
          ),
          boltOns: configCart.offer.boltOns.map(boltOn => ({
            ...boltOn,
            selectedOfferId:
              lineOfBusiness === boltOn.lineOfBusiness
                ? null
                : boltOn.selectedOfferId
          }))
        },
        cartItems: configCart.cartItems.filter(
          item => item.lineOfBusiness !== lineOfBusiness
        )
      }

      yield put(actions.togglePageLoading())
      yield put(
        actions.replaceConfiguration({
          ...currentConfig,
          hideAllConfigurations: true
        })
      )
      const cart = yield call(upsellConfiguration, cartPayload)
      yield put(actions.setCartSummary(parseCartSummary(cart)))
      yield put(actions.setupConfiguration(cart))
    } finally {
      yield put(actions.togglePageLoading(false))
    }
  }
}

export function* setupConfigurationSaga({ payload }) {
  const { configuration } = payload || {}
  const parsedConfig = configurationParser(configuration)
  const voice = parsedConfig[configTypes.voice]

  if (voice) {
    const state = yield select()
    const currentVoice = state.configuration[configTypes.voice]
    const configuredLines = yield select(selectInitialConfiguredVoiceLines)
    const additionalInfo = selectVoiceAdditionalInfo({
      ...state,
      configuration: {
        ...parsedConfig,
        [configTypes.voice]: {
          ...voice,
          configuredItems: {
            ...voice.configuredItems,
            configuredLines
          }
        }
      }
    })

    yield put(
      actions.replaceConfiguration({
        ...parsedConfig,
        [configTypes.voice]: {
          ...voice,
          configuredItems: {
            ...voice.configuredItems,
            configuredLines,
            ...additionalInfo
          },
          validated: currentVoice?.validated,
          lastUpdatedSectionIndex: currentVoice?.lastUpdatedSectionIndex,
          currentSectionIndex: currentVoice?.currentSectionIndex
        }
      })
    )
  } else {
    yield put(actions.replaceConfiguration(parsedConfig))
  }
}

export function* updateConfigInstallSaga({ payload }) {
  const internetConfig = yield select(
    ({ configuration }) => configuration.internet
  )

  if (internetConfig) {
    yield put(actions.updateConfigItem(payload))
  }

  const { item } = payload
  const { lineItem } = payload

  const isProInstall = lineItem.type === installLineItemTypes.proInstall
  const currentInstallSelection = isProInstall
    ? installLineItemTypes.selfInstall
    : installLineItemTypes.proInstall

  const currentInstallLineItem = yield select(
    selectLineItemByType(currentInstallSelection)
  )

  // when there is only proinstall or selfinstall option
  if (!currentInstallLineItem) {
    return true
  }

  const installOptions = getConfigItemsArray(currentInstallLineItem)
  const configurationItems = installOptions.map(opt =>
    getSyntheticId(opt) === getSyntheticId(item)
      ? item
      : { ...opt, isSelected: false, quantity: 0 }
  )

  return yield put(
    actions.setConfig({
      ...currentInstallLineItem,
      selectedItem: null,
      configurationItems: mapConfigurationItems(configurationItems)
    })
  )
}

export function* updateConfigMoveSelectionSaga({ payload }) {
  yield put(actions.updateCartLobMoveSelections(payload))
}

export function* updateConfigPartialDiscMoveSelectionSaga({ payload }) {
  const { lineOfBusiness, statusId, tnLineItem = null } = payload
  const state = yield select()
  const { movesPartialDisconnectLobs } = state.configuration?.offer

  const updatedDisconnectLobs = movesPartialDisconnectLobs.map(lob => {
    if (lob?.lineOfBusiness === lineOfBusiness) {
      if (lob?.lineOfBusiness === configTypes.voice) {
        const { telephoneGrid: tnGrid } = lob
        return {
          ...lob,
          telephoneGrid: tnGrid.map(tn => {
            return {
              ...tn,
              statuses: tn.statuses.map(status => {
                if (tn.telephoneNumber === tnLineItem.telephoneNumber) {
                  return {
                    ...status,
                    isSelected: statusId === status.id
                  }
                }
                return status
              })
            }
          })
        }
      }

      return {
        ...lob,
        statuses: lob.statuses.map(status => ({
          ...status,
          isSelected: statusId === status.id
        }))
      }
    }
    return lob
  })

  yield put(
    actions.updateConfigOffer({
      movesPartialDisconnectLobs: updatedDisconnectLobs
    })
  )

  yield put(actions.updateCartMovePartialDisconnect(payload))
}

export function* sendBBLabelEmailSaga({ payload }) {
  const { fxbuyflowSessionId } = yield select(({ session }) => session)
  const { email, callback,callback2 } = payload
  const res = yield call(sendBBLabelEmail, {
    emailId: email,
    fxbuyflowSessionId
  })
  if (res) {
    callback2(false)
    callback(res)
  }
}

export default function* rootSaga() {
  yield takeEvery(actions.SEND_BBLABEL_EMAIL, sendBBLabelEmailSaga)
  yield takeEvery(actions.UPDATE_CONFIG_ITEM, updateConfigItemSaga)
  yield takeEvery(actions.DISABLE_CONFIG, disableConfigSaga)
  yield takeEvery(actions.SET_CONFIGURATION, updateCartWithConfig)
  yield takeLatest(actions.SETUP_CONFIGURATION, setupConfigurationSaga)
  yield takeEvery(actions.REVERT_OFFER_CONFIGURATION, revertOfferConfiguration)
  yield takeEvery(actions.MODIFY_CONFIG_QUOTE, modifyConfigQuote)
  yield takeEvery(
    actions.NAV_CONFIG_INVALID_OFFER,
    navigateConfigInvalidOfferSaga
  )
  yield takeEvery(
    actions.RESUBMIT_CANCEL_MODIFY_QUOTE,
    resubmitCancelModifyQuote
  )
  yield takeEvery(actions.ADD_BOLT_ON_PACKAGE, addBoltOnPackageSaga)
  yield takeEvery(actions.REMOVE_BOLT_ON, removeBoltOnSaga)
  yield takeEvery(actions.UPDATE_CONFIG_INSTALL, updateConfigInstallSaga)
  yield takeEvery(
    actions.UPDATE_CONFIG_MOVE_SELECTION,
    updateConfigMoveSelectionSaga
  )
  yield takeEvery(
    actions.UPDATE_CONFIG_PARTIAL_DISCONNECT_MOVE_SELECTION,
    updateConfigPartialDiscMoveSelectionSaga
  )

  yield all([
    internetConfigSagas(),
    tvConfigSagas(),
    voiceConfigSagas(),
    officeConfigSagas()
  ])
}
