export const EMAIL_REGEX = /\S+@\S+\.\S+/
export const ZIP_CODE_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/
export const ALPHA_REGEX = /^[A-Za-z]+$/
export const SPECIAL_CHAR_REGEX = /[^\s\w]|[_]/
export const DL_DISPLAY_NAME_REGEX = /[^\w]|[_]/
export const PDF_DOWNLOAD_REGEX = /[~*\\|:"/<>?]/
export const FIRST_CHAR_CAPITAL_REGEX = /^[A-Z]/
export const TWO_LETTER_CAPITAL_REGEX = /[A-Z].*[A-Z]/
export const NUMBER_REGEX = /[0-9]/
export const NO_NAME_SHOPPING_ELEMENT_REGEX = /No Name SHOPPING_ELEMENT/i
export const HAS_PERIOD_REGEX = /[.]/
export const ALL_LOWER_CASE_REGEX = /\b[a-z]+\b/
export const NUMBERS_ONLY_REGEX = /^[0-9]+$/
