import { takeLatest, all, put, select, call } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import * as actions from '../actions'
import { selectRoutesAsArray, selectRouterMatch } from '../selectors/navigation'
import {
  getNextRoute,
  constructOrdersPath,
  openParentWindow
} from '../utils/navigation'
import { orderRouteIds, orderRoutes } from '../constants/navigation'
import { getWorkflowStep } from '../services/session'
import { getCbmLaunchUrl, getQQUrl } from '../services/navigation'

export function* resetOrderNavigationRoutes({ payload }) {
  const { currentRouteId } = payload

  if (currentRouteId) {
    const allRoutes = yield select(selectRoutesAsArray)
    const routes = allRoutes.filter(({ isLandingPage }) => !isLandingPage)
    const routeIndex = routes.findIndex(route => route.id === currentRouteId)

    // Reset all routes
    yield all(
      routes
        .slice(routeIndex + 1)
        .map(({ id }) =>
          put(actions.updateRoute(id, { disabled: true, completed: false }))
        )
    )
  }
}

// Navigates to route
export function* navigateToOrderRoute({ payload }) {
  const { routeId, data } = payload
  const routes = yield select(selectRoutesAsArray)
  const currentRoute = routes.find(({ id }) => routeId === id)
  const previousRoutes = routes.filter(
    ({ index, isLandingPage }) => index < currentRoute.index && !isLandingPage
  )
  const { path } = yield select(selectRouterMatch)
  const { pathname, search } = yield select(({ router }) => router.location)

  yield put(actions.updateRoute(currentRoute.id, { disabled: false }))

  const { cbmStandaloneOrderFlowEnabled } = yield select(state => state.order)

  // Complete/enable all previous routes
  yield all(
    previousRoutes.map(({ id }) => {
      // disabling documents section for CBM Standalone flow
      if (cbmStandaloneOrderFlowEnabled && id === orderRouteIds.documents) {
        return put(
          actions.updateRoute(id, {
            completed: true,
            disabled: true
          })
        )
      }
      return put(
        actions.updateRoute(id, {
          completed: true,
          disabled: false
        })
      )
    })
  )
  yield put(
    replace(constructOrdersPath(path || pathname, `/${routeId}`) + search, data)
  )
}

// Navigates to next order route in nav
export function* navigateToNextOrderRoute({ payload }) {
  const { currentRouteId, data } = payload
  const allRoutes = yield select(selectRoutesAsArray)
  const routes = allRoutes.filter(({ isLandingPage }) => !isLandingPage)
  const { cbmStandaloneOrderFlowEnabled } = yield select(state => state.order)

  let nextRoute = getNextRoute(routes, currentRouteId)

  // Skipping documents section for CBM Standalone flow when user clicks continue on config screen
  if (
    cbmStandaloneOrderFlowEnabled &&
    currentRouteId === orderRouteIds.configure
  ) {
    nextRoute = getNextRoute(routes, nextRoute.id)
  }

  if (nextRoute) {
    yield put(actions.navigateToOrderRoute(nextRoute.id, data))
  }
}

export function* navigateToDashboardSaga() {
  const { params } = yield select(selectRouterMatch)

  yield put(
    actions.setNavigation({
      continueOrderRoute: orderRoutes[params.pageId]
    })
  )
  yield put(actions.navigateToOrderRoute(orderRoutes.dashboard.id))
}

export function* navigateToContinueOrderRouteSaga() {
  const { continueOrderRoute } = yield select(({ navigation }) => navigation)

  // Check to get latest orders success status
  if (continueOrderRoute.id === orderRoutes.success.id) {
    yield put(actions.getCurrentWorkflowStep())
  }
  yield put(actions.navigateToOrderRoute(continueOrderRoute.id))
}

export function* navigateToCurrentStatusRouteSaga() {
  const {
    opportunityId,
    agentId,
    accountNumber,
    fxbuyflowSessionId
  } = yield select(({ session }) => session)
  const { continueOrderRoute } = yield call(getWorkflowStep, {
    opportunityId,
    agentId,
    accountNumber,
    fxbuyflowSessionId
  })

  yield put(actions.navigateToOrderRoute(continueOrderRoute.id))
}

export function* launchCbmSaga() {
  const { fxbuyflowSessionId } = yield select(({ session }) => session)

  const cbmUrl = yield call(getCbmLaunchUrl, fxbuyflowSessionId)
  yield call(openParentWindow, cbmUrl)
}

export function* launchQqSaga() {
  const {
    opportunityId,
    sessionId,
    fxbuyflowSessionId,
    agentId
  } = yield select(state => state.session)

  const qqUrl = yield call(getQQUrl, {
    opportunityId,
    sessionId,
    fxbuyflowSessionId,
    agentId
  })
  window.open(qqUrl, '_blank')
}

function* rootSaga() {
  yield takeLatest(actions.RESET_ORDER_NAV_ROUTES, resetOrderNavigationRoutes)
  yield takeLatest(actions.NAV_TO_ORDER_ROUTE, navigateToOrderRoute)
  yield takeLatest(actions.NAV_TO_NEXT_ORDER_ROUTE, navigateToNextOrderRoute)
  yield takeLatest(actions.NAV_TO_DASHBOARD_ROUTE, navigateToDashboardSaga)
  yield takeLatest(
    actions.NAV_TO_CONTINUE_ORDER_ROUTE,
    navigateToContinueOrderRouteSaga
  )
  yield takeLatest(
    actions.NAV_TO_CURRENT_STATUS_ROUTE,
    navigateToCurrentStatusRouteSaga
  )
  yield takeLatest(actions.LAUNCH_CBM, launchCbmSaga)
  yield takeLatest(actions.LAUNCH_QQ, launchQqSaga)
}

export default rootSaga
