import * as actions from '../actions/cart'
import { configMoveStatusIds, configTypes } from '../constants/configuration'
import { getCartSummaryCategories, calcSummaryTotals } from '../utils/cart'

export const initialState = {
  summary: undefined,
  details: undefined,
  quote: {},
  staticIpsSelected: {}
}

function cartReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case actions.SET_CART_SUMMARY: {
      const { cart } = payload

      return {
        ...state,
        summary: cart
      }
    }

    case actions.SET_CART_DETAILS: {
      const { cart } = payload

      return {
        ...state,
        details: cart
      }
    }

    case actions.SET_CART_QUOTE: {
      const { quote } = payload

      return {
        ...state,
        quote
      }
    }

    case actions.UPDATE_CART_SUMMARY: {
      const { lineItem } = payload
      const { shoppingType, lineOfBusiness } = lineItem
      const { linesOfBusiness } = state.summary
      const { items } = linesOfBusiness[lineOfBusiness] || {}
      const updatedItem = {
        ...lineItem,
        configurationItems: Object.values(lineItem.configurationItems || {})
      }

      // Shouldn't update cart without shoppingType/configurationItems
      if (!shoppingType || !lineItem.configurationItems) {
        return { ...state }
      }

      const updatedSummary = {
        ...state.summary,
        linesOfBusiness: {
          ...linesOfBusiness,
          [lineOfBusiness]: {
            ...linesOfBusiness[lineOfBusiness],
            items: {
              ...items,
              [shoppingType]: items[shoppingType].map(i =>
                i.type === lineItem.type ? updatedItem : i
              )
            }
          }
        }
      }
      const {
        additionalOptions,
        changedCartItems,
        ...billableCategories
      } = getCartSummaryCategories(updatedSummary)
      const [totalMonthlyCharges, totalOneTimeCharges] = calcSummaryTotals(
        billableCategories
      )

      return {
        ...state,
        summary: {
          ...updatedSummary,
          changedCartItems,
          totalMonthlyCharges,
          totalOneTimeCharges,
          categories: {
            additionalOptions,
            ...billableCategories
          }
        }
      }
    }

    case actions.UPDATE_CART_DISCONNECT_REASON: {
      const { selectedDisconnectReason } = payload
      const { summary } = state

      return {
        ...state,
        summary: {
          ...summary,
          lobDisconnectDetails: {
            ...summary.lobDisconnectDetails,
            selectedDisconnectReason
          }
        }
      }
    }

    case actions.UPDATE_CART_ADDITIONAL_SERVICES: {
      const { additionalServices } = payload

      return {
        ...state,
        summary: {
          ...state.summary,
          additionalServices: {
            ...state.summary.additionalServices,
            ...additionalServices
          }
        }
      }
    }

    case actions.UPDATE_CART_LOB_MOVE_SELECTIONS: {
      const { lineOfBusiness, statusId, disconnectDate } = payload
      const { linesOfBusiness } = state.summary
      const { movesStatusSelections } = linesOfBusiness[lineOfBusiness] || []

      return {
        ...state,
        summary: {
          ...state.summary,
          linesOfBusiness: {
            ...linesOfBusiness,
            [lineOfBusiness]: {
              ...linesOfBusiness[lineOfBusiness],
              movesStatusSelections: movesStatusSelections.map(option => ({
                ...option,
                isSelected: option.id === statusId,
                disconnectDate:
                  option.id === configMoveStatusIds.tempOverlapOfService &&
                  disconnectDate
                    ? disconnectDate
                    : null
              }))
            }
          }
        }
      }
    }

    case actions.UPDATE_CART_MOVE_PARTIAL_DISCONNECT: {
      const { lineOfBusiness, statusId, tnLineItem = null } = payload
      const { offer } = state.summary
      const { movesPartialDisconnectLobs } = offer

      return {
        ...state,
        summary: {
          ...state.summary,
          offer: {
            ...offer,
            movesPartialDisconnectLobs: movesPartialDisconnectLobs.map(lob => {
              if (lob?.lineOfBusiness === configTypes.voice) {
                const { telephoneGrid: tnGrid } = lob
                return {
                  ...lob,
                  telephoneGrid: tnGrid.map(tn => {
                    return {
                      ...tn,
                      statuses: tn.statuses.map(status => {
                        if (tn.telephoneNumber === tnLineItem.telephoneNumber) {
                          return {
                            ...status,
                            isSelected: statusId === status.id
                          }
                        }
                        return status
                      })
                    }
                  })
                }
              }

              return lob?.lineOfBusiness === lineOfBusiness
                ? {
                    ...lob,
                    statuses: lob.statuses.map(status => ({
                      ...status,
                      isSelected: statusId === status.id
                    }))
                  }
                : lob
            })
          }
        }
      }
    }

    default: {
      return state
    }
  }
}

export default cartReducer
