import styled, { css } from 'styled-components'
import { inverseVisibleFocusStyles } from '../../theme'

const buttonStyles = {
  primary: css`
    ${({ theme }) => theme.helpers?.getButtonStyle('primary')}
    &:focus-visible {
      background-color: rgb(34 162 253);
    }
  `,
  success: css`
    ${({ theme }) => theme.helpers?.getButtonStyle('success')}
  `,
  warning: css`
    ${({ theme }) => theme.helpers?.getButtonStyle('warning')}
  `,
  danger: css`
    ${({ theme }) => theme.helpers?.getButtonStyle('danger')}
  `,
  link: css`
    height: auto;
    min-width: auto;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors?.primary};
    margin: 0;
    padding: 0;
    text-decoration: underline;
    box-shadow: none;
    text-transform: none;
    font-weight: inherit;
    text-align: left;
  `,
  accessible: css`
    height: auto;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
    box-shadow: none;
    text-transform: none;
    font-weight: inherit;
    text-align: left;
    min-width: 0;
    ${inverseVisibleFocusStyles.text}
  `
}

export default styled.button.attrs(props => ({
  type: props.type || 'default',
  'aria-label': props.ariaLabel
}))`
  ${({ theme }) => theme.css?.button}
  ${({ type }) => buttonStyles[type]}
  ${({ fab }) => fab && 'border-radius: 40px'};
`
