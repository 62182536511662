import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

export const AppType = createContext({
  appType: ''
})

export const useAppType = () => {
  const { appType } = useContext(AppType)
  return useMemo(() => ({ appType }), [appType])
}

export const AppTypeProvider = ({ children, ...props }) => {
  return <AppType.Provider value={props}>{children}</AppType.Provider>
}
AppTypeProvider.propTypes = {
  children: PropTypes.node
}
export default useAppType
