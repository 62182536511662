import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTextArea = styled.textarea`
  padding: 10px;
  width: 100%;
  height: ${({ height }) => height || '150px'};
  resize: none;
  border: 2px solid
    ${({ theme, error }) =>
      error ? theme.colors?.danger : theme.colors?.inputBorder};
  background: ${({ theme }) => theme.colors?.inputBackground};

  :focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors?.primary};
  }
`

const TextArea = ({ ...props }) => (
  <StyledTextArea
    {...props}
    aria-label={props.ariaLabel || props.name}
  />
)
TextArea.propTypes = {
  name: PropTypes.string,
  ariaLabel: PropTypes.string
}
export default TextArea
