import { formatNumber } from './core'

export function formatCustomer(customer = {}) {
  const { amount: installFee = '0.00' } = customer.customInstallFee || {}
  const { amount: paidByComcast = '0.00' } = customer.amountPaidByComcast || {}
  return {
    ...customer,
    account: customer.account || {},
    site: customer.site || {},
    contact: {
      ...customer.contact,
      email: customer?.contact?.email || ''
    },
    amountPaidByComcast: {
      ...customer.amountPaidByComcast,
      amount: formatNumber(paidByComcast || 0)
    },
    customInstallFee: {
      ...customer.customInstallFee,
      amount: formatNumber(installFee || 0)
    }
  }
}

export function getSaveCustomerPayload(customer = {}) {
  const { contact, account, amountPaidByComcast } = customer
  const { billingAddress } = account

  return {
    email: contact.email || null,
    hasOptedOutOfEmail: contact.hasOptedOutOfEmail || false,
    secondaryEmail: contact.secondaryEmail,
    amountPaidByComcast: amountPaidByComcast.amount,
    taxExempt: account.taxExempt,
    billingAddress
  }
}
