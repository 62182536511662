import React from 'react'
import { Helmet } from 'react-helmet'
import { routerPropTypes } from '../../propTypes'
import { routes } from '../../constants/navigation'
import { appTitle } from '../../constants/content'

const Head = ({ match }) => {
  const matchedRoute = routes[match.params.pageId]
  const title = matchedRoute ? `${matchedRoute.title} - ${appTitle}` : appTitle

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}

Head.propTypes = {
  ...routerPropTypes
}

export default Head
