import * as actions from '../actions'

export const initialState = {
  status: undefined,
  existingServices: undefined,
  details: undefined,
  isSubmitted: false,
  projectCodes: undefined,
  moveDetails: undefined,
  cbmStandaloneOrderFlowEnabled: false,
  showMoveModal: false
}

function orderReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case actions.SUBMIT_ORDER_SUCCESS:
    case actions.SET_ORDER_IS_SUBMITTED: {
      const { isSubmitted = true } = payload || {}

      return { ...state, isSubmitted }
    }

    case actions.SET_EXISTING_SERVICES: {
      const { existingServices } = payload

      return {
        ...state,
        existingServices
      }
    }

    case actions.SET_ORDER_STATUS: {
      const { status } = payload

      return {
        ...state,
        status
      }
    }

    case actions.SET_DOCUMENT_TYPES: {
      return {
        ...state,
        documentTypes: payload
      }
    }

    case actions.SET_ORDER_DETAILS: {
      return {
        ...state,
        details: payload
      }
    }

    case actions.UPDATE_ORDER: {
      return {
        ...state,
        ...payload
      }
    }

    case actions.TOGGLE_MOVE_MODAL: {
      return {
        ...state,
        showMoveModal: !state.showMoveModal
      }
    }

    default: {
      return state
    }
  }
}
export default orderReducer
