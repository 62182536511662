import * as actions from '../actions/session'
import { urls } from '../constants/salesforce'
import { appTypes, LOCAL_TOKEN, themeTypes } from '../constants/session'
import { getUserLocalThemePreference } from '../utils/session'

const { NODE_ENV, REACT_APP_ACCESS_TOKEN = 'LOCAL_AUTH' } = process.env
const isProduction = NODE_ENV === 'production'
// Use locally stored token or fallback on config token
const devAuthToken = localStorage.getItem(LOCAL_TOKEN) || REACT_APP_ACCESS_TOKEN

export const initialState = {
  appType: appTypes.EC,
  sessionId: undefined,
  enabledFeatures: undefined,
  accessToken: isProduction ? undefined : devAuthToken,
  guid: undefined,
  isAuthorized: isProduction ? undefined : true,
  workflowStepId: undefined,
  initialWorkflowStepId: undefined,
  salesforce: {
    origin: undefined,
    paths: {},
    urls: {
      ...urls,
      opportunity: undefined,
      officeCameras: '/'
    }
  },
  bundleBuilder: {
    reasons: undefined
  },
  themeType: getUserLocalThemePreference()
}

function sessionReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case actions.SET_SESSION_ID: {
      const { sessionId } = payload

      return { ...state, sessionId }
    }

    case actions.SET_SESSION_DATA: {
      return { ...state, ...payload }
    }

    case actions.HIDE_BUNDLE_BUILDER_MODAL: {
      return {
        ...state,
        bundleBuilder: {
          ...state.bundleBuilder,
          showModal: false
        }
      }
    }

    case actions.JOIN_SALESFORCE_SESSION_URLS: {
      const { opportunityId } = payload
      const { urls: salesforceUrls } = state.salesforce

      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          urls: {
            ...state.salesforce.urls,
            opportunity: `${salesforceUrls.opportunity}${opportunityId}`
          }
        }
      }
    }

    case actions.GET_CURRENT_WORKFLOW_STEP: {
      return { ...state, workflowStepId: 0 }
    }

    case actions.TOGGLE_THEME: {
      return {
        ...state,
        themeType:
          state.themeType === themeTypes.regTheme
            ? themeTypes.darkTheme
            : themeTypes.regTheme
      }
    }

    default: {
      return state
    }
  }
}

export default sessionReducer
