import { css, createGlobalStyle } from 'styled-components'

// 3rd Party CSS
import 'antd/dist/antd.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'rc-tooltip/assets/bootstrap.css'

export const colors = {
  background: '#f4f4f4',
  foreground: '#ffffff', // Contrast color used for sections

  // Colors
  primary: '#1890ff',
  primaryDark: '#2e68b9',
  primaryLight: '#3f93e6',
  success: '#1e8500',
  warning: '#fad14f',
  danger: '#d11314',
  light: '#e5e5e5',
  lightSuccess: 'rgba(30, 133, 0, 0.20)',
  lightDanger: 'rgba(209, 19, 20, 0.20)',
  lightWarning: 'rgba(255, 251, 229, 1)',

  // Text
  text: 'rgba(0, 0, 0, 0.75)',
  textSecondary: 'rgba(0, 0, 0, 0.55)',
  textSuccess: '#679666',
  textLight: '#ffffff',

  // Statuses
  disabled: '#e5e5e5',
  hovered: 'rgba(229, 229, 229, 0.35)',

  // Filter/Table/Cart Headers
  tableHeader: '#000000',
  tableHeaderLight: '#cccccc',

  // Borders
  border: '#cccccc',
  borderDark: '#9c9c9c',

  // Inputs
  inputBackground: '#ffffff',
  inputBorder: '#000000',
  inputText: '#000000',
  inputIcon: '#cccccc',

  // Scrollbar
  scrollbarThumb: 'rgba(0, 0, 0, 0.5)',
  scrollbarTrack: '#f1f1f1',

  // Misc
  loaderTransparent: 'rgba(0, 0, 0, 0.60)',
  loaderSolid: '#ffffff',
  divider: '#e8e8e8',

  // offers
  offerCompetitive: 'orange'
}

export const darkColors = {
  ...colors,
  // add specific dark theme colors
  background: 'black'
}

// Breakpoints for media queries
export const breakpoints = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  tabletL: 962,
  laptop: 1024,
  laptopM: 1280,
  laptopL: 1440,
  desktop: 2560
}

// Function to insert media queries for styled components/css
export const screenSize = size => {
  return `@media (max-width: ${size}px)`
}

export const defaultTheme = {
  css: {
    button: css`
      height: 35px;
      padding: 0 30px;
      min-width: 140px;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 1.499;
      display: inline-block;
      text-align: center;
      border: 1px solid transparent;
      color: ${props => props.theme.colors?.text};
      border-color: ${props => props.theme.colors?.text};
      border-radius: 0;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      outline: none;

      &:disabled:not([type='accessible']) {
        border-color: ${props => props.theme.colors?.disabled};
        background: ${props => props.theme.colors?.light};
        color: rgba(0, 0, 0, 0.25);
        cursor: not-allowed;
      }

      & + & {
        margin-left: 10px;
      }

      ${screenSize(breakpoints.tablet)} {
        &:not([type='link']):not([type='accessible']) {
          min-width: auto;
          padding: 0 1.1rem;
          font-size: 0.98rem;
        }
      }

      &:focus-visible {
        svg {
          color: black;
        }
      }
    `,
    scrollBar: css`
      ::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      ::-webkit-scrollbar:vertical {
        width: 8px;
      }

      ::-webkit-scrollbar:horizontal {
        height: 8px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${props => props.theme.colors?.scrollbarThumb};
      }

      ::-webkit-scrollbar-track {
        background-color: ${props => props.theme.colors?.scrollbarTrack};
        border-radius: 8px;
      }
    `
  },
  helpers: {
    getButtonStyle(background) {
      return css`
        background-color: ${props => props.theme?.colors[background]};
        color: ${props => props.theme.colors?.textLight};
        border-color: ${props => props.theme?.colors[background]};

        &:hover,
        &:disabled {
          opacity: 0.9;
        }

        &:focus {
          opacity: 0.8;
          outline: none;
        }
        &:focus-visible {
          border-width: 3px;
          color: black;
          border-color: black;
        }
      `
    }
  }
}

export const theme = {
  ...defaultTheme,
  colors
}

// TODO: update with dark theme colors
export const darkTheme = theme

export const GlobalStyle = createGlobalStyle`
 /* OVERRIDES */
  body#app {
    color: ${props => props.theme.colors?.text};
    font-family: 'Lato', sans-serif;
    /* needed for error modal scroll */
    overflow: auto !important;
  }

  input:disabled {
    cursor: not-allowed;
  }

  .tooltip-voice-edge {
    > div {
      display: flex;
      padding-right: 10px;
    }
  }

  select::-ms-expand {
    display: none;
  }

  /* MODALS */
  .ant-modal-wrap {
    overflow-x: hidden !important;
  }

  /* Alert Modal */
  div.error-modal .ant-modal-confirm-body > .anticon {
    color: ${props => props.theme.colors?.danger};
  }
  div.warning-modal .ant-modal-confirm-body > .anticon {
    color: ${props => props.theme.colors?.warning};
  }
  div.success-modal .ant-modal-confirm-body > .anticon {
    color: ${props => props.theme.colors?.success};
  }
  div.modal-hide-icon .ant-modal-confirm-body > .anticon {
   display: none;
  }

  /* Hide Icon Modal */
  div.modal-hide-icon .ant-modal-confirm-body .ant-modal-confirm-content {
    margin-left: 0;
  }

  /* Hide Icon Modal */
  div.modal-hide-error-footer .ant-modal-confirm-btns {
    display: none;
  }

  /* Needed for modal buttons */
  button.ant-btn {
    ${props => props.theme?.css.button};
    :focus-visible{
      color: ${props => props.theme.colors?.text};
      border-width:3px;

    }
  }

  button.ant-btn.ant-btn-primary {
    ${getButtonStyle('primary')};
  }

  /* Partials */
  .sr-only {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    white-space: nowrap;
    border-width: 0;
  }

  /* Global font resizing*/
  html {
    ${screenSize(breakpoints.tablet)}{
      font-size:12px;
    }
  }

  &&&.full-page-modal .ant-modal-body {
    padding: 5px;
  }

  &&&.full-page-modal.ant-modal {
    top: 25px;
  }

`

export function getButtonStyle(background) {
  return css`
    background-color: ${props => props.theme?.colors[background]};
    color: ${props => props.theme.colors?.textLight};
    border-color: ${props => props.theme?.colors[background]};

    &:hover,
    &:disabled {
      opacity: 0.9;
    }

    &:focus {
      opacity: 0.8;
      outline: none;
    }
    &:focus-visible {
      border-width: 3px;
      color: black;
      border-color: black;
    }
  `
}

export const visibleFocusStyles = {
  button: css`
    &:focus-visible {
      border-width: 3px;
      border: solid;
      border-color: black;
      color: black;
    }
  `,
  svg: css`
    &:focus-visible {
      svg {
        fill: ${colors.primary};
        color: ${colors.primary};
      }
    }
  `,
  svgWithBoarder: css`
    &:focus-visible {
      svg {
        fill: ${colors.primary};
        color: ${colors.primary};
        border: 3px solid ${colors.primary};
      }
    }
  `,
  text: css`
    &:focus-visible {
      h1,
      h2,
      h3,
      h4,
      h5,
      span {
        color: ${colors.primary};
      }
      color: ${colors.primary};
    }
  `,
  link: css`
    &:focus-visible {
      color: ${colors.primary};
    }
  `
}
export const inverseVisibleFocusStyles = {
  button: css`
    &:focus-visible {
      border-width: 3px;
      border: solid;
      border-color: ${colors.primary};
      color: ${colors.primary};
    }
  `,
  svg: css`
    &:focus-visible {
      svg {
        fill: black;
        color: black;
      }
    }
  `,
  text: css`
    &:focus-visible {
      color: black;
    }
  `
}
