import { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { isTokenExpired } from '../../utils/auth'
import { getAuthenticationToken } from '../../services/session'
import { getEnv } from '../../utils/env'

const { HOST_ENV } = getEnv()
const redirectErrorCodes = HOST_ENV !== 'local' ? [401, 403] : []

const Authorization = ({
  guid,
  accessToken,
  onTokenRefresh,
  onUnauthorized
}) => {
  const interceptorsRef = useRef()

  const requestMiddleware = useCallback(
    async config => {
      let bearerToken = accessToken

      if (config.disableAuthorization) {
        return config
      }

      if (bearerToken && isTokenExpired(bearerToken)) {
        try {
          const tokenResponse = await getAuthenticationToken(guid)
          bearerToken = tokenResponse.accessToken
          onTokenRefresh(tokenResponse)
        } catch (error) {
          console.error('Token Refresh Failed:', error)
        }
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          ...(!!bearerToken && {
            Authorization: `Bearer ${bearerToken}`
          })
        }
      }
    },
    [accessToken, guid, onTokenRefresh]
  )

  const responseMiddleware = useCallback(
    error => {
      if (redirectErrorCodes.includes(error.response?.status)) {
        return onUnauthorized()
      }

      return Promise.reject(error)
    },
    [onUnauthorized]
  )

  useEffect(() => {
    const { current: currentInterceptors } = interceptorsRef

    if (currentInterceptors) {
      axios.interceptors.request.eject(currentInterceptors.request)
      axios.interceptors.response.eject(currentInterceptors.response)
    }

    interceptorsRef.current = {
      request: axios.interceptors.request.use(requestMiddleware),
      response: axios.interceptors.response.use(null, responseMiddleware)
    }
  }, [requestMiddleware, responseMiddleware])

  return false
}

Authorization.propTypes = {
  guid: PropTypes.string,
  accessToken: PropTypes.string,
  onTokenRefresh: PropTypes.func.isRequired,
  onUnauthorized: PropTypes.func.isRequired
}

export default Authorization
