import { configTypes } from '../constants/configuration'
import { orderRouteIds } from '../constants/navigation'
import { offerFilterTypes } from '../constants/offers'
import { lobTitles } from '../constants/orderservices'
import { selectActivePageId } from './router'
import { appTypes } from '../constants/session'

export const selectAccountName = state => {
  const { account = {} } = state.customer.details || {}

  return account.name
}

// Selects customer details & returns empty details if not found
export const selectCustomerDetails = state => {
  const { details } = state.customer || {}

  return (
    details || {
      account: {},
      contact: {},
      site: {}
    }
  )
}

export const selectIsSmartOfficeCustomer = state => {
  const { order } = state
  const { cartItems = [] } = order?.existingServices || {}

  return !!cartItems.find(
    item => item.lineOfBusiness === offerFilterTypes.office
  )
}

export function selectExistingLOBs(state) {
  return state?.order?.existingServices?.productDetails
    ? Object.keys(state.order.existingServices.productDetails).filter(
        key => key !== 'additionalOptions'
      )
    : []
}

export function selectServiceabilityMessage(state) {
  const pageId = selectActivePageId(state)

  if (pageId === orderRouteIds.configure) {
    const { site } = selectCustomerDetails(state)
    const { serviceabilityMessage, smartOfficeLicenceMessage } = site
    const { appType } = state.session

    if (!serviceabilityMessage) {
      return null
    }

    if (site.homeBasedBusiness) {
      const { configMessage } = serviceabilityMessage.hbbServiceability

      return {
        title: 'HBB',
        message: configMessage
      }
    }

    if (site.nonServiceableLobs?.length) {
      const { configMessage } = serviceabilityMessage.nonServiceableLob
      const { nonServiceableLobs } = site

      return {
        title: 'LOB(s) Not Serviceable',
        message: `${configMessage} ${nonServiceableLobs.join(', ')}`
      }
    }

    if (
      !site.homeBasedBusiness &&
      !site.canAgentSellSmartOffice &&
      site.nonServiceableLobs.length === 0 &&
      smartOfficeLicenceMessage &&
      appType === appTypes.NC
    ) {
      return {
        title: 'LOB(s) Not Serviceable',
        message: smartOfficeLicenceMessage?.configMessage
      }
    }
  }

  return null
}

export function isLineOfBusinessServiceable(state) {
  return lineOfBusiness => {
    const { site } = selectCustomerDetails(state)

    if (site.homeBasedBusiness) {
      return ![configTypes.tv, configTypes.office].includes(lineOfBusiness)
    }

    if (
      !site.canAgentSellSmartOffice &&
      lineOfBusiness === configTypes.office
    ) {
      return false
    }

    if (!site.nonServiceableLobs?.length) {
      return true
    }

    return site.nonServiceableLobs.every(
      nLob => nLob !== lobTitles[lineOfBusiness]
    )
  }
}

export function selectIsQuickQuoteToggle(state) {
  return state.customer?.details?.isQuickQuoteEnabled
}
