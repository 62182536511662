import { configTypes } from '../constants/configuration'

// Config
export const CONFIG_ITEM = 'CONFIG_ITEM'
export const CONFIG_ITEM_INTERNET = 'CONFIG_ITEM_INTERNET'
export const CONFIG_ITEM_TV = 'CONFIG_ITEM_TV'
export const CONFIG_ITEM_VOICE = 'CONFIG_ITEM_VOICE'
export const CONFIG_ITEM_OFFICE = 'CONFIG_ITEM_OFFICE'
export const SET_CONFIGURATION = 'SET_CONFIGURATION'
export const REPLACE_CONFIGURATION = 'REPLACE_CONFIGURATION'
export const SETUP_CONFIGURATION = 'SETUP_CONFIGURATION'
export const RESET_OFFER_CONFIGURATION = 'RESET_OFFER_CONFIGURATION'
export const CLEAR_CONFIG_ERROR_INTERNET = 'CLEAR_CONFIG_ERROR_INTERNET'
export const UPDATE_CONFIG_ITEM = 'UPDATE_CONFIG_ITEM'
export const DISABLE_CONFIG = 'DISABLE_CONFIG'
export const REVERT_OFFER_CONFIGURATION = 'REVERT_OFFER_CONFIGURATION'
export const CONFIG_ON_MOUNT = 'CONFIG_ON_MOUNT'
export const CONFIG_ON_CHANGE = 'CONFIG_ON_CHANGE'
export const CONFIG_UPDATE_OFFER = 'CONFIG_UPDATE_OFFER'
export const MODIFY_CONFIG_QUOTE = 'MODIFY_CONFIG_QUOTE'
export const NAV_CONFIG_INVALID_OFFER = 'NAV_CONFIG_INVALID_OFFER'
export const RESUBMIT_CANCEL_MODIFY_QUOTE = 'RESUBMIT_CANCEL_MODIFY_QUOTE'
export const TOGGLE_BOLT_ON = 'TOGGLE_BOLT_ON'
export const ADD_BOLT_ON_PACKAGE = 'ADD_BOLT_ON_PACKAGE'
export const REMOVE_BOLT_ON = 'REMOVE_BOLT_ON'
export const UPDATE_CONFIG_INSTALL = 'UPDATE_CONFIG_INSTALL'
export const UPDATE_CONFIG_MOVE_SELECTION = 'UPDATE_CONFIG_MOVE_SELECTION'
export const UPDATE_CONFIG_PARTIAL_DISCONNECT_MOVE_SELECTION =
  'UPDATE_CONFIG_PARTIAL_DISCONNECT_MOVE_SELECTION'

// Internet
export const TOGGLE_INTERNET_OPTIONS = 'TOGGLE_INTERNET_OPTIONS'

// Voice
export const SET_CURRENT_VOICE_CONFIG_SECTION =
  'SET_CURRENT_VOICE_CONFIG_SECTION'
export const SET_LAST_UPDATED_VOICE_CONFIG_SECTION =
  'SET_LAST_UPDATED_VOICE_CONFIG_SECTION'
export const GET_VOICE_HEADER_LOOKUPS = 'GET_VOICE_HEADER_LOOKUPS'
export const SET_HEADER_LOOKUPS = 'SET_HEADER_LOOKUPS'
export const CONFIG_SETUP_VOICE_LINES = 'CONFIG_SETUP_VOICE_LINES'
export const SETUP_VOICE_ADDITIONAL_INFO = 'SETUP_VOICE_ADDITIONAL_INFO'
export const UPDATE_VOICE_DIRECTORY_LISTINGS = 'UPDATE_VOICE_DIRECTORY_LISTINGS'
export const UPDATE_VOICE_E911_NOTIFICATION = 'UPDATE_VOICE_E911_NOTIFICATION'
export const UPDATE_VOICE_PROVIDER_DETAILS = 'UPDATE_VOICE_PROVIDER_DETAILS'
export const UPDATE_VOICE_LINE_ATTRIBUTE = 'UPDATE_VOICE_LINE_ATTRIBUTE'
export const HANDLE_EDGE_EMAIL_ALERT = 'HANDLE_EDGE_EMAIL_ALERT'
export const SET_CONFIG_VOICE_LINE = 'SET_CONFIG_VOICE_LINE'
export const MIGRATE_VOICE_LINE = 'MIGRATE_VOICE_LINE'
export const VALIDATED_VOICE = 'VALIDATED_VOICE'
export const UPDATE_VOICE_LINE_QTY = 'UPDATE_VOICE_LINE_QTY'
export const VALIDATE_MOVE_VOICE_LINES = 'VALIDATE_MOVE_VOICE_LINES'

// TV
export const CONFIG_TV_EQUIPMENT = 'CONFIG_TV_EQUIPMENT'
export const CONFIG_X1 = 'CONFIG_X1'
export const CONFIG_CONVERT_EQUIP_TO_X1 = 'CONFIG_CONVERT_EQUIP_TO_X1'
export const CONFIG_X1_UNSET_IPNS = 'CONFIG_X1_UNSET_IPNS'
export const UPDATE_MDTA_INSTALL_FEES = 'UPDATE_MDTA_INSTALL_FEES'
export const RESET_PRIMARY_OUTLETS_LEGACY_VIDEO =
  'RESET_PRIMARY_OUTLETS_LEGACY_VIDEO'

export const CANCEL_MODIFY_QUOTE = 'CANCEL_MODIFY_QUOTE'

// Smartoffice
export const GET_SMARTOFFICE_PERMIT_BANNER = 'GET_SMARTOFFICE_PERMIT_BANNER'
export const SEND_BBLABEL_EMAIL= 'SEND_BBLABEL_EMAIL'

// All LOBs that can be configured
export const configurationTypes = {
  [configTypes.internet]: CONFIG_ITEM_INTERNET,
  [configTypes.tv]: CONFIG_ITEM_TV,
  [configTypes.voice]: CONFIG_ITEM_VOICE,
  [configTypes.office]: CONFIG_ITEM_OFFICE
}

export function configureItem(lineOfBusiness, payload) {
  return {
    type: configurationTypes[lineOfBusiness],
    payload
  }
}

// Sets whole config line item
export function setConfig(lineItem) {
  return {
    type: SET_CONFIGURATION,
    payload: { lineItem }
  }
}

// Updates configuration item (option)
export function updateConfigItem(payload) {
  return {
    type: UPDATE_CONFIG_ITEM,
    payload
  }
}

export function disableConfig(lineItemType, isDisabled = true) {
  return {
    type: DISABLE_CONFIG,
    payload: { lineItemType, isDisabled }
  }
}

export function setupConfiguration(configuration) {
  return {
    type: SETUP_CONFIGURATION,
    payload: { configuration }
  }
}

export function replaceConfiguration(config) {
  return {
    type: REPLACE_CONFIGURATION,
    payload: config
  }
}

export function resetOfferConfiguration() {
  return {
    type: RESET_OFFER_CONFIGURATION
  }
}

export function updateConfigOffer(offer) {
  return {
    type: CONFIG_UPDATE_OFFER,
    payload: { offer }
  }
}

export function revertOfferConfiguration() {
  return {
    type: REVERT_OFFER_CONFIGURATION
  }
}

// Internet
export function toggleInternetOptions(enabled) {
  return {
    type: TOGGLE_INTERNET_OPTIONS,
    payload: { enabled }
  }
}

// Voice
export function setupVoiceLines() {
  return {
    type: CONFIG_SETUP_VOICE_LINES
  }
}

export function setupVoiceAdditionalInfo() {
  return {
    type: SETUP_VOICE_ADDITIONAL_INFO
  }
}

export function updateVoiceProviderDetails(payload) {
  return {
    type: UPDATE_VOICE_PROVIDER_DETAILS,
    payload
  }
}

export function updateVoiceDirectoryListings(payload) {
  return {
    type: UPDATE_VOICE_DIRECTORY_LISTINGS,
    payload
  }
}

export function updateVoiceE911Notification(payload) {
  return {
    type: UPDATE_VOICE_E911_NOTIFICATION,
    payload
  }
}

export function updateVoiceLineAttribute(payload) {
  return {
    type: UPDATE_VOICE_LINE_ATTRIBUTE,
    payload
  }
}

export function setCurrentVoiceConfigSection(sectionIndex) {
  return {
    type: SET_CURRENT_VOICE_CONFIG_SECTION,
    payload: { sectionIndex }
  }
}

export function setLastUpdatedVoiceSection(sectionIndex) {
  return {
    type: SET_LAST_UPDATED_VOICE_CONFIG_SECTION,
    payload: { sectionIndex }
  }
}

export function getVoiceHeaderLookups(payload) {
  return {
    type: GET_VOICE_HEADER_LOOKUPS,
    payload
  }
}

export function setHeaderLookups(lookups) {
  return {
    type: SET_HEADER_LOOKUPS,
    payload: { lookups }
  }
}

export function setVoiceLine(line) {
  return {
    type: SET_CONFIG_VOICE_LINE,
    payload: { line }
  }
}

export function migrateVoiceLine(payload) {
  return {
    type: MIGRATE_VOICE_LINE,
    payload
  }
}

export function validatedVoice(validated) {
  return {
    type: VALIDATED_VOICE,
    payload: { validated }
  }
}

export function updateVoiceLineQuantity(payload) {
  return {
    type: UPDATE_VOICE_LINE_QTY,
    payload
  }
}

// TV
export function configTvEquipment(payload) {
  return {
    type: CONFIG_TV_EQUIPMENT,
    payload
  }
}

export function configX1(item, skipOutletValidation = false) {
  return {
    type: CONFIG_X1,
    payload: {
      item,
      skipOutletValidation
    }
  }
}

export function updateMDTAInstallFees({ item, lineItem }) {
  return {
    type: UPDATE_MDTA_INSTALL_FEES,
    payload: { item, lineItem }
  }
}

// MISC
export function modifyConfigQuote() {
  return {
    type: MODIFY_CONFIG_QUOTE
  }
}

export function navigateConfigInvalidOffer() {
  return {
    type: NAV_CONFIG_INVALID_OFFER
  }
}

export function cancelModifyQuote() {
  return {
    type: CANCEL_MODIFY_QUOTE
  }
}

export function resubmitCancelModifyQuote() {
  return {
    type: RESUBMIT_CANCEL_MODIFY_QUOTE
  }
}

export function toggleConfigBoltOn(lineOfBusiness, isVisible = true) {
  return {
    type: TOGGLE_BOLT_ON,
    payload: { lineOfBusiness, isVisible }
  }
}

export function addConfigBoltOnPackage(offerId, lineOfBusiness) {
  return {
    type: ADD_BOLT_ON_PACKAGE,
    payload: { offerId, lineOfBusiness }
  }
}

export function removeBoltOn(lineOfBusiness) {
  return {
    type: REMOVE_BOLT_ON,
    payload: { lineOfBusiness }
  }
}

export function updateConfigInstall(payload) {
  return {
    type: UPDATE_CONFIG_INSTALL,
    payload
  }
}

export function getSmartOfficePermitBanner() {
  return {
    type: GET_SMARTOFFICE_PERMIT_BANNER
  }
}

export function updateConfigMoveSelection(payload) {
  return {
    type: UPDATE_CONFIG_MOVE_SELECTION,
    payload
  }
}

export function validateMoveVoiceLines() {
  return {
    type: VALIDATE_MOVE_VOICE_LINES
  }
}

export function updateConfigPartialMoveSelection(payload) {
  return {
    type: UPDATE_CONFIG_PARTIAL_DISCONNECT_MOVE_SELECTION,
    payload
  }
}

export function resetPrimaryOutletsLegacyVideo(payload) {
  return {
    type: RESET_PRIMARY_OUTLETS_LEGACY_VIDEO,
    payload
  }
}

export function sendBBLabelEmail(payload) {
  return {
    type: SEND_BBLABEL_EMAIL,
    payload
  }
}
