import { searchTermTypes } from '../constants/offers'

// Search for empty/Upgradeable tier
export function getEmptyTierFilter(name) {
  return `(${name} eq null or ${name} eq 'Upgradeable')`
}

export function getEqualsFilter(filter) {
  const [name, value] = Object.entries(filter)[0]

  if (name === 'videoTierOfService') {
    return `${name} eq '${value}'`
  }

  return typeof value === 'string'
    ? `contains(${name}, '${value}')`
    : `${name} eq ${value}`
}

// convert array of filters to string
export function getOfferFilterString(filterMap) {
  return Object.entries(filterMap)
    .reduce((filterStrings, [filterProperty, filters]) => {
      const mappedFilters = filters.map(getEqualsFilter)

      if (filterProperty === 'contractTerm') {
        return [...filterStrings, ...mappedFilters]
      }

      if (filterProperty === 'searchTerm') {
        const [{ searchTerm }] = filters

        const searchFilters = searchTermTypes
          .map(({ name, type }) => {
            if (type === 'number') {
              return !Number.isNaN(Number(searchTerm))
                ? `${name} eq ${searchTerm}`
                : null
            }

            return `contains(toLower(${name}), '${searchTerm
              .toString()
              .toLowerCase()}')`
          })
          .filter(Boolean)
          .join(' or ')

        return [...filterStrings, `(${searchFilters})`]
      }

      if (filters.length < 1) {
        return [...filterStrings, getEmptyTierFilter(filterProperty)]
      }
      const parsedFilters = [
        ...mappedFilters,
        `contains(${filterProperty}, 'Upgradeable')`
      ].join(' or ')

      return [...filterStrings, `(${parsedFilters})`]
    }, [])
    .join(' and ')
}
