import { put, call, take, fork, cancel, cancelled } from 'redux-saga/effects'
import { rpcConnection, rpcMessageTypes } from '../utils/rpc'
import { createChannel } from '../utils/reduxSaga'
import * as actions from '../actions'

const rpcConnections = {}

export function* rpcMessengerSaga({ payload }) {
  const { connectionId, onSendAction, onCloseAction } = payload || {}

  // Create new channel to allow dispatching on connection events
  const resultChannel = yield call(createChannel, emitter => {
    rpcConnection.on(rpcMessageTypes.receiveOrder, data => {
      if (data) {
        let parsedData

        try {
          parsedData = typeof data === 'string' ? JSON.parse(data) : data
        } catch {
          parsedData = data
        }

        emitter({
          actionType: onSendAction,
          data: parsedData
        })
      }
    })

    return () => rpcConnection.stop()
  })

  rpcConnection.invoke(rpcMessageTypes.initOrderSubmit, connectionId)

  try {
    // Take and dispatch all events
    while (true) {
      const { actionType, data } = yield take(resultChannel)

      yield put({
        type: actionType,
        payload: { connectionId, data }
      })
    }
  } finally {
    // Clean up when cancelled
    if (yield cancelled()) {
      resultChannel.close()
      yield put({
        type: onCloseAction,
        payload: { connectionId }
      })
    }
  }
}

export function* rootSaga() {
  while (true) {
    let action = yield take(actions.RPC_OPEN_CONNECTION)
    rpcConnections[action?.payload.connectionId] = yield fork(
      rpcMessengerSaga,
      action
    )

    action = yield take(actions.RPC_CLOSE_CONNECTION)
    yield cancel(rpcConnections[action?.payload.connectionId])
  }
}

export default rootSaga
