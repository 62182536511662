import 'core-js'
// Adds support for IE (TODO: Upgrade core-js so this isn't needed)
import 'array-flat-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './interceptors'
import { setConfig } from 'react-hot-loader'
import { store } from './store'
import * as serviceWorker from './serviceWorker'
import { getSalesforceURLs } from './utils/navigation'
import * as salesforce from './constants/salesforce'
import * as actions from './actions'
import App from './App'

const { NODE_ENV, BUILD_DATE, PACKAGE_VERSION } = process.env

setConfig({ reloadHooks: false })

/* istanbul ignore next */
if (NODE_ENV === 'production') {
  // Log build time
  console.info(
    `%c***FxBuyflow***\nBuild Date: ${BUILD_DATE}\nPackage Version: ${PACKAGE_VERSION}`,
    'background: black; color: white; font-size: medium'
  )
}

window.addEventListener('message', ({ data }) => {
  if (data && data.SALESFORCE) {
    const { origin } = data.SALESFORCE
    const paths = {
      ...salesforce.paths,
      ...data.SALESFORCE.paths
    }
    const salesforceURLs = getSalesforceURLs(origin, paths)

    store.dispatch(
      actions.setSessionData({
        salesforce: {
          ...data.SALESFORCE,
          urls: {
            ...salesforce.urls, // Default URLs shipped with app
            ...data.SALESFORCE.urls, // Full URLs passed by salesforce
            ...salesforceURLs // Joined URL paths
          }
        }
      })
    )
  }
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
