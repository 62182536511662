import { documentStatus } from '../constants/documents'

export function selectAcceptedDocument(state) {
  const types = state?.documents?.types || {}
  return (
    Object.values(types).find(
      type => !!type.status && type.status === documentStatus.approved
    ) || false
  )
}

export function selectCurrentDocAcceptanceType(state) {
  const types = state?.documents?.types || {}
  return Object.values(types).find(type => !!type.status) || false
}

export function selectDocumentsSignNowUrl(state) {
  return state?.documents?.signNowUrl
}
