export const SELECT_DOC_TYPE = 'SELECT_DOC_TYPE'
export const REST_DOC_TYPES = 'REST_DOC_TYPES'
export const VERIFY_BY_PHONE = 'VERIFY_BY_PHONE'
export const SEND_PROPOSAL = 'SEND_PROPOSAL'
export const GENERATE_PDF = 'GENERATE_PDF'
export const GET_PREVIEW_PROPOSAL = 'GET_PREVIEW_PROPOSAL'
export const SET_PDF_DATA = 'SET_PDF_DATA'
export const SET_PREVIEW_PROPOSAL = 'SET_PREVIEW_PROPOSAL'
export const SET_PDF_GUID = 'SET_PDF_GUID'
export const CHECK_DOCUMENT_ACCEPTANCE = 'CHECK_DOCUMENT_ACCEPTANCE'
export const SAVE_DOCUMENTS = 'SAVE_DOCUMENTS'
export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS'
export const TOGGLE_UPLOAD_MODAL = 'TOGGLE_UPLOAD_MODAL'
export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT'
export const TOGGLE_DOCUMENT_IS_UPLOADING = 'TOGGLE_DOCUMENT_IS_UPLOADING'
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const GET_AVAILABLE_DOCUMENTS = 'GET_AVAILABLE_DOCUMENTS'
export const SET_AVAILABLE_DOCUMENT = 'SET_AVAILABLE_DOCUMENT'
export const SET_DOCUMENT_STATUS = 'SET_DOCUMENT_STATUS'
export const CLEAR_DOCUMENT_STATUS = 'CLEAR_DOCUMENT_STATUS'
export const CONFIRM_MODIFY_QUOTE = 'CONFIRM_MODIFY_QUOTE'
export const GET_MISSING_DOCUMENTS = 'GET_MISSING_DOCUMENTS'
export const SET_MISSING_DOCUMENTS = 'SET_MISSING_DOCUMENTS'
export const SET_SIGN_NOW_URL = 'SET_SIGN_NOW_URL'
export const SIGN_NOW_DOC_ACCEPTANCE = 'SIGN_NOW_DOC_ACCEPTANCE'
export const GENERATE_MOVE_FORM_PDF = 'GENERATE_MOVE_FORM_PDF'
export const SET_MOVE_FORM_PDF_GUID = 'SET_MOVE_FORM_PDF_GUID'
export const SET_MOVE_FORM_PDF_DATA = 'SET_MOVE_FORM_PDF_DATA'
export const REFRESH_DOCUMENT_ACCEPTANCE = 'REFRESH_DOCUMENT_ACCEPTANCE'

export function selectDocType(docType) {
  return {
    type: SELECT_DOC_TYPE,
    payload: { docType }
  }
}

export function resetDocTypes() {
  return {
    type: REST_DOC_TYPES
  }
}

export function verifyByPhone(agreed) {
  return {
    type: VERIFY_BY_PHONE,
    payload: { agreed }
  }
}

export function sendProposal() {
  return {
    type: SEND_PROPOSAL
  }
}

export function getPreviewProposal() {
  return {
    type: GET_PREVIEW_PROPOSAL
  }
}

export function setPreviewProposal(payload) {
  return {
    type: SET_PREVIEW_PROPOSAL,
    payload
  }
}

export function generatePdf() {
  return {
    type: GENERATE_PDF
  }
}

export function setPdfData(payload) {
  return {
    type: SET_PDF_DATA,
    payload
  }
}

export function setPdfGuid(payload) {
  return {
    type: SET_PDF_GUID,
    payload
  }
}

export function checkDocumentAcceptance(payload) {
  return {
    type: CHECK_DOCUMENT_ACCEPTANCE,
    payload
  }
}

export function saveDocuments(payload) {
  return {
    type: SAVE_DOCUMENTS,
    payload
  }
}

export function updateDocuments(payload) {
  return {
    type: UPDATE_DOCUMENTS,
    payload
  }
}

export function toggleUploadModal() {
  return {
    type: TOGGLE_UPLOAD_MODAL
  }
}

export function getDocuments() {
  return {
    type: GET_DOCUMENTS
  }
}

export function downloadDocument(document) {
  return {
    type: DOWNLOAD_DOCUMENT,
    payload: { document }
  }
}

export function toggleDocumentIsUploading() {
  return {
    type: TOGGLE_DOCUMENT_IS_UPLOADING
  }
}

export function deleteDocument(payload) {
  return {
    type: DELETE_DOCUMENT,
    payload
  }
}

export function getAvailableDocumentAcceptance(payload) {
  return {
    type: GET_AVAILABLE_DOCUMENTS,
    payload
  }
}

export function setAvailableDocument(docType, isActive = false) {
  return {
    type: SET_AVAILABLE_DOCUMENT,
    payload: {
      docType,
      isActive
    }
  }
}

export function setDocumentStatus(payload) {
  return {
    type: SET_DOCUMENT_STATUS,
    payload
  }
}

export function clearDocumentStatus() {
  return {
    type: CLEAR_DOCUMENT_STATUS
  }
}

export function confirmModifyQuote() {
  return {
    type: CONFIRM_MODIFY_QUOTE
  }
}

export function getMissingDocuments() {
  return {
    type: GET_MISSING_DOCUMENTS
  }
}

export function setMissingDocuments(payload) {
  return {
    type: SET_MISSING_DOCUMENTS,
    payload
  }
}

export function setSignNowURL(signNowUrl) {
  return {
    type: SET_SIGN_NOW_URL,
    payload: { signNowUrl }
  }
}

export function signNowDocAcceptance(payload) {
  return {
    type: SIGN_NOW_DOC_ACCEPTANCE,
    payload
  }
}

export function generateMoveFormPdf() {
  return {
    type: GENERATE_MOVE_FORM_PDF
  }
}

export function setMoveFormPdfGuid(payload) {
  return {
    type: SET_MOVE_FORM_PDF_GUID,
    payload
  }
}

export function setMoveFormPdfData(payload) {
  return {
    type: SET_MOVE_FORM_PDF_DATA,
    payload
  }
}

export function refreshDocumentAcceptance() {
  return {
    type: REFRESH_DOCUMENT_ACCEPTANCE
  }
}
