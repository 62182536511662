import format from 'date-fns/format'
import isAfter from 'date-fns/is_after'
import isBefore from 'date-fns/is_before'
import dfIsSameDay from 'date-fns/is_same_day'
import dfParse from 'date-fns/parse'
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import dfAddDays from 'date-fns/add_days'
import subtractDays from 'date-fns/sub_days'
import dfIsWeekend from 'date-fns/is_weekend'
import dfAddYears from 'date-fns/add_years'
import dfAddMinutes from 'date-fns/add_minutes'

export function parseDate(dateString) {
  return typeof dateString === 'string' ? dfParse(dateString) : dateString
}

export function formatDate(date, formatString = 'M/DD/YYYY') {
  const targetDate = parseDate(date)

  return date && format(targetDate, formatString)
}

export function isDateSameOrAfter(startDate, endDate = new Date()) {
  const start = parseDate(startDate)
  const end = parseDate(endDate)

  return isSameDay(start, end) || isAfter(start, end)
}

export function isDateSameOrBefore(startDate, endDate = new Date()) {
  const start = parseDate(startDate)
  const end = parseDate(endDate)

  return isSameDay(start, end) || isBefore(start, end)
}

export function isSameDay(startDate, endDate = new Date()) {
  const start = parseDate(startDate)
  const end = parseDate(endDate)

  return dfIsSameDay(start, end)
}

export function isWeekend(date) {
  return dfIsWeekend(parseDate(date))
}

export function isDateAfter(startDate, endDate) {
  const start = parseDate(startDate)
  const end = parseDate(endDate)

  return isAfter(start, end)
}

export function isDateBefore(startDate, endDate) {
  return !isDateAfter(startDate, endDate)
}

export function getDifferenceInDays(end, start) {
  const dateLater = parseDate(end)
  const dateEarlier = parseDate(start)

  return differenceInCalendarDays(dateLater, dateEarlier)
}

export function getAddedDate(start, number, formatString = 'YYYY-MM-DD') {
  const dateEarlier = parseDate(start)
  const addedDate = dfAddDays(dateEarlier, number)

  return format(addedDate, formatString)
}

export function getSubtractedDate(end, number, formatString = 'YYYY-MM-DD') {
  const dateLater = parseDate(end)
  const subtractedDate = subtractDays(dateLater, number)

  return format(subtractedDate, formatString)
}

export function getNextAvailableDate(startDate, disabledDates = []) {
  const date = parseDate(startDate)
  const isDisabledDate = disabledDates.some(d =>
    isSameDay(formatDate(d), formatDate(date))
  )

  if (isDisabledDate) {
    return getNextAvailableDate(dfAddDays(date, 1), disabledDates)
  }

  return date
}

export function addYears(date, years = 0) {
  return dfAddYears(parseDate(date), years)
}

export function addMinutes(date, minutes) {
  return dfAddMinutes(date, minutes)
}

// TODO: Upgrade to date-fns 2.0 which has these methods (holding off for current release)
export function getAddedBusinessDaysDate(start, number, availableDates) {
  return calcBusinessDaysHelper(
    start,
    number,
    getAddedDate,
    availableDates || []
  )
}

export function getSubtractedBusinessDaysDate(start, number, availableDates) {
  return calcBusinessDaysHelper(
    start,
    number,
    getSubtractedDate,
    availableDates || []
  )
}

function findDate(date, availableDates) {
  return availableDates.find(d => isSameDay(d, date))
}

function calcBusinessDaysHelper(
  startDate,
  numberOfDays,
  calcFn,
  availableDates = []
) {
  // Used to make sure we never have an endless loop
  let totalSkippedDays = 0
  let days = 0
  let calcDate = startDate

  function isDateUnavailable(date) {
    return availableDates.length ? !findDate(date, availableDates) : false
  }

  while (days < numberOfDays && totalSkippedDays < 15) {
    calcDate = calcFn(calcDate, 1)
    const shouldSkip = isWeekend(calcDate) || isDateUnavailable(calcDate)

    totalSkippedDays = shouldSkip ? totalSkippedDays + 1 : totalSkippedDays
    days = shouldSkip ? days : days + 1
  }

  return calcDate
}
