import {
  configMoveStatusIds,
  configTypes,
  lineItemTypes
} from '../constants/configuration'
import * as actions from '../actions/configuration'

export const initialState = {
  [configTypes.internet]: undefined,
  [configTypes.tv]: undefined,
  [configTypes.voice]: undefined,
  [configTypes.office]: undefined
}

function configurationReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case actions.SET_CONFIGURATION: {
      const { lineItem } = payload
      const { lineOfBusiness: lob } = lineItem
      const currentLOB = state[lob] || {}

      return {
        ...state,
        [lob]: {
          ...currentLOB,
          [lineItem.type]: {
            ...currentLOB[lineItem.type],
            ...lineItem
          }
        }
      }
    }

    case actions.SET_CURRENT_VOICE_CONFIG_SECTION: {
      const { sectionIndex } = payload
      const { currentSectionIndex = 0 } = state.voice || {}

      return {
        ...state,
        [configTypes.voice]: {
          ...state.voice,
          // Only want to update when index is increased
          currentSectionIndex:
            sectionIndex > currentSectionIndex
              ? sectionIndex
              : currentSectionIndex
        }
      }
    }

    case actions.SET_LAST_UPDATED_VOICE_CONFIG_SECTION: {
      const { sectionIndex } = payload

      return {
        ...state,
        [configTypes.voice]: {
          ...state.voice,
          validated: sectionIndex === 0,
          lastUpdatedSectionIndex: sectionIndex
        }
      }
    }

    case actions.SET_CONFIG_VOICE_LINE: {
      const { line } = payload
      const voice = state[configTypes.voice]
      const configuredItems = voice[lineItemTypes.configuredItems]
      const voiceLineGroup =
        configuredItems.configuredLines[line.voiceLineGroup]

      return {
        ...state,
        [configTypes.voice]: {
          ...voice,
          [lineItemTypes.configuredItems]: {
            ...configuredItems,
            configuredLines: {
              ...configuredItems.configuredLines,
              [line.voiceLineGroup]: {
                ...voiceLineGroup,
                configurationItems: voiceLineGroup.configurationItems.map(i =>
                  i.lineId === line.lineId ? line : i
                )
              }
            }
          }
        }
      }
    }

    case actions.REPLACE_CONFIGURATION: {
      return payload
    }

    case actions.RESET_OFFER_CONFIGURATION: {
      return {
        ...initialState
      }
    }

    case actions.SET_HEADER_LOOKUPS: {
      const { lookups } = payload

      return {
        ...state,
        voice: {
          ...state.voice,
          headerLookupResults: lookups
        }
      }
    }

    case actions.VALIDATED_VOICE: {
      const { validated } = payload
      return {
        ...state,
        voice: {
          ...state.voice,
          validated
        }
      }
    }

    case actions.CONFIG_UPDATE_OFFER: {
      const { offer } = payload

      return {
        ...state,
        offer: {
          ...state.offer,
          ...offer
        }
      }
    }

    case actions.TOGGLE_BOLT_ON: {
      const { lineOfBusiness, isVisible } = payload

      // Remove bolt on if not visible
      if (!isVisible) {
        const { [lineOfBusiness]: _, ...updatedState } = state
        return updatedState
      }

      return {
        ...state,
        [lineOfBusiness]: {
          lineOfBusiness,
          allowDisconnect: true,
          isBoltOn: true,
          hideConfigurations: true
        }
      }
    }

    case actions.UPDATE_CONFIG_MOVE_SELECTION: {
      const { lineOfBusiness, statusId, disconnectDate } = payload
      const lobState = state[lineOfBusiness]
      const { movesStatusSelections } = lobState

      return {
        ...state,
        [lineOfBusiness]: {
          ...lobState,
          movesStatusSelections: movesStatusSelections.map(option => ({
            ...option,
            isSelected: option.id === statusId,
            disconnectDate:
              option.id === configMoveStatusIds.tempOverlapOfService &&
              disconnectDate
                ? disconnectDate
                : null
          }))
        }
      }
    }

    default: {
      return state
    }
  }
}

export default configurationReducer
