import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { AiOutlineLoading as Icon } from 'react-icons/ai'
import FocusTrap from 'focus-trap-react'

const Loader = ({ visible, isFull, message, ...props }) => {
  const isOverlay = !!props.children

  return (
    <>
      {props.children}
      {visible && (
        <StyledContainer
          transparent={isOverlay}
          {...props}
          isFull={isFull || isOverlay}
          isOverlay={isOverlay}
        >
          <div>
            <StyledIcon />
          </div>
          <div>
            {message && (
              <StyledMessage isOverlay={isOverlay}>{message}</StyledMessage>
            )}
          </div>
          {isOverlay && (
            <FocusTrap active>
              <div>
                <StyledInputFocusTrap
                  type="text"
                  readOnly
                />
              </div>
            </FocusTrap>
          )}
        </StyledContainer>
      )}
    </>
  )
}

const StyledContainer = styled.div`
  ${({ isFull, isOverlay }) =>
    isFull &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: fixed;
      left: 0;
      top: 0;
      height: ${isOverlay ? '100%' : '100vh'};
      width: 100%;
      background: ${({ transparent, theme }) => {
        const color = transparent ? 'loaderTransparent' : 'loaderSolid'
        return theme.colors && theme.colors[color]
      }};
      z-index: 2;
      z-index: 1001;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `}
  svg {
    fill: ${({ theme, isOverlay }) =>
      isOverlay ? theme.colors?.light : theme.colors?.primary};
  }
`

const iconKeyFrames = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledIcon = styled(Icon)`
  font-size: 4rem;
  height: 4rem;
  width: 4rem;
  animation: ${iconKeyFrames} 1.2s infinite linear;
`

const StyledMessage = styled.p`
  color: ${({ theme, isOverlay }) =>
    isOverlay ? theme.colors?.light : theme.colors?.primary};
  margin: 10px 0px;
  font-weight: bold;
`

const StyledInputFocusTrap = styled.input`
  opacity: 0;
`

Loader.propTypes = {
  visible: PropTypes.bool,
  transparent: PropTypes.bool,
  isFull: PropTypes.bool,
  children: PropTypes.node,
  message: PropTypes.string
}

Loader.defaultProps = {
  visible: true,
  isFull: true
}

export default Loader
