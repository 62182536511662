import * as actions from '../actions/scheduling'

export const initialState = {
  calendar: undefined,
  notes: undefined
}

function schedulingReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case actions.SET_CALENDAR: {
      const { calendar } = payload

      return {
        ...state,
        calendar
      }
    }

    case actions.UPDATE_SELECTED_CALENDAR_DATE: {
      const { calendarType, selectedDate: selected } = payload

      return {
        ...state,
        calendar: {
          ...state.calendar,
          [calendarType]: {
            ...state.calendar[calendarType],
            selected
          }
        }
      }
    }

    case actions.SET_SCHEDULING_NOTES: {
      const { notes } = payload

      return {
        ...state,
        notes
      }
    }

    case actions.SET_SCHEDULING_ESCALATION: {
      const { field, value } = payload

      return {
        ...state,
        calendar: {
          ...state.calendar,
          [field]: value
        }
      }
    }

    default: {
      return state
    }
  }
}

export default schedulingReducer
