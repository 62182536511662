import { internetConfigurationItems } from '../../constants/configuration'
import { errors } from '../../constants/content'
import validationErrors from '../../constants/errors/order'
import { orderRouteIds } from '../../constants/navigation'
import { getValidationError } from './core'
import customerValidations from './customer'
import { getDifferenceInDays, isSameDay, isDateAfter } from '../dates'
import { selectActiveForm } from '../../selectors/form'

export default {
  validations: {
    order: [
      {
        validation: validateOrder,
        error: validationErrors.validateOrderError
      }
    ]
  }
}

export function validateOrder({ state, pageId }) {
  const { error } = getValidationError(
    customerValidations.validations.customerEmail,
    state
  )

  switch (pageId) {
    case orderRouteIds.documents: {
      const productDetails = state.cart?.details?.productDetails

      const cartIncludesSecurityEdge =
        productDetails?.internet?.filter(
          item => item.id === internetConfigurationItems.securityEdge
        ).length > 0

      return error && cartIncludesSecurityEdge
    }
    default: {
      return false
    }
  }
}

export function validateOrderSubmit(state) {
  const { calendar } = state.scheduling
  const form = selectActiveForm(state)

  if (!form.isValid) {
    const [firstError] = Object.values(form.errors)
    return firstError
  }

  // calendar not set/has installationMessage don't run validations
  if (calendar.installationMessage) {
    return false
  }

  const {
    install,
    prewire,
    preWireMinGapBeforeInstallCalendar: minDaysBeforeInstall
  } = calendar
  const installDate = install?.selected.date
  const preWireDate = prewire?.selected.date
  const diffInCddPreWire = getDifferenceInDays(installDate, preWireDate)

  if (isSameDay(preWireDate, installDate)) {
    return `${errors.preWireCddSameDay} ${minDaysBeforeInstall} days after prewire date.`
  }

  if (isDateAfter(preWireDate, installDate)) {
    return `${errors.preWireAfterCdd} ${minDaysBeforeInstall} days prior to installation date.`
  }

  if (diffInCddPreWire < minDaysBeforeInstall) {
    return `${errors.preWirePriorCdd} ${minDaysBeforeInstall} days prior to installation date.`
  }

  return false
}
