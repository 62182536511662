import axios from 'axios'
import {
  POST_PROPOSAL_URL,
  GET_PREVIEW_URL,
  POST_PDF_GENERATION_URL,
  GET_PDF_URL,
  POST_VERIFY_BY_PHONE_URL,
  POST_DOCUMENT_URL,
  GET_DOCUMENTS_INFO_URL,
  POST_DELETE_DOCUMENT_URL,
  POST_DOCUMENT_ACCEPTANCE_TYPE_URL,
  POST_EXPIRE_DOCUMENTS_URL,
  GET_MISSING_DOCUMENTS_URL,
  GET_DOCUMENT_URL,
  POST_DOCS_SAVE_CONTINUE_URL,
  POST_SIGN_NOW_URL,
  POST_MOVE_FORM_GENERATION_URL
} from '../constants/urls'
import { getApiErrorMessage } from '../utils/error'
import { getUserDevice } from '../utils/session'

export async function verifyByPhone({
  opportunityId,
  sessionId,
  accountName,
  fxbuyflowSessionId
}) {
  try {
    const { data } = await axios.post(POST_VERIFY_BY_PHONE_URL, {
      OpportunityId: opportunityId,
      SessionId: sessionId,
      AccountNumber: accountName, // TODO: Change this name when API is updated
      fxbuyflowSessionId
    })

    return data
  } catch (err) {
    const errorMsg = getApiErrorMessage(err)
    throw new Error(errorMsg?.description)
  }
}

export async function generatePdf({ opportunityId, fxbuyflowSessionId }) {
  const { data: res } = await axios.post(POST_PDF_GENERATION_URL, {
    OpportunityId: opportunityId,
    fxbuyflowSessionId
  })
  return res.data
}

export async function getPdf(guid) {
  const { data } = await axios.get(`${GET_PDF_URL}/${guid}`)
  return data
}

export async function sendProposal({ opportunityId, fxbuyflowSessionId }) {
  const { data } = await axios.post(`${POST_PROPOSAL_URL}`, {
    OpportunityId: opportunityId,
    fxbuyflowSessionId
  })
  return data
}

export async function getPreviewProposal({ opportunityId }) {
  const { data } = await axios.get(`${GET_PREVIEW_URL}/${opportunityId}`)
  return data.data
}

export async function checkDocumentAcceptance(payload) {
  try {
    const { data } = await axios.post(POST_DOCS_SAVE_CONTINUE_URL, payload)

    return {
      isAccepted: data.valid,
      message: data.messages[0].description
    }
  } catch (err) {
    const errorMsg = getApiErrorMessage(err)
    throw new Error(errorMsg?.description)
  }
}

export async function uploadDocuments(payload) {
  return axios.post(POST_DOCUMENT_URL, payload)
}

export async function getDocuments({ opportunityId, fxbuyflowSessionId }) {
  try {
    const { data: res } = await axios.post(GET_DOCUMENTS_INFO_URL, {
      opportunityId,
      fxbuyflowSessionId
    })
    return res.data || []
  } catch (e) {
    return []
  }
}

export async function deleteDocument(payload) {
  return axios.post(POST_DELETE_DOCUMENT_URL, { ...payload })
}

export async function getAvailableDocumentAcceptance(payload) {
  try {
    const { data: res } = await axios.post(
      POST_DOCUMENT_ACCEPTANCE_TYPE_URL,
      payload,
      {
        headers: {
          'user-device': getUserDevice()
        }
      }
    )
    return res.data
  } catch (error) {
    const errorMsg = getApiErrorMessage(error)
    throw new Error(errorMsg?.description)
  }
}

export async function expireDocuments(
  opportunityId,
  sessionId,
  fxbuyflowSessionId
) {
  try {
    await axios.post(`${POST_EXPIRE_DOCUMENTS_URL}`, {
      opportunityId,
      sessionId,
      fxbuyflowSessionId
    })
    return true
  } catch {
    throw new Error('Unable to expire documents...')
  }
}

export async function getMissingDocuments(fxbuyflowSessionId) {
  try {
    const { data: res } = await axios.get(
      `${GET_MISSING_DOCUMENTS_URL}/${fxbuyflowSessionId}`
    )
    return res.data
  } catch (error) {
    return []
  }
}

export async function getDocumentData(documentId, sessionId) {
  const { data: res } = await axios.get(`${GET_DOCUMENT_URL}`, {
    params: {
      id: documentId,
      sessionId
    }
  })
  return res.data
}

export async function signNow({ opportunityId, fxbuyflowSessionId }) {
  const { data } = await axios.post(`${POST_SIGN_NOW_URL}`, {
    OpportunityId: opportunityId,
    fxbuyflowSessionId
  })
  return data
}

export async function generateMoveFormPdf({
  opportunityId,
  fxbuyflowSessionId
}) {
  const { data: res } = await axios.post(POST_MOVE_FORM_GENERATION_URL, {
    OpportunityId: opportunityId,
    fxbuyflowSessionId
  })
  return res.data
}
