export const RESET_LOADING = 'RESET_LOADING'
export const SET_LOADING = 'SET_LOADING'
export const UPDATE_LOADING_MESSAGE = 'UPDATE_LOADING_MESSAGE'

export function setLoading(type, status = true) {
  return {
    type: SET_LOADING,
    payload: { [type]: status }
  }
}

export function resetPageLoading() {
  return {
    type: RESET_LOADING
  }
}

export function togglePageLoading(status) {
  return setLoading('page', status)
}

export function toggleAppLoading(status) {
  return setLoading('app', status)
}

export function updatePageLoadingMessage(message) {
  return {
    type: UPDATE_LOADING_MESSAGE,
    payload: { type: 'page', message }
  }
}

export function updateAppLoadingMessage(message) {
  return {
    type: UPDATE_LOADING_MESSAGE,
    payload: { type: 'app', message }
  }
}
