export default {
  internet: {
    name: 'Internet',
    id: 'internet',
    index: 0,
    disableUnselect: false,
    filterProperty: 'internetTierOfService',
    isVisible: true,
    visibilityMessage: null,
    filters: []
  },
  voice: {
    name: 'Voice',
    id: 'voice',
    index: 1,
    disableUnselect: false,
    filterProperty: 'voiceTierOfService',
    isVisible: true,
    visibilityMessage: null,
    filters: []
  },
  tv: {
    name: 'TV',
    id: 'tv',
    index: 2,
    disableUnselect: false,
    isVisible: true,
    visibilityMessage: null,
    filterProperty: 'videoTierOfService',
    filters: []
  },
  smartoffice: {
    name: 'Smart Office',
    id: 'smartoffice',
    index: 3,
    disableUnselect: false,
    isVisible: true,
    visibilityMessage: null,
    filterProperty: 'smartOfficeTierOfService',
    filters: []
  },
  mobile: {
    name: 'Mobile',
    id: 'mobile',
    index: 6,
    disableUnselect: false,
    isVisible: true,
    visibilityMessage: null,
    filterProperty: 'mobileTierOfService',
    filters: []
  },
  contractTerm: {
    name: 'Contract Term (Months)',
    id: 'contractTerm',
    index: 4,
    disableUnselect: false,
    filterProperty: 'contractTerm',
    filters: [
      {
        name: '36',
        id: 36
      },
      {
        name: '24',
        id: 24
      },
      {
        name: '12',
        id: 12
      },
      {
        name: 'Month to Month',
        id: 1
      }
    ]
  },
  searchTerm: {
    name: 'Enter Offer ID or key word',
    id: 'searchTerm',
    index: 5,
    filterProperty: 'searchTerm',
    excludeWhenEmpty: true,
    filter: {
      value: ''
    }
  }
}
