import React from 'react'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
import { theme as regTheme, darkTheme } from '../theme'
import { themeTypes } from '../constants/session'

export const ThemeContainer = ({ themeType, children }) => {
  const theme = themeType === themeTypes.regTheme ? regTheme : darkTheme
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

const mapStateToProps = state => ({
  themeType: state.session.themeType
})
ThemeContainer.propTypes = {
  themeType: PropTypes.string,
  children: PropTypes.object
}
export default connect(mapStateToProps)(ThemeContainer)
