import { sections, routes } from '../constants/navigation'
import * as actions from '../actions/navigation'

export const initialState = {
  defaultRoute: {},
  continueOrderRoute: {},
  sections,
  routes
}

function navigationReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case actions.SET_NAVIGATION: {
      return {
        ...state,
        ...payload
      }
    }

    case actions.UPDATE_ROUTE: {
      const { routeId, route } = payload

      return {
        ...state,
        routes: {
          ...state.routes,
          [routeId]: {
            ...state.routes[routeId],
            ...route
          }
        }
      }
    }

    default: {
      return state
    }
  }
}

export default navigationReducer
