import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects'
import * as actions from '../actions'
import { getOffers, getOfferDefaults } from '../services/offers'
import { orderRouteIds } from '../constants/navigation'
import {
  selectSelectedFilters,
  selectVisibleFilters
} from '../selectors/offers'
import { selectIsSmartOfficeCustomer } from '../selectors/customer'
import { selectActiveForm } from '../selectors/form'
import { getOfferFilterString } from '../utils/odata'
import { toggleAllFilters, getFiltersByCategory } from '../utils/filters'
import { errors, warning } from '../constants/content'
import { arrayToObject, sortBy } from '../utils/core'
import { smartOfficeWarning } from '../constants/form'
import { workflowStepIds } from '../constants/session'
import { contractTermTitles } from '../constants/offers'

const NEW_SEARCH_ACTIONS = [
  actions.UPDATE_OFFER_FILTERS,
  actions.SET_OFFER_SORT
]

export function* getOfferDefaultsSaga() {
  const currentOffers = yield select(({ offers }) => offers.results || [])

  // Don't get defaults if offers have already been fetched
  if (!currentOffers.length) {
    yield put(actions.togglePageLoading())
    const { sessionId, opportunityId, fxbuyflowSessionId } = yield select(
      ({ session }) => session
    )
    const customerDetails = yield select(
      ({ customer }) => customer.details || {}
    )
    const { division, canAgentSellSmartOffice } = customerDetails.site || {}
    const visibleFilters = yield select(selectVisibleFilters)
    const { contractTerm, ...filters } = visibleFilters

    const {
      preSelectedLob,
      availableContractTerms,
      serviceTiers,
      isVideoBoltOnEnabled = false,
      isSmartOfficeBoltOnEnabled = false,
      genesisBannerInfo,
      fdxBannerDetails: fdxBannerInfo,
      eponBannerDetails: eponBannerInfo
    } = yield call(getOfferDefaults, {
      sessionId,
      division,
      opportunityId,
      fxbuyflowSessionId
    })

    const serviceTierObject = arrayToObject(serviceTiers, 'lineOfBusiness')

    // Assign service tiers to lob filters
    const lobFilters = Object.entries(serviceTierObject).reduce(
      (all, [lob, val]) => ({
        ...all,
        [lob]: {
          ...all[lob],
          filters: getFiltersByCategory(val.filters)
        }
      }),
      filters
    )

    // Sort/Map filters
    const contractFilters = availableContractTerms
      .sort(sortBy(null, true))
      .map((term, index) => ({
        name: contractTermTitles[term]?.title || term,
        id: term,
        isSelected: index === 0
      }))

    // Select all current LOB filters
    const updatedFilters = preSelectedLob.reduce(
      (allFilters, filter) => ({
        ...allFilters,
        [filter.lineOfBusiness]: {
          ...allFilters[filter.lineOfBusiness],
          disableUnselect: filter.disableRemove,
          errorMessage: filter.errorMessage || errors.lobFilterRemoval,
          filters: toggleAllFilters(lobFilters[filter.lineOfBusiness].filters)
        }
      }),
      lobFilters
    )

    const isSmartOfficeCustomer = yield select(selectIsSmartOfficeCustomer)

    if (isSmartOfficeCustomer && !canAgentSellSmartOffice) {
      yield put(
        actions.setGlobalWarning(smartOfficeWarning, warning.smartofficeLicense)
      )
    }

    yield put(
      actions.setOfferFilters({
        ...updatedFilters,
        [contractTerm.id]: {
          ...contractTerm,
          disableUnselect: true,
          filters: contractFilters
        }
      })
    )
    yield put(
      actions.updateOffer({
        isVideoBoltOnEnabled,
        isSmartOfficeBoltOnEnabled,
        genesisBannerInfo,
        fdxBannerInfo,
        eponBannerInfo
      })
    )
    yield put(actions.getOffers())
    yield put(actions.togglePageLoading(false))
  }
}

export function* getOffersSaga({ type }) {
  const isNewSearch = NEW_SEARCH_ACTIONS.includes(type)
  const { sessionId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )
  const { params, metadata } = yield select(({ offers }) => offers.request)
  const selectedFilters = yield select(selectSelectedFilters)
  const $orderby = yield select(({ offers }) => offers.selectedSort)
  const refetchMoveOffers = yield select(
    ({ offers }) => offers?.refetchMoveOffers || false
  )

  const $filter = getOfferFilterString(selectedFilters)
  const $skip =
    isNewSearch || refetchMoveOffers ? 0 : params.$top * metadata.currentPage

  if (isNewSearch) {
    yield put(actions.setOffers(null, isNewSearch))
  }

  const isCBMStandaloneOfferFilterSelected = !!selectedFilters
    ?.mobileTierOfService?.length

  const res = yield call(
    getOffers,
    sessionId,
    fxbuyflowSessionId,
    {
      ...params,
      $skip,
      ...($filter && { $filter }),
      ...($orderby && { $orderby }),
      ...(isCBMStandaloneOfferFilterSelected && { '@cbmStandalone': true })
    },
    isNewSearch
  )

  yield put(
    actions.setOfferRequestData({
      metadata: res.metadata,
      params: {
        ...params,
        $skip,
        $orderby
      }
    })
  )

  yield put(actions.setOffers(res.results, isNewSearch))
}

export function* selectOfferSaga({ payload }) {
  const { offer } = payload
  const form = yield select(selectActiveForm)

  if (form.isValid) {
    yield put(actions.setSelectedOffer(offer))
    yield put(actions.clearDocumentStatus())
    yield put(
      actions.setSessionData({
        workflowStepId: workflowStepIds.PENDING_CONFIGURATION
      })
    )
    yield put(
      actions.navigateToNextOrderRoute(orderRouteIds.offers, {
        offerId: offer.id
      })
    )
  }
}

function* rootSaga() {
  yield takeLatest(actions.GET_OFFER_DEFAULTS, getOfferDefaultsSaga)
  yield takeLatest(NEW_SEARCH_ACTIONS, getOffersSaga)
  yield takeEvery(actions.GET_OFFERS, getOffersSaga)
  yield takeLatest(actions.SELECT_OFFER, selectOfferSaga)
}

export default rootSaga
