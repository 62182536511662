export const UPDATE_LAYOUT = 'UPDATE_LAYOUT'

// Configuration
export function updateLayout(payload) {
  return {
    type: UPDATE_LAYOUT,
    payload
  }
}

export function setConfigAccordionSections(configAccordionSections) {
  return {
    type: UPDATE_LAYOUT,
    payload: { configAccordionSections }
  }
}
