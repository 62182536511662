import axios from 'axios'
import { POST_CBM_LAUNCH_URL, POST_QQ_LAUNCH_URL } from '../constants/urls'

export async function getCbmLaunchUrl(fxbuyflowSessionId) {
  const { data: res } = await axios.post(POST_CBM_LAUNCH_URL, {
    fxbuyflowSessionId
  })
  return res.data.xfinityMobileLaunchUrl
}


export async function getQQUrl(body) {
  const { data: res } = await axios.post(POST_QQ_LAUNCH_URL, body)
  
  const qqURL = res?.quickQuoteBaseUrl + res?.guid
  return qqURL
}
