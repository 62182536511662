import { PDF_DOWNLOAD_REGEX } from '../constants/regex'

export const getContractFileName = name =>
  `${name.replace(PDF_DOWNLOAD_REGEX, '-')}_Contract.pdf`

export const convertFileToByteArray = (file, callback) => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject(new Error('Problem parsing file input'))
    }

    reader.onload = e => {
      const arrayBuffer = e.target.result
      const encodedString = btoa(
        new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      )
      resolve(callback(encodedString))
    }
    reader.readAsArrayBuffer(file)
  })
}

export const getMoveFormFileName = name =>
  `${name.replace(PDF_DOWNLOAD_REGEX, '-')}_Move.pdf`
