import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import BundleBuilderRedirect from '../components/navigation/BundleBuilderRedirect'
import * as actions from '../actions'

export const BundleBuilderLinkContainer = ({
  bundleBuilder = {},
  getBundleBuilderData,
  proceedToBundleBuilder,
  hideBundleBuilderModal,
  ...props
}) =>
  !!bundleBuilder.showModal && (
    <BundleBuilderRedirect
      onCancel={hideBundleBuilderModal}
      onProceed={proceedToBundleBuilder}
      fields={bundleBuilder.reasons}
      showSrTicketError={bundleBuilder?.showSrTicketError}
      {...props}
    />
  )

BundleBuilderLinkContainer.propTypes = {
  bundleBuilder: PropTypes.object,
  getBundleBuilderData: PropTypes.func.isRequired,
  proceedToBundleBuilder: PropTypes.func.isRequired,
  hideBundleBuilderModal: PropTypes.func
}

export const mapStateToProps = state => ({
  bundleBuilder: state.session.bundleBuilder,
  isLimitBBRoutingEnabled: state.session?.isLimitBBRoutingEnabled
})

export const mapDispatchToProps = {
  getBundleBuilderData: actions.getBundleBuilderData,
  proceedToBundleBuilder: actions.updateBundleBuilderData,
  hideBundleBuilderModal: actions.hideBundleBuilderModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BundleBuilderLinkContainer)
