import * as actions from '../actions/layout'
import { configTypes } from '../constants/configuration'

export const initialState = {
  configAccordionSections: {
    [configTypes.internet]: true
  }
}

function layoutReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case actions.UPDATE_LAYOUT: {
      return {
        ...state,
        ...payload
      }
    }

    default: {
      return state
    }
  }
}

export default layoutReducer
