import axios from 'axios'
import { LOCAL_TOKEN } from './constants/session'

const { REACT_APP_LOCAL_AUTH_URL, REACT_APP_LOCAL_AUTH_SECRET } = process.env
let authRequest = null

function getToken() {
  return axios.post(
    REACT_APP_LOCAL_AUTH_URL,
    {},
    {
      headers: {
        Authorization: REACT_APP_LOCAL_AUTH_SECRET
      },
      disableAuthorization: true
    }
  )
}

export async function getLocalAuthenticationToken() {
  // Cache request to avoid multiple calls during parallel requests
  authRequest = authRequest || getToken()

  const { data } = await authRequest
  authRequest = null
  localStorage.setItem(LOCAL_TOKEN, data.access_token)

  return {
    accessToken: data.access_token
  }
}
