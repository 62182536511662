import {
  selectLineItemByType,
  selectConfigItemByType,
  selectIpnProgrammingLineItems
} from '../../selectors/configuration'
import {
  getAllSelectedConfigItems,
  getSelectedConfigItem,
  getConfigItemsArray
} from '../configuration'
import {
  videoLineItemTypes as lineTypes,
  videoConfigurationItems,
  NO_SELECTION_IDS,
  hdOutletsIds
} from '../../constants/configuration'

import errors from '../../constants/errors/tv'

export default {
  validations: {
    x1: [
      {
        validation: validateIpnProgramming,
        error: errors.validateIpnProgrammingError
      },
      {
        validation: validateX1PrimaryOutlets,
        error: errors.validateX1PrimaryOutletsError
      },
      {
        validation: validateX1AdditionalOutlets,
        error: errors.validateX1AdditionalOutletsError
      }
    ],
    tvEquipment: [
      {
        validation: validateAdditionalOutletsCount,
        error: errors.validateAdditionalOutletsCountError
      },
      {
        validation: validateTvEquipment,
        error: errors.validateTvEquipment
      }
    ],
    mDTA: [
      {
        validation: validateMDTA,
        error: errors.validateMDTAError
      }
    ],
    videoPackageAdded: [
      {
        validation: validateVideoPackgeAdded,
        error: errors.validateVideoPackgeAdded
      }
    ]
  }
}

export function validateIpnProgramming(state, item) {
  const x1ConfigItem = item || selectConfigItemByType(lineTypes.x1)(state)

  if (!x1ConfigItem.isSelected) {
    const ipnProgramming = selectIpnProgrammingLineItems(state)
    return ipnProgramming.find(program => {
      const configItem = getSelectedConfigItem(program)

      return configItem && !NO_SELECTION_IDS.includes(configItem.id)
    })
  }

  return false
}

export function validateX1PrimaryOutlets(state, x1ConfigItem) {
  const primaryOutlet = selectConfigItemByType(lineTypes.primaryOutlet)(state)

  return (
    x1ConfigItem.isSelected &&
    primaryOutlet?.id !== videoConfigurationItems.primaryOutletHd
  )
}

export function validateX1AdditionalOutlets(state, x1ConfigItem) {
  const additionalOutlets = selectLineItemByType(lineTypes.additionalOutlets)(
    state
  )

  if (!x1ConfigItem.isSelected) {
    return false
  }

  return getConfigItemsArray(additionalOutlets).some(
    ({ id, quantity }) =>
      quantity && id !== videoConfigurationItems.additionalOutletStbHd
  )
}

export function validateTvEquipment(state, outletConfigItem) {
  const x1ConfigItem = selectConfigItemByType(lineTypes.x1)(state)

  if (!x1ConfigItem?.isSelected) {
    return false
  }

  return !hdOutletsIds.includes(outletConfigItem.id)
}

export function validateAdditionalOutletsCount(state, item) {
  if (item.lineItemType !== lineTypes.additionalOutlets) {
    return false
  }

  const additionalOutlets = selectLineItemByType(lineTypes.additionalOutlets)(
    state
  )
  const filteredOutlets = getAllSelectedConfigItems(additionalOutlets).filter(
    outlet => outlet?.id !== item.id
  )
  const currentTotal = filteredOutlets.reduce(
    (count, outlet) => outlet.quantity + count,
    0
  )

  // Use 15 since it includes primary outlet
  return currentTotal + item.quantity > 15
}

export function validateMDTA(state) {
  const primaryOutlet = selectLineItemByType(lineTypes.primaryOutlet)(state)
  const primaryOutletConfigItem = getAllSelectedConfigItems(primaryOutlet)

  const miniMDTAConfigItem = selectConfigItemByType(lineTypes.miniMDTA)(state)
  const mDTAConfigItem = selectConfigItemByType(lineTypes.miniMDTA)(state)

  return (
    !primaryOutletConfigItem?.length &&
    !miniMDTAConfigItem?.isSelected &&
    !mDTAConfigItem?.isSelected
  )
}

export function validateVideoPackgeAdded(state) {
  const { tv } = state?.configuration
  return tv?.isBoltOn && !tv?.configuredItems
}
