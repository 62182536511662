import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import history from './history'
import reducers from './reducers'
import customMiddleware from './middleware'
import sagas from './sagas'

const { NODE_ENV } = process.env
const sagaMiddleware = createSagaMiddleware()

const middleware = [
  sagaMiddleware,
  routerMiddleware(history),
  ...customMiddleware
]
let composeEnhancers = compose

if (NODE_ENV === 'development') {
  // eslint-disable-next-line no-underscore-dangle
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middleware))
)

// Needs to be called after createStore
sagaMiddleware.run(sagas)
