import React, { useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { FormProvider } from '../../hooks/useForm'
import { getFormErrors } from '../../utils/form'

const Form = ({ name: formName, onChange, onSubmit, children, ...props }) => {
  const form = { fields: {}, isSubmitted: props.isSubmitted, ...props.form }
  const formRef = useRef(form)
  const setActiveForm = useCallback(props.onSetActiveForm, [])
  const formMethods = {
    submitForm: handleFormSubmit
  }

  useEffect(() => {
    if (setActiveForm) {
      setActiveForm(formName)
    }

    return () => false
  }, [formName, setActiveForm])

  function handleFieldChange(name, field) {
    const currentForm = formRef.current
    const updatedField = {
      ...field,
      name,
      error: !field.success && field.error,
      isSubmitted: field.isSubmitted || false
    }
    const errors = getFormErrors({
      ...currentForm.fields,
      [name]: updatedField
    })

    // Cache current form
    formRef.current = {
      ...currentForm,
      fields: {
        ...currentForm.fields,
        ...(!!field.name && { [field.name]: field })
      },
      errors,
      isValid: !Object.keys(errors).length,
      isInvalid: !!Object.keys(errors).length
    }

    onChange({
      field: updatedField,
      formName,
      form: {
        ...currentForm,
        fields: {
          ...currentForm.fields,
          ...(!!field.name && { [field.name]: field })
        },
        errors,
        isValid: !Object.keys(errors).length,
        isInvalid: !!Object.keys(errors).length
      }
    })
  }

  function handleFieldSubmit(name, isSubmitted = true) {
    handleFieldChange(name, { isSubmitted })
  }

  function handleFormSubmit(isSubmitted = true, name = formName) {
    onSubmit({ formName: name, isSubmitted })
  }

  return (
    <FormProvider
      value={[
        form,
        {
          formMethods,
          fieldMethods: {
            updateField: handleFieldChange,
            submitField: handleFieldSubmit
          }
        }
      ]}
    >
      {children}
    </FormProvider>
  )
}

Form.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.shape({
    fields: PropTypes.object,
    errors: PropTypes.object
  }),
  isSubmitted: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onSetActiveForm: PropTypes.func,
  children: PropTypes.node
}

Form.defaultProps = {
  form: {}
}

export default Form
