export const configFieldNames = {
  core: 'coreConfiguration',
  internet: 'internetConfiguration',
  voice: 'voiceConfiguration',
  tv: 'tvConfiguration',
  office: 'smartOfficeConfiguration'
}

export const saveCartFieldName = 'saveCart'
export const docAcceptance = 'documentAcceptance'
export const docSave = 'docSave'
export const confirmCustBilling = 'confirmCustBilling'
export const docDelete = 'docDelete'
export const crcpError = 'crcpError'
export const timeSlot = 'timeSlot'
export const orderSubmitError = 'orderSubmitError'
export const smartOfficeWarning = 'smartOfficeWarning'
export const resubmitDocsExpired = 'resubmitDocsExpired'
export const abtMessage = 'abtMessage'
export const genesisIhatMessage = 'genesisIhatMessage'
export const saveMove = 'saveMove'
