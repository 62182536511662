export const initialState = {}

function pollingReducer(state = initialState, { type } = {}) {
  switch (type) {
    default: {
      return state
    }
  }
}

export default pollingReducer
