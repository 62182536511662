import PropTypes from 'prop-types'

// Custom PropTypes

// Generics
export const numberLikeProp = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string
])

// Models
export const bundleBuilderFieldPropType = PropTypes.shape({
  id: numberLikeProp,
  weight: PropTypes.number,
  text: PropTypes.string,
  comment: PropTypes.string,
  isCommentRequired: PropTypes.bool,
  commentLabel: PropTypes.string,
  commentPlaceholder: PropTypes.string,
  isSelected: PropTypes.bool
})

export const servicePropTypes = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.string,
  total: PropTypes.string,
  subtext: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string
    })
  )
})

export const featTogglePropTypes = PropTypes.shape({
  toggleID: PropTypes.string,
  division: PropTypes.string,
  region: PropTypes.string,
  corp: PropTypes.string,
  Role: PropTypes.string
})

export const featPropTypes = PropTypes.shape({
  toggleID: PropTypes.string,
  Name: PropTypes.string,
  isActive: PropTypes.bool,
  combinations: PropTypes.arrayOf(featTogglePropTypes)
})

export const featOptionPropTypes = PropTypes.objectOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      parent: PropTypes.string,
      profileId: PropTypes.string,
      profileName: PropTypes.string
    })
  )
)

export const routePropTypes = PropTypes.shape({
  id: PropTypes.string,
  path: PropTypes.string,
  title: PropTypes.string,
  component: PropTypes.func,
  disabled: PropTypes.bool
})

export const featureOptionsPropTypes = PropTypes.shape({
  divisions: PropTypes.arrayOf(PropTypes.object),
  regions: PropTypes.arrayOf(PropTypes.object),
  corps: PropTypes.arrayOf(PropTypes.object),
  userProfile: PropTypes.arrayOf(PropTypes.object),
  userRole: PropTypes.arrayOf(PropTypes.object)
})

export const tableColumnProps = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      renderCell: PropTypes.func,
      renderHeader: PropTypes.func
    }),
    PropTypes.string
  ])
)

export const tableDataProps = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
})

export const sessionPropTypes = PropTypes.shape({
  opportunityId: PropTypes.string,
  accountNumber: PropTypes.string,
  agentId: PropTypes.string,
  isAuthorized: PropTypes.bool
})

export const selectedCalendarPropType = PropTypes.shape({
  date: PropTypes.string,
  timeSlot: PropTypes.shape({
    id: PropTypes.string,
    time: PropTypes.string
  })
})

export const pricesPropTypes = PropTypes.shape({
  mrc: PropTypes.shape({
    price: PropTypes.number,
    discount: PropTypes.number
  }),
  nrc: PropTypes.shape({
    price: PropTypes.number,
    discount: PropTypes.number
  })
})

// Existing Services
export const orderServiceOfferPropTypes = PropTypes.shape({
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  quantity: PropTypes.number,
  isHidden: PropTypes.bool,
  additionalLineCartConfigurations: PropTypes.array
})

export const orderServiceCartItemDetailsPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  shoppingElementId: PropTypes.string,
  quantity: PropTypes.number,
  type: PropTypes.string,
  isHidden: PropTypes.bool,
  isLegacy: PropTypes.bool,
  prices: pricesPropTypes
})

export const orderServiceCartItemPropTypes = PropTypes.shape({
  name: PropTypes.string,
  lineOfBusiness: PropTypes.string,
  totalDiscounts: PropTypes.number,
  offer: orderServiceOfferPropTypes,
  cartItemDetails: PropTypes.arrayOf(orderServiceCartItemDetailsPropTypes)
})

export const orderServicesPropTypes = PropTypes.shape({
  offerId: PropTypes.string,
  contractTerm: PropTypes.number,
  rackRate: PropTypes.number,
  promoPrice: PropTypes.number,
  totalMrcPrice: PropTypes.number,
  totalNrcPrice: PropTypes.number,
  coreServiceStart: PropTypes.string,
  coreServiceEnd: PropTypes.string,
  isPackageOffer: PropTypes.bool,
  e911EmailOptIn: PropTypes.bool,
  offer: orderServiceOfferPropTypes,
  cartItems: PropTypes.arrayOf(orderServiceCartItemPropTypes),
  productDetails: PropTypes.shape({
    internet: orderServiceCartItemPropTypes,
    voice: orderServiceCartItemPropTypes,
    tv: orderServiceCartItemPropTypes,
    smartoffice: orderServiceCartItemPropTypes,
    additionalOptions: PropTypes.shape({
      cartItemDetails: PropTypes.arrayOf(orderServiceCartItemDetailsPropTypes)
    })
  })
})

// Configuration
export const boltOnPropTypes = PropTypes.shape({
  lineOfBusiness: PropTypes.string,
  selectedOfferId: numberLikeProp,
  allowDisconnect: PropTypes.bool,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      id: numberLikeProp,
      name: PropTypes.string
    })
  )
})

// React Router propTypes
const match = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.object,
  path: PropTypes.string,
  url: PropTypes.string
})

const location = PropTypes.shape({
  hash: PropTypes.string,
  key: PropTypes.string,
  pathname: PropTypes.string,
  search: PropTypes.string,
  state: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string
  ])
})

const history = PropTypes.shape({
  action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']),
  block: PropTypes.func,
  canGo: PropTypes.func,
  createHref: PropTypes.func,
  entries: PropTypes.arrayOf(location),
  go: PropTypes.func,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
  index: PropTypes.number,
  length: PropTypes.number,
  listen: PropTypes.func,
  location,
  push: PropTypes.func,
  replace: PropTypes.func
})

export const routerPropTypes = {
  match,
  location,
  history
}

export const modalPropTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  visible: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  allowClose: PropTypes.bool,
  cancelButtonProps: PropTypes.object
}

export const quantityDropDownPropTypes = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}
