import { put, select } from 'redux-saga/effects'
import * as actions from '../../actions'
import internetValidations from '../../utils/validations/internet'
import { configFieldNames } from '../../constants/form'
import { getValidationError } from '../../utils/validations/core'

export function* validateSecurityEdgeEmailSaga() {
  const state = yield select()

  const { error } = getValidationError(
    internetValidations.validations.securityEdgeEmail,
    state
  )

  if (error) {
    yield put(actions.setConfigError(configFieldNames.internet, error))
    return error
  }

  return false
}

export function* validateIpJustificationsSaga() {
  const state = yield select()

  const { error } = getValidationError(
    internetValidations.validations.ipJustifications,
    state
  )

  if (error) {
    yield put(actions.setConfigError(configFieldNames.internet, error))
    return error
  }

  return false
}

export function* validateSecurityEdgeSaveSaga() {
  const state = yield select()

  const { error } = getValidationError(
    internetValidations.validations.securityEdgeOnSave,
    state
  )

  if (error) {
    yield put(actions.setConfigError(configFieldNames.internet, error))
    return error
  }

  return false
}

export function* validateSaveInternet() {
  return [
    yield* validateSecurityEdgeEmailSaga(),
    yield* validateIpJustificationsSaga(),
    yield* validateSecurityEdgeSaveSaga()
  ]
}
