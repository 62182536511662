import {
  formatDate,
  getNextAvailableDate,
  isDateSameOrAfter,
  isDateSameOrBefore,
  getAddedBusinessDaysDate,
  getSubtractedBusinessDaysDate
} from './dates'
import { calendarTypes, GENESIS_IHAT, timeSlots } from '../constants/scheduling'
import { CALENDAR_BASE_URL, API_BASE_URL } from '../constants/urls'
import { orderSubmitActions } from '../constants/order'

const { orderSubmit: ORDER_SUBMIT } = orderSubmitActions

export function parseSchedulingCalendars({
  calendars,
  isEscalationAvailable,
  apiToCall,
  apiName,
  messages = [],
  ...data
}) {
  const { prewire, install, escalation } = calendarTypes
  const apiBase = apiName === ORDER_SUBMIT ? API_BASE_URL : CALENDAR_BASE_URL
  const installMessageCodes = messages.reduce(
    (codes, msg) => (msg.code ? [...codes, msg.code] : codes),
    []
  )
  const genesisMsg = messages.find(message => message.code === GENESIS_IHAT)

  return {
    ...data,
    apiUrl: apiBase + apiToCall,
    apiAction: apiName,
    installMessageCodes,
    genesisIhatMessage: genesisMsg?.description || '',
    hasCalendars: !!calendars?.length,
    ...(calendars || []).reduce(
      (cals, cal) => ({
        ...cals,
        [cal.calendarType.toLowerCase() === calendarTypes.prewire
          ? prewire
          : install]: {
          ...cal,
          selected: {
            date: cal.calendarDates[0].date,
            timeSlot: cal.calendarDates[0].availableTimeSlots[0]
          }
        }
      }),
      {}
    ),
    ...(isEscalationAvailable && {
      [escalation]: {
        selected: {
          date: formatDate(
            getNextAvailableDate(new Date(), data.disabledDates),
            'YYYY-MM-DD'
          ),
          timeSlot: timeSlots[2]
        }
      }
    })
  }
}

export function getFilteredCalendar(calendar) {
  const {
    prewire,
    install,
    preWireMinGapBeforeInstallCalendar: minDaysBeforeInstall,
    installationMessage
  } = calendar

  if (!(prewire && install) || installationMessage) {
    return calendar
  }

  const minDays = minDaysBeforeInstall || 0
  const { date: lastInstallDate } = install.calendarDates[
    install.calendarDates.length - 1
  ]
  const allCalendarDates = install.calendarDates.map(d => d.date)

  const minInstallDate = getAddedBusinessDaysDate(
    prewire.selected.date,
    minDays,
    allCalendarDates
  )
  const maxPreInstallDate = getSubtractedBusinessDaysDate(
    lastInstallDate,
    minDays,
    allCalendarDates
  )

  const defaultInstallDateTime = install.calendarDates.find(({ date }) =>
    isDateSameOrAfter(date, minInstallDate)
  )
  const filteredInstallDates = install.calendarDates.filter(({ date }) =>
    isDateSameOrAfter(date, defaultInstallDateTime.date)
  )
  const filteredPreWireDates = prewire.calendarDates.filter(({ date }) =>
    isDateSameOrBefore(date, maxPreInstallDate)
  )

  return {
    ...calendar,
    allCalendarDates,
    install: {
      ...install,
      calendarDates: filteredInstallDates,
      selected: {
        date: filteredInstallDates[0].date,
        timeSlot: filteredInstallDates[0].availableTimeSlots[0]
      }
    },
    prewire: {
      ...prewire,
      calendarDates: filteredPreWireDates,
      selected: {
        date: filteredPreWireDates[0].date,
        timeSlot: filteredPreWireDates[0].availableTimeSlots[0]
      }
    }
  }
}

export function getUpdatedCalendarDate(state, payload) {
  const { calendar } = state.scheduling
  const {
    install,
    prewire,
    allCalendarDates,
    preWireMinGapBeforeInstallCalendar: minDaysBeforeInstall
  } = calendar

  // Don't unnecessarily check if valid
  if (!(prewire && install)) {
    return false
  }

  if (isCalendarValid(calendar, payload)) {
    return false
  }

  const { calendarType, selectedDate } = payload
  const isPreWire = calendarType === calendarTypes.prewire
  const currentTimeSlot = isPreWire
    ? install.selected.timeSlot
    : prewire.selected.timeSlot

  const nextValidDate = isPreWire
    ? getAddedBusinessDaysDate(
        selectedDate.date,
        minDaysBeforeInstall,
        allCalendarDates
      )
    : getSubtractedBusinessDaysDate(
        selectedDate.date,
        minDaysBeforeInstall,
        allCalendarDates
      )

  const { date: updatedCalDate, availableTimeSlots } = isPreWire
    ? findAvailableDate(install.calendarDates, nextValidDate, isDateSameOrAfter)
    : findAvailableDate(
        [...prewire.calendarDates].reverse(),
        nextValidDate,
        isDateSameOrBefore
      )
  const updatedTimeSlot =
    availableTimeSlots.find(slot => slot.id === currentTimeSlot.id) ||
    availableTimeSlots[0]

  return {
    calType: isPreWire ? calendarTypes.install : calendarTypes.prewire,
    updatedDate: {
      date: updatedCalDate,
      timeSlot: updatedTimeSlot
    }
  }
}

function isCalendarValid(calendar, payload) {
  const { calendarType, selectedDate } = payload
  const {
    install,
    prewire,
    allCalendarDates,
    preWireMinGapBeforeInstallCalendar: minDaysBeforeInstall
  } = calendar
  const isPreWire = calendarType === calendarTypes.prewire

  if (isPreWire) {
    const date = getAddedBusinessDaysDate(
      selectedDate.date,
      minDaysBeforeInstall,
      allCalendarDates
    )
    return isDateSameOrBefore(date, install.selected.date)
  }

  const nextValidDate = getSubtractedBusinessDaysDate(
    selectedDate.date,
    minDaysBeforeInstall,
    allCalendarDates
  )
  return isDateSameOrAfter(nextValidDate, prewire.selected.date)
}

const findAvailableDate = (calDates, dt, fn) =>
  calDates.find(calDate => fn(calDate.date, dt))
