export const offerFilterTypes = {
  internet: 'internet',
  voice: 'voice',
  tv: 'tv',
  office: 'smartoffice',
  mobile: 'mobile',
  contractTerm: 'contractTerm',
  searchTerm: 'searchTerm'
}

export const offerTableColumns = {
  bundle: {
    title: 'Bundle'
  },
  internet: {
    title: 'Internet'
  },
  voice: {
    title: 'Voice'
  },
  tv: {
    title: 'TV'
  },
  extras: {
    title: 'Extras'
  }
}

export const offerLobKeys = {
  internet: 'internet',
  voice: 'phone',
  tv: 'tv',
  office: 'smartoffice'
}

export const boltOnMap = {
  [offerFilterTypes.tv]: 'isVideoBoltOnEligible',
  [offerFilterTypes.office]: 'isSmartOfficeBoltOnEligible'
}
export const lobFilterMap = {
  internetTierOfService: offerFilterTypes.internet,
  videoTierOfService: offerFilterTypes.tv,
  voiceTierOfService: offerFilterTypes.voice,
  smartOfficeTierOfService: offerFilterTypes.office
}

export const searchTermTypes = [
  { name: 'id', type: 'text' },
  { name: 'name', type: 'text' },
  { name: 'fullPrice', type: 'number' },
  { name: 'downloadSpeed', type: 'number' },
  { name: 'monthlyCharges', type: 'number' },
  { name: 'voiceHighlight1', type: 'text' },
  { name: 'videoTierOfService', type: 'text' },
  { name: 'detailsandRestrictions', type: 'text' }
]

export const contractTermTitles = {
  1: { title: 'Month to Month' },
  12: { title: 12 },
  24: { title: 24 },
  36: { title: 36 }
}

export const biSpeedTiers = [
  'BI Standard',
  'BI Performance',
  'BI Advanced',
  'BI Premium',
  'BI Gigabit Extra'
]

export const cbmStandaloneOfferItem = 'OFFER:CBMSTANDAONE'

export const cbmStandaloneOfferId = 'CBM-STANDALONE'

export const offerVisibiltyTypes = {
  offerCompetitive: 'offerCompetitive',
  digital: 'primary',
  default: 'border'
}
