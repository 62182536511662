import { breakpoints } from '../theme'
import { themeTypes } from '../constants/session'

// Returns if id is within same base workflow range
export function isSameWorkflowStepRange(workflowId, baseWorkflowId) {
  return workflowId >= baseWorkflowId && workflowId <= baseWorkflowId + 100
}

export function getUserDevice() {
  const windowWidth = window.innerWidth
  if (windowWidth > breakpoints.tablet) {
    return 'desktop'
  }
  return 'mobile'
}

export function getUserLocalThemePreference() {
  const userThemePreference = localStorage.getItem('userThemePreference')
  if (userThemePreference) {
    return userThemePreference
  }
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? themeTypes.darkTheme
    : themeTypes.regTheme
}
