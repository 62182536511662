import axios from 'axios'
import {
  GET_FEAT_ALL_URL,
  GET_FEAT_DETAILS_URL,
  GET_FEAT_LOCATIONS_URL,
  PUT_FEAT_DETAILS_URL,
  PUT_FEAT_TOGGLE_URL,
  POST_FEAT_TOGGLE_URL,
  GET_FEAT_ROLES_URL,
  GET_FEAT_PROFILES_URL
} from '../constants/urls'
import { requestWithRetry } from '../utils/api'

export async function getFeatures(id) {
  const url = id ? `${GET_FEAT_DETAILS_URL}/${id}` : GET_FEAT_ALL_URL
  const { data } = await requestWithRetry(url)
  return data
}

export async function getFeatureOptions() {
  const { data: locations } = await requestWithRetry(GET_FEAT_LOCATIONS_URL)
  const { data: roles } = await requestWithRetry(GET_FEAT_ROLES_URL)
  const { data: profiles } = await requestWithRetry(GET_FEAT_PROFILES_URL)

  return { ...locations, roles, profileId: profiles }
}

export async function updateFeature(feature) {
  const { featureID } = feature
  const { data } = await axios({
    method: 'PUT',
    url: `${PUT_FEAT_DETAILS_URL}/${featureID}`,
    data: feature
  })

  return data
}

export async function updateFeatureToggle(featureId, { toggleID, ...toggle }) {
  const url = toggleID
    ? `${PUT_FEAT_TOGGLE_URL}/${toggleID}`
    : `${POST_FEAT_TOGGLE_URL}/${featureId}`

  const { data } = await axios({
    method: toggleID ? 'PUT' : 'POST',
    url,
    data: toggle
  })
  return data
}
