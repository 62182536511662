const { ENV_MAP = {}, ...ENV } = process.env
const HOST_ENV = window.env?.HOST_ENV
const API_ENV = window.env?.API_ENV || HOST_ENV
const HOST_ENV_REGEX = /<API_ENV>/
const PROD_HOST_ENV_REGEX = /<API_ENV>.np/
const PROD_ENV_VARIABLES = ['production', 'g']
const PROD_API_VARIABLE = 'api-g'

const prodConfig = {
  replacementRules: {
    REACT_APP_API_BASE_URL: {
      replacement: env => env.value.replace(/<API_ENV>.np/, 'g')
    },
    REACT_APP_QUICKQUOTE_URL: {
      replacement: env => env.value.replace(/<API_ENV>.np/, 'g')
    },
    REACT_APP_RPC_URL: {
      replacement: env => env.value.replace(/<API_ENV>.np/, 'hub-g')
    }
  }
}

const COMBINED_ENVS = Object.entries({
  ...ENV,
  API_ENV,
  HOST_ENV,
  ...ENV_MAP[HOST_ENV]
}).reduce((vars, [key, value]) => {
  const isProdEnv = PROD_ENV_VARIABLES.includes(API_ENV)
  const replacementRule = prodConfig.replacementRules[key]

  // replace only diff pattern prod urls
  if (isProdEnv && replacementRule) {
    return {
      ...vars,
      [key]: replacementRule.replacement({ value })
    }
  }

  return {
    ...vars,
    [key]: isProdEnv
      ? value?.replace(PROD_HOST_ENV_REGEX, PROD_API_VARIABLE)
      : value?.replace(HOST_ENV_REGEX, API_ENV)
  }
}, {})

export function getEnv() {
  return COMBINED_ENVS
}
