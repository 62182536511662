import { createContext } from 'react'

// Used for configuring per LOB
// Add all these as null for intellisense
export const ConfigContext = createContext({
  businessLine: null,
  configurationOptions: {},
  configureItem: null,
  getLineItem: null,
  getSelectedConfigItem: null,
  getConfigItem: null,
  getVoiceLineItem: null,
  setupVoiceLines: null,
  getAllVoiceLineTypes: null,
  validateVoiceSection: null
})

export const FeatureSwitchContext = createContext({
  disabledEnvs: [],
  features: []
})
