import { addMinutes } from '../utils/dates'

export const START_POLLING = 'START_POLLING'
export const STOP_POLLING = 'STOP_POLLING'

export const startPolling = ({
  pollingAction,
  interval = 5000,
  timeoutDate = addMinutes(new Date(), 2),
  data
}) => ({
  type: START_POLLING,
  payload: {
    pollingAction,
    interval,
    timeoutDate,
    data
  }
})

export const stopPolling = id => ({
  type: STOP_POLLING,
  payload: { id }
})
