import React, { useState, useEffect, useCallback } from 'react'
import { Modal as AntModal } from 'antd'
import { AiOutlineClose } from 'react-icons/ai'
import styled from 'styled-components'
import { modalPropTypes } from '../../propTypes'

const Modal = ({
  children,
  content,
  allowClose,
  visible,
  onConfirm,
  onCancel,
  ...props
}) => {
  const [isVisible, setVisible] = useState(false)
  const hasRenderFunction = typeof children === 'function'

  useEffect(() => {
    setVisible(visible)
  }, [visible])

  const toggleModal = useCallback(
    method => {
      setVisible(!isVisible)
      if (method === 'onOk' && onConfirm) {
        onConfirm()
      }
      if (method === 'onCancel' && onCancel) {
        onCancel()
      }
    },
    [isVisible, onConfirm, onCancel]
  )

  return (
    <>
      <AntModal
        {...props}
        visible={isVisible}
        onOk={() => toggleModal('onOk')}
        onCancel={() => toggleModal('onCancel')}
        closable={allowClose}
        closeIcon={(
          <StyledIcon
            tabIndex="0"
            alt="close"
            size="1.1rem"
          />
        )}
        cancelButtonProps={
          !allowClose
            ? {
                ...props.cancelButtonProps,
                style: { display: 'none' }
              }
            : props.cancelButtonProps
        }
      >
        {hasRenderFunction ? content : children}
      </AntModal>
      {hasRenderFunction &&
        children({
          show: () => toggleModal(),
          hide: () => toggleModal()
        })}
    </>
  )
}

const StyledIcon = styled(AiOutlineClose)`
  :focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors?.primary};
  }
`

// Export AntModal Methods
Modal.info = AntModal.info
Modal.success = AntModal.success
Modal.error = AntModal.error
Modal.warning = AntModal.warning
Modal.confirm = AntModal.confirm

Modal.defaultProps = {
  allowClose: true
}

Modal.propTypes = modalPropTypes

export default Modal
