import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Button from './Button'
import { compareObjectArrays, sortBy } from '../../utils/core'
import { inverseVisibleFocusStyles } from '../../theme'

const selectLabelPositions = {
  above: 'above',
  inside: 'inside'
}
const buttonPositions = {
  between: 'between',
  below: 'below'
}

const ListSelect = ({
  leftSelectData,
  rightSelectData,
  sortOrder,
  sortKey,
  moveLeftButtonText,
  moveRightButtonText,
  leftSelectLabel,
  rightSelectLabel,
  selectLabelPosition,
  selectAreaStyle,
  leftSelectAreaStyle,
  rightSelectAreaStyle,
  moveLeftButtonStyles,
  moveRightButtonStyles,
  onMoveRight,
  onMoveLeft,
  buttonPosition,
  onChange,
  leftSelectAriaLabel,
  rightSelectAriaLabel
}) => {
  const leftSelect = useRef(null)
  const rightSelect = useRef(null)
  const [leftOptions, setLeftOptions] = useState(
    leftSelectData.sort(sortBy(sortKey, sortOrder === 'desc'))
  )
  const [rightOptions, setRightOptions] = useState(
    rightSelectData.sort(sortBy(sortKey, sortOrder === 'desc'))
  )
  const showLabelAbove = selectLabelPosition === selectLabelPositions.above
  const directionsConfig = {
    right: {
      fromRef: leftSelect,
      toRef: rightSelect,
      fromOptions: leftOptions,
      toOptions: rightOptions,
      setFromOptions: setLeftOptions,
      setToOptions: setRightOptions
    },
    left: {
      fromRef: rightSelect,
      toRef: leftSelect,
      fromOptions: rightOptions,
      toOptions: leftOptions,
      setFromOptions: setRightOptions,
      setToOptions: setLeftOptions
    }
  }

  useEffect(() => {
    setRightOptions(rightSelectData.sort(sortBy(sortKey, sortOrder === 'desc')))
  }, [rightSelectData, sortKey, sortOrder])

  function getSelectedOptions(options) {
    return [...options].filter(({ selected }) => selected)
  }

  function moveOptions(direction) {
    const moveObject = directionsConfig[direction]

    const fromSelections = compareObjectArrays(
      getSelectedOptions(moveObject.fromRef.current.options),
      moveObject.fromOptions,
      'value',
      'intersection'
    )

    const toMove = compareObjectArrays(
      moveObject.fromOptions,
      fromSelections,
      'value',
      'intersection'
    )
    const newToOptions = [...moveObject.toOptions, ...toMove].sort(
      sortBy(sortKey, sortOrder === 'desc')
    )
    moveObject.setToOptions(newToOptions)

    const newFromOptions = compareObjectArrays(
      moveObject.fromOptions,
      toMove,
      'value',
      'leftDifference'
    ).sort(sortBy(sortKey, sortOrder === 'desc'))

    moveObject.setFromOptions(newFromOptions)
    if (onChange) {
      // the right select box is assumed to be the selected values
      onChange(direction === 'right' ? newToOptions : newFromOptions)
    }
  }

  function handleMoveRight() {
    moveOptions('right')
    if (onMoveRight) {
      onMoveRight(rightSelect.current)
    }
  }

  function handleMoveLeft() {
    moveOptions('left')
    if (onMoveLeft) {
      onMoveLeft(leftSelect.current)
    }
  }

  function renderMoveButtons() {
    return (
      <StyledButtonContainer buttonPosition={buttonPosition}>
        <StyledButton
          onClick={handleMoveRight}
          style={moveRightButtonStyles}
        >
          {moveRightButtonText}
        </StyledButton>
        <StyledButton
          onClick={handleMoveLeft}
          style={moveLeftButtonStyles}
        >
          {moveLeftButtonText}
        </StyledButton>
      </StyledButtonContainer>
    )
  }

  return (
    <StyledListSelectWrapper>
      <StyledSelectContainer>
        <StyledSelectWrapper selectLabel={!showLabelAbove && leftSelectLabel}>
          {showLabelAbove && leftSelectLabel && (
            <StyledLabel>{leftSelectLabel}</StyledLabel>
          )}
          <StyledSelect
            multiple
            ref={leftSelect}
            style={{ ...selectAreaStyle, ...leftSelectAreaStyle }}
            size="5"
            showBorder={showLabelAbove}
            onDoubleClick={handleMoveRight}
            ariaLabel={leftSelectAriaLabel}
          >
            {leftOptions.map(opt => (
              <StyledOption
                key={opt.value}
                value={opt.value}
              >
                {opt.display || opt.value}
              </StyledOption>
            ))}
          </StyledSelect>
        </StyledSelectWrapper>
        {buttonPosition === buttonPositions.between && renderMoveButtons()}
        <StyledSelectWrapper selectLabel={!showLabelAbove && rightSelectLabel}>
          {showLabelAbove && rightSelectLabel && (
            <StyledLabel>{rightSelectLabel}</StyledLabel>
          )}
          <StyledSelect
            multiple
            ref={rightSelect}
            style={{ ...selectAreaStyle, ...rightSelectAreaStyle }}
            showBorder={showLabelAbove}
            onDoubleClick={handleMoveLeft}
            ariaLabel={rightSelectAriaLabel}
          >
            {rightOptions.map(opt => (
              <StyledOption
                key={opt.value}
                value={opt.value}
              >
                {opt.display || opt.value}
              </StyledOption>
            ))}
          </StyledSelect>
        </StyledSelectWrapper>
      </StyledSelectContainer>
      {buttonPosition === buttonPositions.below && renderMoveButtons()}
    </StyledListSelectWrapper>
  )
}

ListSelect.defaultProps = {
  leftSelectData: [],
  rightSelectData: [],
  sortOrder: 'asc',
  sortKey: 'value',
  moveLeftButtonText: '<',
  moveRightButtonText: '>',
  leftSelectLabel: '',
  rightSelectLabel: '',
  selectLabelPosition: 'above',
  selectAreaStyle: {},
  moveLeftButtonStyles: {},
  moveRightButtonStyles: {},
  buttonPosition: 'between',
  leftSelectAreaStyle: {},
  rightSelectAreaStyle: {}
}

ListSelect.propTypes = {
  leftSelectData: PropTypes.array,
  rightSelectData: PropTypes.array,
  sortOrder: PropTypes.string,
  sortKey: PropTypes.string,
  moveLeftButtonText: PropTypes.string,
  moveRightButtonText: PropTypes.string,
  leftSelectLabel: PropTypes.string,
  rightSelectLabel: PropTypes.string,
  selectLabelPosition: PropTypes.string,
  selectAreaStyle: PropTypes.object,
  moveLeftButtonStyles: PropTypes.object,
  moveRightButtonStyles: PropTypes.object,
  buttonPosition: PropTypes.string,
  leftSelectAreaStyle: PropTypes.object,
  rightSelectAreaStyle: PropTypes.object,
  onMoveRight: PropTypes.func,
  onMoveLeft: PropTypes.func,
  onChange: PropTypes.func,
  leftSelectAriaLabel: PropTypes.string,
  rightSelectAriaLabel: PropTypes.string
}

const StyledListSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSelectContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 8rem;
`

const StyledSelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 10px;

  label {
    display: flex;
    margin-bottom: 0.5rem;
  }

  ${({ selectLabel }) =>
    !!selectLabel &&
    css`
      border: 2px solid ${({ theme }) => theme.colors?.inputBorder};
      padding-top: 1.3rem;

      &:before {
        content: '${selectLabel}';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        padding-left: 3px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors?.inputBorder};
        background: ${({ theme }) => theme.colors?.inputBackground};
        width: 100%;
      }
    `}
`

const StyledSelect = styled.select.attrs(props => ({
  ...props,
  'aria-label': props.ariaLabel
}))`
  width: 10rem;
  display: flex;
  flex: 1;
  width: 100%;
  border: none;
  min-height: 6rem;
  &:focus-visible {
    outline: none;
  }

  ${({ showBorder }) =>
    showBorder &&
    css`
      border: 2px solid ${({ theme }) => theme.colors?.inputBorder};
    `}

  option:disabled {
    color: ${({ theme }) => theme.colors?.inputBorder};
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: space-around;
  height: 6rem;
  ${({ buttonPosition }) => {
    if (buttonPosition === 'between') {
      return css`
        flex-direction: column;
      `
    }

    return css`
      flex-direction: row;
      width: 100%;
      margin-top: 0.7rem;
    `
  }};
`

const StyledButton = styled(Button)`
  height: 2rem;
  max-width: 5rem;
  min-width: 0;
  margin: 0 0.5rem;
  border: 2px solid ${({ theme }) => theme.colors?.inputBorder};

  ${inverseVisibleFocusStyles.button}
`

const StyledLabel = styled.label`
  margin-bottom: 2px;
  font-weight: 800;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors?.text};
`

const StyledOption = styled.option`
  padding-left: 12px;
`

export default ListSelect
