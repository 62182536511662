import * as actions from '../actions'
import offerFilters from '../data/offerFilters'
import { offerTableColumns } from '../constants/offers'

export const initialState = {
  selectedOffer: undefined,
  results: null,
  viewedOffers: {},
  request: {
    metadata: {
      totalPages: 0,
      currentPage: 0
    },
    params: {
      $top: 20,
      $skip: 0
    }
  },
  filters: offerFilters,
  tableColumns: offerTableColumns,
  selectedSort: 'displayOrder'
}

function offersReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case actions.SET_ALL_OFFERS: {
      const { offers, shouldReplace } = payload
      const { results } = state

      return {
        ...state,
        results: shouldReplace ? offers : (results || []).concat(offers)
      }
    }

    case actions.SET_SELECTED_OFFER: {
      const { offer: selectedOffer } = payload
      return {
        ...state,
        selectedOffer,
        viewedOffers: {
          ...state.viewedOffers,
          [selectedOffer.id]: selectedOffer
        }
      }
    }

    case actions.UPDATE_OFFER_FILTERS: {
      const { id, data } = payload
      const updatedFilter = Array.isArray(data)
        ? { filters: data }
        : { filter: data }

      return {
        ...state,
        filters: {
          ...state.filters,
          [id]: {
            ...state.filters[id],
            ...updatedFilter
          }
        }
      }
    }

    case actions.UPDATE_OFFER_FILTER_VISIBILITY: {
      const { id, isVisible } = payload

      return {
        ...state,
        filters: {
          ...state.filters,
          [id]: {
            ...state.filters[id],
            isVisible
          }
        }
      }
    }

    case actions.SET_OFFER_REQUEST_DATA: {
      return {
        ...state,
        request: {
          ...state.request,
          ...payload
        }
      }
    }

    case actions.SET_OFFER_SORT: {
      return {
        ...state,
        selectedSort: payload.sort
      }
    }

    case actions.SET_OFFER_FILTERS: {
      const { filters } = payload

      return {
        ...state,
        filters
      }
    }

    case actions.UPDATE_OFFER: {
      return {
        ...state,
        ...payload
      }
    }

    default: {
      return state
    }
  }
}

export default offersReducer
