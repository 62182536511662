import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BundleBuilderForm from './BundleBuilderForm'
import Modal from '../../core/Modal'
import { bundleBuilderFieldPropType } from '../../../propTypes'
import LimitBundleBuilderForm from './LimitBundleBuilderForm'

const BundleBuilderRedirect = ({
  fields,
  onProceed,
  onCancel,
  isLimitBBRoutingEnabled,
  showSrTicketError
}) => {
  function handleOnProceed(data, isLimitBB) {
    const selected = Object.values(data).filter(({ isSelected }) => isSelected)

    onProceed(selected, isLimitBB)
  }

  return (
    <>
      {!!fields && (
        <StyledModal
          title={isLimitBBRoutingEnabled ? 'Routing Verification' : ''}
          visible
          allowClose={false}
          footer={false}
          maskClosable={false}
          maskStyle={
            isLimitBBRoutingEnabled && {
              backgroundColor: '#000000d9'
            }
          }
          width={isLimitBBRoutingEnabled ? 650 : 520}
          keyboard={false}
        >
          {isLimitBBRoutingEnabled ? (
            <LimitBundleBuilderForm
              fields={fields}
              onCancel={onCancel}
              onProceed={handleOnProceed}
              showSrTicketError={showSrTicketError}
            />
          ) : (
            <BundleBuilderForm
              fields={fields}
              onCancel={onCancel}
              onProceed={handleOnProceed}
            />
          )}
        </StyledModal>
      )}
    </>
  )
}

BundleBuilderRedirect.propTypes = {
  fields: PropTypes.arrayOf(bundleBuilderFieldPropType),
  onProceed: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLimitBBRoutingEnabled: PropTypes.bool,
  showSrTicketError: PropTypes.bool
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 5px 10px;
  }

  .ant-modal-header {
    text-align: center;
  }

  .ant-modal-title {
    font-size: 1.2rem;
  }
`

export default BundleBuilderRedirect
