import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useForm } from '../../hooks/useForm'
import Field from '../elements/Field'

const inputTypes = {
  select: Field.Select,
  listSelect: Field.ListSelect,
  calendar: Field.Calendar,
  file: Field.File,
  textarea: Field.TextArea,
  checkbox: Field.Checkbox
}

const FormField = ({ name, type, onBlur, isRequired, ...props }) => {
  const [fieldForm, formMethods] = useForm(name)
  const { form: parentForm } = fieldForm
  const currentValue = props.defaultValue || props.value
  const updateField = useCallback(updateFieldCallback, [props.error])
  const isSubmitted = getSubmitState()
  const Input = inputTypes[type] || Field
  const error = getError()

  useEffect(() => {
    updateField({ error })
  }, [updateField, error, name])

  useEffect(
    () => () => {
      // Remove field on unmount
      updateField({ error: undefined })
    },
    [updateField]
  )

  function getError() {
    if (isRequired) {
      return typeof props.error === 'string' ? props.error : !currentValue
    }

    return props.error
  }

  function getSubmitState() {
    if (props.isSubmitted === undefined) {
      return !!fieldForm.isSubmitted || parentForm.isSubmitted
    }

    return props.isSubmitted
  }

  function updateFieldCallback(field) {
    const formField = {
      name,
      label: props.label || name,
      value: field.value || currentValue,
      error,
      ...field
    }

    formMethods.updateField({
      ...formField
    })
  }

  function handleOnBlur(e) {
    const { value } = e.target

    updateField({ isSubmitted: props.isSubmitted || true, error, value })

    if (onBlur) {
      onBlur(e)
    }
  }

  return (
    <Input
      {...props}
      name={name}
      error={isSubmitted && error}
      isRequired={isRequired}
      onBlur={handleOnBlur}
    />
  )
}

FormField.Select = props => (
  <FormField
    {...props}
    type="select"
  />
)

FormField.File = props => (
  <FormField
    {...props}
    type="file"
  />
)

FormField.ListSelect = props => (
  <FormField
    {...props}
    type="listSelect"
  />
)

FormField.Calendar = props => (
  <FormField
    {...props}
    type="calendar"
  />
)

FormField.TextArea = props => (
  <FormField
    {...props}
    type="textarea"
  />
)

FormField.Checkbox = props => (
  <FormField
    {...props}
    type="checkbox"
  />
)

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf([
    'text',
    'select',
    'calendar',
    'listSelect',
    'file',
    'textarea',
    'checkbox'
  ]),
  isSubmitted: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array
  ]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

export default FormField
