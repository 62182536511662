import jwt from 'jsonwebtoken'

export function isTokenExpired(token) {
  try {
    const { exp } = jwt.decode(token)
    return Date.now() >= exp * 1000
  } catch {
    return true
  }
}
