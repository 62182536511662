import { orderRoutes } from '../constants/navigation'
import * as actions from '../actions/forms'
import { getFormErrors } from '../utils/form'

const initialFormState = {
  activeFormId: undefined,
  fields: {},
  errors: {}
}

const initialForms = Object.keys(orderRoutes).reduce(
  (forms, pageId) => ({
    ...forms,
    [pageId]: { id: pageId, ...initialFormState }
  }),
  {}
)

export const initialState = {
  ...initialForms
}

function formsReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case actions.SET_ACTIVE_FORM: {
      return {
        ...state,
        activeFormId: payload.id
      }
    }

    case actions.UPDATE_FORM: {
      const { formName, field = {}, ...form } = payload.form
      const configForm = { ...state[formName] }
      const updatedForm = {
        ...configForm,
        ...form,
        fields: {
          ...configForm.fields,
          ...(!!field.name && {
            [field.name]: {
              ...configForm.fields[field.name],
              ...field
            }
          })
        }
      }
      const errors = getFormErrors(updatedForm.fields)

      return {
        ...state,
        [formName]: {
          ...updatedForm,
          isValid: !Object.keys(errors).length,
          errors
        }
      }
    }

    default: {
      return state
    }
  }
}

export default formsReducer
