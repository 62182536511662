export default [
  { value: 'SOA', display: 'Sales Order Agreement (SOA)' },
  { value: 'LOA', display: 'Letter of Agency (LOA)' },
  { value: 'RespO', display: 'RespOrg' },
  { value: 'IPJ', display: 'IP Justification' },
  { value: 'RCF', display: 'RCF' },
  { value: 'OC', display: 'Occupancy Certificate' },
  { value: 'PBC', display: 'Previous Bill Copy' },
  { value: 'TEF', display: 'Tax Exempt Form' },
  { value: 'CAdd', display: 'Construction Addendum' },
  { value: 'CAck', display: 'Construction Acknowledgment' },
  { value: 'ROE', display: 'Right of Entry' },
  { value: 'CJM/CFA', display: 'CJM/CFA' },
  { value: 'Lease', display: 'Lease' },
  { value: 'MOVES', display: 'Move/Upgrade of Service and Name Change Form' },
  { value: 'Other', display: 'Other' },
  { value: 'TPVE911', display: 'TPV E911' },
  { value: 'VDR', display: 'Voluntary Disconnect Request (VDR)' },
  { value: 'NameChange', display: 'Name Change Request Form' },
  {
    value: 'ETFWaiverApproval',
    display: 'ETF Waiver Leader Approval'
  },
  { value: 'GCApproval', display: 'Gift Card Approval' },
  { value: 'LetterOfAccess', display: 'Letter of Access' },
  {
    value: 'ResiToBusiness',
    display: 'Residential to Business Conversion'
  },
  { value: 'CertOfInsurance', display: 'Certificate of Insurance' },
  {
    value: 'ETFWaiverSupport',
    display: 'ETF Waiver Supporting Document'
  },
  { value: 'PastDue', display: 'Past Due Justification' }
]
