export const SET_SESSION_ID = 'SET_SESSION_ID'
export const GET_SESSION_DATA = 'GET_SESSION_DATA'
export const SET_SESSION_DATA = 'SET_SESSION_DATA'
export const INITIALIZE_WORKFLOW_STEP = 'INITIALIZE_WORKFLOW_STEP'
export const GET_CURRENT_WORKFLOW_STEP = 'GET_CURRENT_WORKFLOW_STEP'
export const GET_BUNDLE_BUILDER_DATA = 'GET_BUNDLE_BUILDER_DATA'
export const UPDATE_BUNDLE_BUILDER_DATA = 'UPDATE_BUNDLE_BUILDER_DATA'
export const HIDE_BUNDLE_BUILDER_MODAL = 'HIDE_BUNDLE_BUILDER_MODAL'
export const GET_AUTHENTICATION_TOKEN = 'GET_AUTHENTICATION_TOKEN'
export const JOIN_SALESFORCE_SESSION_URLS = 'JOIN_SALESFORCE_SESSION_URLS'
export const TOGGLE_THEME = 'TOGGLE_THEME'
export const SAVE_BAN_MODAL_INFO = 'SAVE_BAN_MODAL_INFO'

export function setSessionId(sessionId) {
  return {
    type: SET_SESSION_ID,
    payload: { sessionId }
  }
}

export function getSessionData(payload) {
  return {
    type: GET_SESSION_DATA,
    payload
  }
}

export function setSessionData(payload) {
  return {
    type: SET_SESSION_DATA,
    payload
  }
}

export function initializeWorkflowStep(payload) {
  return {
    type: INITIALIZE_WORKFLOW_STEP,
    payload
  }
}

export function getCurrentWorkflowStep() {
  return { type: GET_CURRENT_WORKFLOW_STEP }
}

export function getBundleBuilderData(showSpinner = true) {
  return {
    type: GET_BUNDLE_BUILDER_DATA,
    payload: showSpinner
  }
}

export function updateBundleBuilderData(reasons, isLimitBB) {
  return {
    type: UPDATE_BUNDLE_BUILDER_DATA,
    payload: { reasons, isLimitBB }
  }
}

export function hideBundleBuilderModal() {
  return {
    type: HIDE_BUNDLE_BUILDER_MODAL
  }
}

export function getAuthenticationToken(guid) {
  return {
    type: GET_AUTHENTICATION_TOKEN,
    payload: { guid }
  }
}

export function joinSalesforceSessionUrls(opportunityId) {
  return {
    type: JOIN_SALESFORCE_SESSION_URLS,
    payload: { opportunityId }
  }
}

export function toggleTheme() {
  return {
    type: TOGGLE_THEME
  }
}

export function saveBanModalInfo(payload) {
  return {
    type: SAVE_BAN_MODAL_INFO,
    payload
  }
}
