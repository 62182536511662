export const GET_CART_SUMMARY = 'GET_CART_SUMMARY'
export const SET_CART_SUMMARY = 'SET_CART_SUMMARY'
export const UPDATE_CART_SUMMARY = 'UPDATE_CART_SUMMARY'
export const GET_CART_DETAILS = 'GET_CART_DETAILS'
export const SET_CART_DETAILS = 'SET_CART_DETAILS'
export const UPDATE_CART_WITH_CONFIG = 'UPDATE_CART_WITH_CONFIG'
export const SAVE_CART = 'SAVE_CART'
export const SET_CART_QUOTE = 'SET_CART_QUOTE'
export const UPDATE_CART_DISCONNECT_REASON = 'UPDATE_CART_DISCONNECT_REASON'
export const UPDATE_CART_ADDITIONAL_SERVICES = 'UPDATE_CART_ADDITIONAL_SERVICES'
export const SAVE_CART_VIDEO_DWELL_TYPE = 'SAVE_CART_VIDEO_DWELL_TYPE'
export const CANCEL_VIDEO_DWELL_TYPE_ERROR = 'CANCEL_VIDEO_DWELL_TYPE_ERROR'
export const UPDATE_CART_LOB_MOVE_SELECTIONS = 'UPDATE_CART_LOB_MOVE_SELECTIONS'
export const UPDATE_CART_MOVE_PARTIAL_DISCONNECT =
  'UPDATE_CART_MOVE_PARTIAL_DISCONNECT'
export const SAVE_CART_FDX_CONFIG = 'SAVE_CART_FDX_CONFIG'
export const CANCEL_FDX_CONFIG_ERROR = 'CANCEL_FDX_CONFIG_ERROR'

// Cart Summary
export function getCartSummary(offerId = null) {
  return {
    type: GET_CART_SUMMARY,
    payload: { offerId }
  }
}

export function setCartSummary(cart) {
  return {
    type: SET_CART_SUMMARY,
    payload: { cart }
  }
}

export function updateCartSummary(lineItem) {
  return {
    type: UPDATE_CART_SUMMARY,
    payload: {
      lineItem
    }
  }
}

export function updateCartDisconnectReason(selectedDisconnectReason) {
  return {
    type: UPDATE_CART_DISCONNECT_REASON,
    payload: {
      selectedDisconnectReason
    }
  }
}

export function updateCartAdditionalServices(additionalServices) {
  return {
    type: UPDATE_CART_ADDITIONAL_SERVICES,
    payload: {
      additionalServices
    }
  }
}

export function updateCartCbm(isCbmAdded) {
  return {
    type: UPDATE_CART_ADDITIONAL_SERVICES,
    payload: {
      additionalServices: { isCbmAdded }
    }
  }
}

// Cart Details
export function getCartDetails() {
  return {
    type: GET_CART_DETAILS
  }
}

export function setCartDetails(cart) {
  return {
    type: SET_CART_DETAILS,
    payload: { cart }
  }
}

export function saveCart(payload) {
  return {
    type: SAVE_CART,
    payload
  }
}

export function setCartQuote(quote) {
  return {
    type: SET_CART_QUOTE,
    payload: { quote }
  }
}

export function updateCartLobMoveSelections(payload) {
  return {
    type: UPDATE_CART_LOB_MOVE_SELECTIONS,
    payload
  }
}

export function updateCartMovePartialDisconnect(payload) {
  return {
    type: UPDATE_CART_MOVE_PARTIAL_DISCONNECT,
    payload
  }
}
