export const GET_CALENDAR = 'GET_CALENDAR'
export const SET_CALENDAR = 'SET_CALENDAR'
export const UPDATE_SELECTED_CALENDAR_DATE = 'UPDATE_SELECTED_CALENDAR_DATE'
export const SET_SCHEDULING_NOTES = 'SET_SCHEDULING_NOTES'
export const SET_CALENDAR_DATE = 'SET_CALENDAR_DATE'
export const SET_SCHEDULING_ESCALATION = 'SET_SCHEDULING_ESCALATION'

export function getCalendar() {
  return {
    type: GET_CALENDAR
  }
}

export function setCalendar(calendar) {
  return {
    type: SET_CALENDAR,
    payload: { calendar }
  }
}

export function updateSelectedCalendarDate(calendarType, selectedDate) {
  return {
    type: UPDATE_SELECTED_CALENDAR_DATE,
    payload: { calendarType, selectedDate }
  }
}

export function setCalendarDate(calendarType, selectedDate) {
  return {
    type: SET_CALENDAR_DATE,
    payload: { calendarType, selectedDate }
  }
}

export function setSchedulingNotes(notes) {
  return {
    type: SET_SCHEDULING_NOTES,
    payload: { notes }
  }
}

export function setSchedulingEscalation(field, value) {
  return {
    type: SET_SCHEDULING_ESCALATION,
    payload: { field, value }
  }
}
