import { selectCustomerDetails } from './customer'
import { lobTitles } from '../constants/orderservices'
import { appTypes } from '../constants/session'

export const selectSelectedFilters = state => {
  const { filters: lobFilters } = state.offers
  return Object.values(lobFilters).reduce(
    (acc, { filterProperty, filter, filters, excludeWhenEmpty }) => {
      // get selected filters (including child filters)
      const selected = (filters || [filter])
        .flatMap(f => f.filters || f)
        .reduce(
          (all, f) =>
            f.isSelected
              ? [
                  ...all,
                  { [filterProperty]: f.id || f.id === 0 ? f.id : f.value }
                ]
              : all,
          []
        )
      const shouldInclude = selected.length || !excludeWhenEmpty

      return {
        ...acc,
        ...(shouldInclude && { [filterProperty]: selected })
      }
    },
    {}
  )
}

export const selectShouldLoadMoreOffers = ({ offers }) => {
  const { currentPage, totalPages } = offers.request.metadata

  return currentPage < totalPages
}

export const selectVisibleFilters = state => {
  const { filters: lobFilters } = state.offers
  const { site, account } = selectCustomerDetails(state)
  const { appType } = state.session
  const { isCBMStandaloneEligible } = state.order
  const {
    homeBasedBusiness,
    canAgentSellSmartOffice,
    serviceabilityMessage,
    nonServiceableLobs,
    smartOfficeLicenceMessage
  } = site
  const { searchTerm, contractTerm, ...filters } = lobFilters

  const isHomeBasedBusinessTv = lob =>
    homeBasedBusiness && lob === lobFilters.tv.id

  const isNotSmartOfficeLicensed = lob =>
    !canAgentSellSmartOffice && lob === lobFilters.smartoffice?.id

  const isNotCBMStandaloneEligible = lob => {
    if(lob === lobFilters.mobile?.id) {
      if (!isCBMStandaloneEligible) {
        return true
      }

      if (account.taxExempt) {
        return true
      }
    }

    return false
  }

  function getVisibilityMessage(lob) {
    const { hbbServiceability, nonServiceableLob } = serviceabilityMessage || {}

    if (isHomeBasedBusinessTv(lob)) {
      return {
        title: 'HBB',
        message: hbbServiceability?.peoMessage
      }
    }

    if (
      !homeBasedBusiness &&
      !canAgentSellSmartOffice &&
      nonServiceableLobs?.length === 0 &&
      smartOfficeLicenceMessage &&
      lob === lobFilters?.smartoffice?.id &&
      appType === appTypes.NC
    ) {
      return {
        message: smartOfficeLicenceMessage?.peoMessage
      }
    }

    if (!homeBasedBusiness && (nonServiceableLobs || []).length) {
      const hasLob = nonServiceableLobs.find(nLob => nLob === lobTitles[lob])

      if (!hasLob) {
        return null
      }

      return {
        title: `Not Serviceable for ${lobTitles[lob]}`,
        message: nonServiceableLob?.peoMessage.replace(
          '{LOB}',
          lobTitles[lob].replace('Business ', '')
        )
      }
    }

    return null
  }

  return Object.entries(filters).reduce(
    (all, [lob, filter]) => ({
      ...all,
      [lob]: {
        ...filter,
        isVisible: !(
          isHomeBasedBusinessTv(lob) || isNotSmartOfficeLicensed(lob) ||
          isNotCBMStandaloneEligible(lob)
        ),
        visibilityMessage: getVisibilityMessage(lob)
      }
    }),
    { searchTerm, contractTerm }
  )
}

export const selectHasExistingOfferPackage = existingServices => {
  return (
    !!existingServices?.offerPackage &&
    !!Object.keys(existingServices?.offerPackage).length
  )
}

export const selectOfferPackageItems = (existingServices = {}) => {
  const { cartItems, isPackageOffer } = existingServices

  if (!cartItems) {
    return []
  }

  return cartItems
    .reduce((packagedItems, { cartItemDetails = [], offer = {} }) => {
      const items = cartItemDetails.reduce((includedPackageItems, item) => {
        return item.isIncludedInPackage
          ? [...includedPackageItems, item]
          : includedPackageItems
      }, packagedItems)

      if (isPackageOffer) {
        return items
      }

      return offer.isIncludedInPackage ? [...items, offer] : items
    }, [])
    .sort((a, b) => {
      if (a.type === 'offer' && b.type !== 'offer') {
        return -1
      }
      if (b.type === 'offer') {
        return 1
      }
      return 0
    })
}
