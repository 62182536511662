import {
  selectConfigItemByType,
  selectLineItemByType,
  selectConfiguredVoiceLines,
  selectCrcVoiceLines,
  selectMoveOptionsByLOB
} from '../../selectors/configuration'
import { selectActiveForm } from '../../selectors/form'
import {
  getTotalQuantity,
  getConfigItemsArray,
  getHuntGroupLines,
  getHGIndex
} from '../configuration'
import {
  voiceItemIds,
  voiceLineItemTypes as lineTypes,
  REQUIRED_CRCP_ERROR,
  crcpStatus,
  CRCP_INELIGIBLE_ERROR,
  OTHER_PROVIDER,
  configTypes,
  selectedInternetSpeedTypes,
  maximumVoiceLinesForEPON
} from '../../constants/configuration'
import { hasSpecialChars } from './core'
import errors from '../../constants/errors/voice'
import { selectIsAdvancedMoveOrder } from '../../selectors/order'

// Grouped Validation Sections
export const sectionOne = [
  {
    values: {
      maximumLines: 24
    },
    validation: validateTotalVoiceLines,
    error: errors.validateTotalVoiceLinesError
  },
  {
    validation: validateVoiceMailCount,
    error: errors.validateVoiceMailCountError
  },
  {
    validation: validateVoicemailHuntGroup,
    error: errors.validateVoicemailHuntGroupError
  },
  {
    validation: validateHuntGroupQty,
    error: errors.validateHuntGroupQtyError
  },
  {
    validation: validateTotalVoiceLinesForEPON,
    error: errors.validateTotalVoiceLinesForEPONError
  }
]

export const sectionTwo = [
  {
    validation: validateSelectedVoiceMail,
    error: errors.validateVoiceMailError
  },
  {
    validation: validateRingToNumber,
    error: errors.validateRingToNumberError
  },
  {
    validation: validateVoiceLineDLN,
    error: errors.validateVoiceLineDLNError
  },
  {
    validation: validatePhoneNumbers,
    values: {
      PHONE_NUM_REGEX: /[0-9]{10}/
    },
    error: errors.validatePhoneNumbersError
  },
  {
    validation: validateHuntGroupSequence,
    error: errors.validateHuntGroupSequenceError
  },
  {
    validation: validateCallingArea,
    error: errors.validateCallingAreaError
  },
  {
    validation: validateManualCrcp,
    error: errors.validateManualCrcpError
  },
  {
    validation: validateRateCenter,
    error: errors.validateRateCenterError
  },
  {
    validation: validateCrcDetails
  },
  {
    validation: validateCurrentProviders,
    error: errors.validateCurrentProvidersError
  },
  {
    validation: validateE911NotificationEmail,
    error: errors.validateE911NotificationEmailError
  },
  {
    validation: validateVoiceLineMoveStatus,
    error: errors.validateVoiceLineMoveStatusError
  }
]

export const sectionThree = [
  {
    validation: validateProviderDetails
  },
  {
    validation: validateDirectoryListingInfo
  }
]

// Section Validation Methods
export function validateVoiceMailCount(state) {
  const voiceMail = selectConfigItemByType(lineTypes.voiceMail)(state)
  const voiceLines = selectLineItemByType(lineTypes.voiceLines)(state)
  const allLines = getConfigItemsArray(voiceLines)
  const totalLines = allLines.reduce((lines, line) => lines + line.quantity, 0)
  return !!totalLines && voiceMail.quantity > totalLines
}

export function validateTotalVoiceLines(state, validationData) {
  const voiceLines = selectLineItemByType(lineTypes.voiceLines)(state)
  const {
    [voiceItemIds.mobilityLine]: mobility,
    [voiceItemIds.basicLine]: basicLine,
    [voiceItemIds.fullFeaturedLine]: fullFeaturedLine
  } = voiceLines.configurationItems
  const { maximumLines } = validationData
  const totalLineQuantity = [mobility, basicLine, fullFeaturedLine].reduce(
    (total, line) => (line ? total + line.quantity : total),
    0
  )
  return totalLineQuantity > maximumLines
}

export function validateVoicemailHuntGroup(state) {
  const voiceMail = selectConfigItemByType(lineTypes.voiceMail)(state)
  const huntGroup = selectConfigItemByType(lineTypes.huntGroup)(state)
  const voiceLines = selectLineItemByType(lineTypes.voiceLines)(state)
  const allLines = getConfigItemsArray(voiceLines)
  const hasLine = allLines.some(line => !!line.quantity)

  return (
    !hasLine && !!(getTotalQuantity(voiceMail) || getTotalQuantity(huntGroup))
  )
}

export function validateHuntGroupQty(state) {
  const huntGroup = selectConfigItemByType(lineTypes.huntGroup)(state)
  const voiceLines = selectLineItemByType(lineTypes.voiceLines)(state)
  const totalLineQty = Object.values(voiceLines.configurationItems).reduce(
    (total, line) => total + line.quantity,
    0
  )
  if (huntGroup.quantity === 1) {
    return totalLineQty < 2
  }

  if (huntGroup.quantity >= 2) {
    return totalLineQty < 4
  }

  return false
}

export function validateSelectedVoiceMail(state) {
  const voiceMail = selectConfigItemByType(lineTypes.voiceMail)(state)
  const { voiceLines } = selectConfiguredVoiceLines(state)
  const totalSetVoiceMails = voiceLines.reduce(
    (total, line) => (line.isVoiceMailOnLine ? total + 1 : total),
    0
  )
  return totalSetVoiceMails !== voiceMail.quantity
}

export function validateRingToNumber(state) {
  const { tollFree } = selectConfiguredVoiceLines(state)
  return tollFree.some(({ ringToNumberLine }) => !ringToNumberLine.lineId)
}

export function validateVoiceLineDLN(state) {
  const { voiceLines } = selectConfiguredVoiceLines(state)
  const selectedDirListing = selectConfigItemByType(lineTypes.directoryListing)(
    state
  )
  const voiceDLNLine = voiceLines.find(({ isDLN }) => isDLN)

  return (
    voiceDLNLine.isCRC &&
    selectedDirListing.id === voiceItemIds.publishedDirListing
  )
}

export function validatePhoneNumbers(state, { PHONE_NUM_REGEX }) {
  const allLines = Object.values(selectConfiguredVoiceLines(state)).flat()

  return allLines.some(line => {
    const phoneNumber = line.phoneNumber || line.tollFreeNumber
    return (
      (line.isPorted || line.isComcastTN) && !PHONE_NUM_REGEX.test(phoneNumber)
    )
  })
}

export function validateHuntGroupSequence(state) {
  const isInvalidSequence = (lines, type = 'HG1') =>
    lines.some(({ [type]: hg }, i) => getHGIndex(hg) !== i + 1)

  const { voiceLines } = selectConfiguredVoiceLines(state)
  const { quantity: hgQty } = selectConfigItemByType(lineTypes.huntGroup)(state)
  const hg1 = getHuntGroupLines(voiceLines)
  const hg2 = getHuntGroupLines(voiceLines, 'HG2')
  const hg1SeqIsInvalid = hg1.length < 2 || isInvalidSequence(hg1)
  const hg2SeqIsInvalid = hg2.length < 2 || isInvalidSequence(hg2, 'HG2')

  if (!hgQty) {
    return false
  }

  if (hgQty < 2) {
    return hg1SeqIsInvalid
  }

  return hg1SeqIsInvalid || hg2SeqIsInvalid
}

export function validateCallingArea(state) {
  const { tollFree } = selectConfiguredVoiceLines(state)

  return tollFree.some(line => !line.callingAreaDestination.length)
}

export function validateProviderDetails(state) {
  const configForm = selectActiveForm(state)

  return configForm.errors.providerDetails
}

export function validateDirectoryListingInfo(state) {
  const configForm = selectActiveForm(state)

  return configForm.errors.directoryListing
}

export function validateManualCrcp(state) {
  const { voiceLines } = selectConfiguredVoiceLines(state)
  const manuallyEnteredLines = voiceLines.filter(
    line => line.isCRC && line.attributes.allowManualInput
  )
  return manuallyEnteredLines.some(
    line =>
      !line.attributes.rateCenter ||
      !line.attributes.nxx ||
      !line.attributes.state ||
      !line.attributes.npa
  )
}

export function validateRateCenter(state) {
  const { voiceLines } = selectConfiguredVoiceLines(state)
  const manuallyEnteredLines = voiceLines.filter(
    line => line.isCRC && line.attributes.allowManualInput
  )
  const linesWithInvalidRateCenters = manuallyEnteredLines.filter(
    line =>
      !line.attributes.rateCenter ||
      line.attributes.rateCenter.length > 10 ||
      hasSpecialChars(line.attributes.rateCenter)
  )

  return !!linesWithInvalidRateCenters.length
}

export function validateCrcDetails(state) {
  const crcLines = selectCrcVoiceLines(state)
  const invalidLines = crcLines.reduce((lines, line) => {
    const { state: lineState, npa, nxx, rateCenter } = line.attributes

    if (!(lineState && npa && rateCenter && nxx)) {
      return [
        ...lines,
        {
          ...line,
          attributes: {
            ...line.attributes,
            error: REQUIRED_CRCP_ERROR
          }
        }
      ]
    }

    return lines
  }, [])

  if (invalidLines.length) {
    return {
      message: REQUIRED_CRCP_ERROR,
      data: {
        invalidLines
      }
    }
  }

  return false
}

export function validateCurrentProviders(state) {
  const { voiceLines, tollFree } = selectConfiguredVoiceLines(state)
  const allLines = [...voiceLines, ...tollFree]

  return allLines.find(
    line =>
      line.isPorted &&
      (!line.currentProvider || line.currentProvider === OTHER_PROVIDER)
  )
}

export function validateE911NotificationEmail(state) {
  const configForm = selectActiveForm(state)
  return configForm.errors.e911EmailAddress
}

// Individual Validations
export function validateEligiblePortedLines(portedLines) {
  const errorMessage = portedLines
    .reduce((errorMsg, line) => {
      if (line.attributes.error === crcpStatus.CRCP_NOT_ELIGIBLE) {
        return [
          ...errorMsg,
          CRCP_INELIGIBLE_ERROR.replace('<PHONE_NUMBER>', line.phoneNumber)
        ]
      }

      return errorMsg
    }, [])
    .join('\n')

  return !!errorMessage.length && errorMessage
}

export function validateVoiceLineMoveStatus(state) {
  const isAdvancedMoveOrder = selectIsAdvancedMoveOrder(state)
  const moveStatuses = selectMoveOptionsByLOB(configTypes.voice)(state)
  if (!isAdvancedMoveOrder || moveStatuses.length === 0) {
    return false
  }

  const { voiceLines, tollFree } = selectConfiguredVoiceLines(state)
  const allLines = [...voiceLines, ...tollFree]

  const errorCount = allLines.reduce((acc, line) => {
    const isLineMoveSelected = line.movesStatusSelections?.some(
      status => status.isSelected
    )
    const selectedLine = line.movesStatusSelections.find(
      status => status.isSelected
    )
    if (selectedLine?.id === 'tempOverlapOfService') {
      return selectedLine?.disconnectDate ? acc : acc + 1
    }

    return isLineMoveSelected ? acc : acc + 1
  }, 0)

  return errorCount || false
}

export function validateTotalVoiceLinesForEPON(state) {
  const selectedSpeedType = state?.cart?.summary?.selectedSpeedType?.toUpperCase() || ''
  if (selectedSpeedType !== selectedInternetSpeedTypes.epon) {
    return false
  }
 
  const voiceLines = selectLineItemByType(lineTypes.voiceLines)(state)
  const { 
    [voiceItemIds.mobilityLine]: mobility,
    [voiceItemIds.basicLine]: basicLine
  } = voiceLines.configurationItems || {}
 
  const totalLineQuantity = (mobility?.quantity || 0) + 
    (basicLine?.quantity || 0)
 
  return totalLineQuantity > maximumVoiceLinesForEPON
}
