import { put, select } from 'redux-saga/effects'
import * as actions from '../../actions'
import { configFieldNames } from '../../constants/form'
import { selectActiveForm } from '../../selectors/form'

export function* validateConfigureFormErrors() {
  const form = yield select(selectActiveForm)

  if (!form.isValid) {
    const [error] = Object.values(form.errors)
    yield put(actions.setConfigError(configFieldNames.core, error))
    return error
  }

  return false
}

export function* validateConfiguration() {
  return [yield* validateConfigureFormErrors()]
}
