export const UPDATE_ROUTE = 'UPDATE_ROUTE'
export const SET_NAVIGATION = 'SET_NAVIGATION'
export const RESET_ORDER_NAV_ROUTES = 'RESET_ORDER_NAV_ROUTES'
export const NAV_TO_NEXT_ORDER_ROUTE = 'NAV_TO_NEXT_ORDER_ROUTE'
export const NAV_TO_ORDER_ROUTE = 'NAV_TO_ORDER_ROUTE'
export const NAV_TO_DASHBOARD_ROUTE = 'NAV_TO_DASHBOARD_ROUTE'
export const SAVE_AND_CONTINUE = 'SAVE_AND_CONTINUE'
export const NAV_TO_CONTINUE_ORDER_ROUTE = 'NAV_TO_CONTINUE_ORDER_ROUTE'
export const NAV_TO_CURRENT_STATUS_ROUTE = 'NAV_TO_CURRENT_STATUS_ROUTE'
export const LAUNCH_CBM = 'LAUNCH_CBM'
export const LAUNCH_QQ ='LAUNCH_QQ'

export function updateRoute(routeId, route) {
  return {
    type: UPDATE_ROUTE,
    payload: { routeId, route }
  }
}

export function setNavigation(payload) {
  return {
    type: SET_NAVIGATION,
    payload
  }
}

export function resetOrderNavigationRoutes(currentRouteId) {
  return {
    type: RESET_ORDER_NAV_ROUTES,
    payload: { currentRouteId }
  }
}

export function navigateToNextOrderRoute(currentRouteId, data) {
  return {
    type: NAV_TO_NEXT_ORDER_ROUTE,
    payload: { currentRouteId, data }
  }
}

export function navigateToOrderRoute(routeId, data) {
  return {
    type: NAV_TO_ORDER_ROUTE,
    payload: { routeId, data }
  }
}

export function navigateToDashboard() {
  return {
    type: NAV_TO_DASHBOARD_ROUTE
  }
}

export function navigateToContinueOrderRoute() {
  return {
    type: NAV_TO_CONTINUE_ORDER_ROUTE
  }
}

export function navigateToCurrentStatusRoute() {
  return {
    type: NAV_TO_CURRENT_STATUS_ROUTE
  }
}

export function launchCbm() {
  return {
    type: LAUNCH_CBM
  }
}

export function launchQq() {
  return {
    type: LAUNCH_QQ
  }
}
