export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_QUOTE = 'GET_ORDER_QUOTE'
export const SAVE_ORDER = 'SAVE_ORDER'
export const GET_ORDER_SERVICES = 'GET_ORDER_SERVICES'
export const SET_EXISTING_SERVICES = 'SET_EXISTING_SERVICES'
export const GET_ORDER_STATUS = 'GET_ORDER_STATUS'
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS'
export const GET_DOCUMENT_TYPES = 'GET_DOCUMENT_TYPES'
export const SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES'
export const VALIDATE_ORDER = 'VALIDATE_ORDER'
export const SUBMIT_ORDER = 'SUBMIT_ORDER'
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS'
export const SET_ORDER_IS_SUBMITTED = 'SET_ORDER_IS_SUBMITTED'
export const SYNC_ORDER_SUBMIT_ON_MESSAGE = 'SYNC_ORDER_SUBMIT_ON_MESSAGE'
export const VALIDATE_ORDER_INSTALL = 'VALIDATE_ORDER_INSTALL'
export const GET_ORDER_DASHBOARD = 'GET_ORDER_DASHBOARD'
export const GET_ORDER_DATA = 'GET_ORDER_DATA'
export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS'
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const GET_PROJECT_CODES = 'GET_PROJECT_CODES'
export const GET_ORDER_MOVE_DETAILS = 'GET_ORDER_MOVE_DETAILS'
export const SAVE_ORDER_MOVE_DETAILS = 'SAVE_ORDER_MOVE_DETAILS'
export const SET_ORDER_MOVE_COMCAST_TN = 'SET_ORDER_MOVE_COMCAST_TN'
export const GET_MOVE_EXISTING_SERVICES = 'GET_MOVE_EXISTING_SERVICES'
export const RESET_MOVE_BAN_CHECK = 'RESET_MOVE_BAN_CHECK'
export const SAVE_ORDER_BAN_MOVE_DETAILS = 'SAVE_ORDER_BAN_MOVE_DETAILS'
export const CANCEL_BAN_MOVE = 'CANCEL_BAN_MOVE'
export const TOGGLE_MOVE_MODAL = 'TOGGLE_MOVE_MODAL'

export const getOrder = payload => ({
  type: GET_ORDER,
  payload
})

export const saveOrder = payload => ({
  type: SAVE_ORDER,
  payload
})

export const validateOrder = () => ({
  type: VALIDATE_ORDER
})

export const submitOrder = (apiUrl, isFullAutomationOrder = false) => ({
  type: SUBMIT_ORDER,
  payload: { apiUrl, isFullAutomationOrder }
})

export const validateOrderInstall = apiUrl => ({
  type: VALIDATE_ORDER_INSTALL,
  payload: { apiUrl }
})

export const submitOrderSuccess = () => ({
  type: SUBMIT_ORDER_SUCCESS
})

export const setOrderIsSubmitted = isSubmitted => ({
  type: SET_ORDER_IS_SUBMITTED,
  payload: { isSubmitted }
})

export const getOrderServices = payload => ({
  type: GET_ORDER_SERVICES,
  payload
})

export const setExistingServices = existingServices => ({
  type: SET_EXISTING_SERVICES,
  payload: { existingServices }
})

export const getOrderStatus = () => ({
  type: GET_ORDER_STATUS
})

export const setOrderStatus = status => ({
  type: SET_ORDER_STATUS,
  payload: { status }
})

export const getDocumentTypes = () => ({
  type: GET_DOCUMENT_TYPES
})

export const setDocumentTypes = payload => ({
  type: SET_DOCUMENT_TYPES,
  payload
})

export const getOrderDashboard = () => ({
  type: GET_ORDER_DASHBOARD
})

export const getOrderDetails = () => ({
  type: GET_ORDER_DETAILS
})

export const setOrderDetails = payload => ({
  type: SET_ORDER_DETAILS,
  payload
})

export const updateOrder = payload => ({
  type: UPDATE_ORDER,
  payload
})

export const getProjectCodes = (searchTerm, searchType) => ({
  type: GET_PROJECT_CODES,
  payload: { searchTerm, searchType }
})

export const setProjectCodes = projectCodes => ({
  type: UPDATE_ORDER,
  payload: { projectCodes }
})

export function getOrderMoveDetails(payload) {
  return {
    type: GET_ORDER_MOVE_DETAILS,
    payload
  }
}

export function saveOrderMoveDetails(
  orderMoveDetails,
  redirectToConfig = false
) {
  return {
    type: SAVE_ORDER_MOVE_DETAILS,
    payload: { orderMoveDetails, redirectToConfig }
  }
}

export function setOrderMoveComcastTN() {
  return {
    type: SET_ORDER_MOVE_COMCAST_TN
  }
}

export function getMoveExistingServices(banNumber) {
  return {
    type: GET_MOVE_EXISTING_SERVICES,
    payload: { banNumber }
  }
}

export function resetMoveBanCheck() {
  return {
    type: RESET_MOVE_BAN_CHECK
  }
}

export function saveOrderBanMoveDetails(payload) {
  return {
    type: SAVE_ORDER_BAN_MOVE_DETAILS,
    payload
  }
}

export function cancelBanMove() {
  return {
    type: CANCEL_BAN_MOVE
  }
}

export function toggleMoveModal() {
  return {
    type: TOGGLE_MOVE_MODAL
  }
}
