import { isValidEmail } from './core'
import { selectCustomerDetails } from '../../selectors/customer'
import errors from '../../constants/errors/customer'

export default {
  validations: {
    customerEmail: [
      {
        validation: validateCustomerEmail,
        error: errors.customerEmailError
      }
    ]
  }
}

export function validateCustomerEmail(state) {
  const { contact } = selectCustomerDetails(state)
  const { email } = contact
  return !isValidEmail(email)
}
