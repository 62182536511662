import * as actions from '../../actions'
import { errors } from '../content'

export default {
  validateX1PrimaryOutletsError: {
    message: errors.x1RequiresHdTvEquipment,
    onOkAction: [
      actions.CONFIG_CONVERT_EQUIP_TO_X1,
      actions.CLEAR_CONFIG_ERROR_TV
    ],
    onCancelAction: actions.CLEAR_CONFIG_ERROR_TV
  },
  validateX1AdditionalOutletsError: {
    message: errors.x1RequiresHdTvEquipment,
    onOkAction: [
      actions.CONFIG_CONVERT_EQUIP_TO_X1,
      actions.CLEAR_CONFIG_ERROR_TV
    ],
    onCancelAction: actions.CLEAR_CONFIG_ERROR_TV
  },
  validateTvEquipment: {
    message: errors.x1IncompatibleOutlet,
    onOkAction: [actions.CONFIG_TV_EQUIPMENT, actions.CLEAR_CONFIG_ERROR_TV],
    onCancelAction: actions.CLEAR_CONFIG_ERROR_TV
  },
  validateAdditionalOutletsCountError: {
    message: errors.additionalOutletSum,
    onOkAction: actions.CLEAR_CONFIG_ERROR_TV
  },
  validateMDTAError: {
    message: errors.mdtaSelection,
    onOkAction: actions.CLEAR_CONFIG_ERROR_TV
  },
  validateIpnProgrammingError: {
    message: errors.ipnX1Programming,
    onOkAction: [actions.CONFIG_X1_UNSET_IPNS, actions.CLEAR_CONFIG_ERROR_TV],
    onCancelAction: actions.CLEAR_CONFIG_ERROR_TV
  },
  validateVideoPackgeAdded: {
    message: errors.videoPackageNotAdded,
    onOkAction: actions.CLEAR_CONFIG_ERROR_TV
  }
}
