import numeral from 'numeral'

export function formatNumber(val, decimals = 2) {
  if (!val && val !== 0) {
    return ''
  }

  return numeral(val).format(`0.${'0'.repeat(decimals)}`, Math.floor)
}

export function arrayToObject(dataArray = [], key = 'id') {
  // if the key is not found default to the id
  const retData = dataArray.reduce(
    (ret, data) => (data ? { ...ret, [data[key]]: data } : { ...ret }),
    {}
  )
  return retData
}

export function generateSequenceArray(length, prefix = '') {
  return Array(length)
    .fill()
    .map((item, index) => `${prefix}${index + 1}`)
}

export function sortBy(prop, isDesc = false, priorityItem = false) {
  const getValue = item => (prop ? item[prop] : item)

  return (...values) => {
    const [a, b] = isDesc ? values.slice().reverse() : values

    if (priorityItem) {
      if (a[priorityItem]) {
        return 1
      }
      if (b[priorityItem]) {
        return 1
      }
    }
    const aValue = getValue(a)
    const bValue = getValue(b)

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      const aLower = aValue.toLocaleLowerCase()
      const bLower = bValue.toLocaleLowerCase()

      if (aLower < bLower) return -1
      if (aLower > bLower) return 1
      return 0
    }

    return aValue - bValue
  }
}

// Returns the comparison of two object arrays on specified key
export function compareObjectArrays(
  arr1,
  arr2,
  key = 'key',
  operation = 'intersection'
) {
  if (operation === 'intersection') {
    // returns array containing common objects
    return arr1.reduce(
      (ret, opt1) => [...ret, ...arr2.filter(opt2 => opt1[key] === opt2[key])],
      []
    )
  }
  if (operation === 'leftDifference') {
    // returns array containing objects in arr1 that are not in arr2
    return arr1.filter(opt1 =>
      arr2.reduce((ret, opt2) => ret && opt1[key] !== opt2[key], true)
    )
  }
  if (operation === 'rightDifference') {
    // returns array containing objects in arr2 that are not in arr1
    return arr2.filter(opt2 =>
      arr1.reduce((ret, opt1) => ret && opt2[key] !== opt1[key], true)
    )
  }
  if (operation === 'symmetricDifference') {
    // returns array containing objects not found in BOTH arrays
    return arr1
      .filter(opt1 =>
        arr2.reduce((ret, opt2) => ret && opt1[key] !== opt2[key], true)
      )
      .concat(
        arr2.filter(opt2 =>
          arr1.reduce((ret, opt1) => ret && opt2[key] !== opt1[key], true)
        )
      )
  }
  return []
}

// Finds item and also filters it out of base array
export function findAndFilter(array = [], fn = () => false) {
  return array.reduce(
    (items, current) => {
      if (fn(current)) {
        return [current, items[1]]
      }

      return [items[0], [...items[1], current]]
    },
    [null, []]
  )
}
