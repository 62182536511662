export const TOGGLE_SETTINGS_LOADING = 'TOGGLE_SETTINGS_LOADING'
export const GET_ALL_FEATURES = 'GET_ALL_FEATURES'
export const SET_ALL_FEATURES = 'SET_ALL_FEATURES'
export const GET_FEATURE_DETAILS = 'GET_FEATURE_DETAILS'
export const SET_FEATURE_DETAILS = 'SET_FEATURE_DETAILS'
export const UPDATE_FEATURE = 'UPDATE_FEATURE'
export const UPDATE_FEATURE_TOGGLE = 'UPDATE_FEATURE_TOGGLE'
export const GET_FEATURE_OPTIONS = 'GET_FEATURE_OPTIONS'
export const SET_FEATURE_OPTIONS = 'SET_FEATURE_OPTIONS'
export const DISMISS_TOGGLE_MODAL = 'DISMISS_TOGGLE_MODAL'

export function toggleSettingsLoading(isLoading) {
  return {
    type: TOGGLE_SETTINGS_LOADING,
    payload: { isLoading }
  }
}

export function getAllFeatures() {
  return {
    type: GET_ALL_FEATURES
  }
}

export function setAllFeatures(features) {
  return {
    type: SET_ALL_FEATURES,
    payload: { features }
  }
}

export function getFeatureDetails(id) {
  return {
    type: GET_FEATURE_DETAILS,
    payload: { id }
  }
}

export function setFeatureDetails(feature) {
  return {
    type: SET_FEATURE_DETAILS,
    payload: { feature }
  }
}

export function updateFeature(feature) {
  return {
    type: UPDATE_FEATURE,
    payload: { feature }
  }
}

export function updateFeatureToggle(feature, toggle) {
  return {
    type: UPDATE_FEATURE_TOGGLE,
    payload: { feature, toggle }
  }
}

export function getFeatureOptions() {
  return {
    type: GET_FEATURE_OPTIONS
  }
}

export function setFeatureOptions(options) {
  return {
    type: SET_FEATURE_OPTIONS,
    payload: { options }
  }
}

export function dismissToggleModal(method) {
  return {
    type: DISMISS_TOGGLE_MODAL,
    payload: method
  }
}
