export const defaultError = {
  trackingId: null,
  TimeStamp: null,
  InfoType: 'EC-UI-ERROR',
  ErrorType: 'error',
  MessageType: 'UI',
  OpportunityId: null,
  ResponseTime: '0',
  IsError: true,
  ErrorCode: '200',
  UserName: '',
  RelatedXmlFile: 'DNA'
}
