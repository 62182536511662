export const documentTypes = {
  pdf: 'MANUAL_PDF',
  phone: 'SIMPLIFIED_CONTRACTING',
  send: 'E_SIGNATURE',
  signNow: 'SIGN_NOW',
  move: 'MOVES_PDF'
}

export const availableDocumentTypes = {
  [documentTypes.signNow]: {
    id: documentTypes.signNow,
    title: 'Sign Now',
    subTitle: null,
    isSelected: false,
    isActive: false,
    status: null,
    sortOrder: 0
  },
  [documentTypes.phone]: {
    id: documentTypes.phone,
    title: 'Verify By Phone',
    subTitle: 'Click for Simplified Contracting',
    isSelected: false,
    isActive: false,
    status: null,
    sortOrder: 1
  },
  [documentTypes.send]: {
    id: documentTypes.send,
    title: 'Send Proposal',
    subTitle: null,
    isSelected: false,
    isActive: false,
    status: null,
    sortOrder: 2
  },
  [documentTypes.pdf]: {
    id: documentTypes.pdf,
    title: 'PDF',
    subTitle: 'Click to Generate PDF',
    isSelected: false,
    isActive: false,
    status: null,
    sortOrder: 3
  },
  [documentTypes.move]: {
    id: documentTypes.move,
    title: 'Generate Move Form/Name Change Form',
    subTitle: 'Click to Generate PDF',
    isSelected: false,
    isActive: false,
    status: null,
    sortOrder: 4
  }
}

export const addressModalTitle = `Confirm the customer's Billing Address before creating their
contract. Any changes will update the Billing Address associated to
the Opportunity:`

export const documentStatus = {
  approved: 'Approved',
  pending: 'Pending',
  expired: 'Expired'
}

export const pendingDocument = {
  title: 'Pending Document',
  content:
    'Document is in pending status and cannot be viewed yet. Once customer has accepted documents, document may be viewed.'
}
export const E_SIGNATURE_DOC_METHOD = 'E-Signature'
