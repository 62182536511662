import axios from 'axios'
import {
  GET_CUST_DETAILS_URL,
  GET_CUST_DEBT_URL,
  UPDATE_CUSTOMER_DETAILS_URL,
  GET_BANNER_MESSAGES_URL,
  POST_PROCESS_BANNER_INTERACTION_URL
} from '../constants/urls'
import { createRedirectError, getApiErrorMessage } from '../utils/error'
import { stringifyQueryString } from '../utils/navigation'

export async function getCustomer({ fxbuyflowSessionId }) {
  try {
    const { data } = await axios.post(GET_CUST_DETAILS_URL, {
      fxbuyflowSessionId
    })
    return data
  } catch (error) {
    throw createRedirectError(error)
  }
}

export async function getCustomerDebt(sessionId) {
  const { data: res } = await axios.get(`${GET_CUST_DEBT_URL}/${sessionId}`)
  return res.data
}

export async function getBannerMessages() {
  try {
    const { data: res } = await axios.get(GET_BANNER_MESSAGES_URL)
    return res
  } catch {
    return null
  }
}

export async function processBannerInteraction(payload) {
  try {
    const { data: res } = await axios.post
    (POST_PROCESS_BANNER_INTERACTION_URL, payload)
    return res
  }
  catch {
    return null
  }
}

export async function updateCustomerInfo(
  payload,
  { opportunityId, sessionId, fxbuyflowSessionId }
) {
  const queryString = stringifyQueryString({
    opportunityId,
    sessionId,
    fxbuyflowSessionId
  })

  try {
    await axios.post(`${UPDATE_CUSTOMER_DETAILS_URL}?${queryString}`, payload)
    return true
  } catch (err) {
    const errorMsg = getApiErrorMessage(err)
    throw new Error(errorMsg?.description)
  }
}
