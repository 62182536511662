import axios from 'axios'
import { staticIpMove } from '../constants/configuration'
import { cbmStandaloneOfferId } from '../constants/offers'
import {
  GET_CART_CONFIG_URL,
  SAVE_CART_URL,
  SAVE_CONTINUE_CART_URL,
  SAVE_CONTINUE_CBM_CART_URL,
  CART_DETAILS_URL,
  SAVE_TO_SALESFORCE_ASYNC,
  GET_CHECK_PREWIRE_ASYNC_URL,
  SWITCH_VIDEO_OFFER_URL,
  POST_CART_UPSELL_CONFIG_URL,
  GET_CBM_CART_CONFIG_URL
} from '../constants/urls'
import { getApiErrorMessage } from '../utils/error'
import { sourceSystemTypes } from '../constants/session'

export async function getCartSummary({
  offerId,
  sessionId,
  opportunityId,
  fxbuyflowSessionId,
  cbmStandaloneOrderFlowEnabled
}) {
  const payload = {
    offerId,
    sessionId,
    opportunityId,
    fxbuyflowSessionId
  }

  let url = GET_CART_CONFIG_URL

  // If selected offer is CBM Standalone offer. Invoke new cbm config endpoint
  if (
    (!offerId && cbmStandaloneOrderFlowEnabled) ||
    offerId === cbmStandaloneOfferId
  ) {
    url = GET_CBM_CART_CONFIG_URL
  }

  const { data: res } = await axios.post(url, payload)
  return res.data
}

export async function getCartDetails(opportunityId) {
  const { data: res } = await axios.get(`${CART_DETAILS_URL}/${opportunityId}`)
  return res.data
}

export async function saveCartSummary(
  cart,
  isContinue,
  cbmStandaloneOrderFlowEnabled,
  sourceSystem
) {
  try {
    let confirmCartURL = SAVE_CONTINUE_CART_URL

    if (cbmStandaloneOrderFlowEnabled) {
      confirmCartURL = SAVE_CONTINUE_CBM_CART_URL
    }

    const url = isContinue ? confirmCartURL : SAVE_CART_URL
    const { data: res } = await axios.post(url, cart)

    if (
      sourceSystem === sourceSystemTypes.salesforce &&
      !cbmStandaloneOrderFlowEnabled
    ) {
      axios.get(`${SAVE_TO_SALESFORCE_ASYNC}`, {
        params: {
          sessionId: cart.sessionId
        }
      })
    }
    axios.get(`${GET_CHECK_PREWIRE_ASYNC_URL}/${cart.fxbuyflowSessionId}`)
    return res.data
  } catch (error) {
    if (error.response.data.messages[0].code === staticIpMove) {
      throw new Error(error.response.data.messages[0].code)
    } else {
      const errorMsg = getApiErrorMessage(error)
      throw new Error(errorMsg?.description)
    }
  }
}

export async function getVideoOfferView(body) {
  const { data: res } = await axios.post(SWITCH_VIDEO_OFFER_URL, body)
  return res.data
}

export async function upsellConfiguration(body) {
  const { data: res } = await axios.post(POST_CART_UPSELL_CONFIG_URL, body)
  return res.data
}
