import {
  selectLineItemByType,
  selectConfigItemByType
} from '../../selectors/configuration'
import { getConfigItem } from '../configuration'
import {
  internetLineItemTypes as lineTypes,
  internetConfigurationItems,
  staticIpJustificationCounts,
  defaultIPSelection,
  validSecurityEdgeEquipmentIds
} from '../../constants/configuration'
import { isValidEmail } from './core'
import { selectCustomerDetails } from '../../selectors/customer'
import errors from '../../constants/errors/internet'

export default {
  validations: {
    securityEdgeEmail: [
      {
        validation: validateSecurityEdgeEmail,
        error: errors.validateSecurityEdgeEmailError
      }
    ],
    securityEdgeOnSave: [
      {
        validation: validateSecurityEdgeOnSave,
        error: errors.validateSecurityEdgeErrorOnSave
      }
    ],
    ipJustifications: [
      {
        validation: checkStaticIpOptionsSet,
        error: errors.checkStaticIpOptionsSetError
      },
      {
        validation: checkOtherIpValue,
        error: errors.checkOtherIpValueError
      }
    ]
  }
}

export function validateCustomerOwnedEquipment(state, item) {
  const securityEdge = selectLineItemByType(lineTypes.securityEdge)(state)
  const securityEdgeConfigItem = getConfigItem(securityEdge) || {}

  return (
    securityEdgeConfigItem.isSelected &&
    item.id === internetConfigurationItems.customerOwnedEquipment
  )
}

export function checkStaticIpOptionsSet(state) {
  const staticIps = selectLineItemByType(lineTypes.staticIps)(state)
  const staticIpsConfigItem = getConfigItem(staticIps)
  const { id, attributes = {} } = staticIpsConfigItem
  const ipCount = staticIpJustificationCounts[id]
  const ips = attributes.staticIpJustifications || []
  const hasEmptyValue = ips.some(j => j === defaultIPSelection) || !ips.length

  return ipCount > 1 && hasEmptyValue
}

export function checkOtherIpValue(state) {
  const staticIps = selectLineItemByType(lineTypes.staticIps)(state)
  const staticIpsConfigItem = getConfigItem(staticIps)
  const { id, attributes = {} } = staticIpsConfigItem
  const ipCount = staticIpJustificationCounts[id]
  const ips = attributes.staticIpJustifications || []
  const noOtherValue = ips.some(j => {
    const hasOtherValue = j
      .trim()
      .toLowerCase()
      .includes('other')

    return hasOtherValue || !j.trim().length
  })

  return ipCount > 1 && noOtherValue
}

export function validateSecurityEdgeEmail(state) {
  const { contact } = selectCustomerDetails(state)
  const { hasOptedOutOfEmail, email } = contact

  const securityEdge = selectLineItemByType(lineTypes.securityEdge)(state)
  const securityEdgeConfigItem = getConfigItem(securityEdge)

  return (
    (hasOptedOutOfEmail || !isValidEmail(email)) &&
    securityEdgeConfigItem?.isSelected
  )
}

export function validateSecurityEdgeOnSave(state) {
  const securityEdgeConfigItem = selectConfigItemByType(lineTypes.securityEdge)(
    state
  )
  const equipment = selectConfigItemByType(lineTypes.internetEquipment)(state)

  return (
    !!equipment &&
    securityEdgeConfigItem?.isSelected &&
    !validSecurityEdgeEquipmentIds.includes(equipment?.id)
  )
}
