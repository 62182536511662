export const RPC_OPEN_CONNECTION = 'RPC_OPEN_CONNECTION'
export const RPC_CLOSE_CONNECTION = 'RPC_CLOSE_CONNECTION'
export const RPC_ON_SEND = 'RPC_ON_SEND'
export const RPC_ON_ERROR = 'RPC_ON_ERROR'
export const RPC_ON_CLOSE = 'RPC_ON_CLOSE'

export function openRPCConnection({
  connectionId,
  onSendAction = RPC_ON_SEND,
  onCloseAction = RPC_ON_CLOSE
}) {
  return {
    type: RPC_OPEN_CONNECTION,
    payload: { connectionId, onSendAction, onCloseAction }
  }
}

export function closeRPCConnection(connectionId) {
  return {
    type: RPC_CLOSE_CONNECTION,
    payload: { connectionId }
  }
}
