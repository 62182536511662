import * as actions from '../actions/settings'

export const initialState = {
  isLoading: true, // Might want to move to an app wide reducer
  features: {
    all: [],
    details: undefined,
    options: undefined
  }
}

function settingsReducer(state = initialState, { type, payload = {} } = {}) {
  switch (type) {
    case actions.SET_ALL_FEATURES: {
      const { features } = payload
      return {
        ...state,
        features: {
          ...state.features,
          all: features
        }
      }
    }

    case actions.SET_FEATURE_DETAILS: {
      const { feature } = payload
      return {
        ...state,
        features: {
          ...state.features,
          details: { ...feature }
        }
      }
    }

    case actions.GET_FEATURE_DETAILS: {
      return {
        ...state,
        features: {
          ...state.features,
          details: undefined
        }
      }
    }

    case actions.TOGGLE_SETTINGS_LOADING: {
      const { isLoading = !state.isLoading } = payload
      return { ...state, isLoading }
    }

    case actions.SET_FEATURE_OPTIONS: {
      const { options } = payload
      return {
        ...state,
        features: {
          ...state.features,
          options
        }
      }
    }
    case actions.DISMISS_TOGGLE_MODAL: {
      return {
        ...state,
        features: {
          ...state.features,
          details: {
            ...state.features.details,
            showToggleError: false,
            errorToggleId:
              payload === 'cancel'
                ? null
                : state.features.details.errorToggleId,
            validCombinations: payload === 'cancel'
          }
        }
      }
    }
    default: {
      return state
    }
  }
}

export default settingsReducer
