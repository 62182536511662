import styled, { css } from 'styled-components'

const inputStyles = {
  small: css`
    padding: 5px 8px;
    line-height: 1rem;

    & + div svg {
      font-size: 15px;
    }

    ${({ hasIcon }) => hasIcon && 'padding-right: 28px'}
  `
}

const errorStyles = css`
  border-color: ${({ theme }) => theme.colors?.danger};

  :focus {
    border-color: ${({ theme }) => theme.colors?.danger};
  }
`

export default styled.input.attrs(props => ({
  ...props,
  type: props.type || 'text',
  'aria-label': props.ariaLabel || props.name,
  'aria-describedby': props.ariaDescribedBy
}))`
  width: ${({ width = '100%' }) => width};
  border: 2px solid ${({ theme }) => theme.colors?.inputBorder};
  background: ${({ theme }) => theme.colors?.inputBackground};
  line-height: 1.5;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 0;
  outline: none;

  :focus {
    border-color: ${({ theme }) => theme.colors?.primary};
  }

  :disabled {
    border-color: ${({ theme }) => theme.colors?.inputBorder};
    background-color: ${({ theme }) => theme.colors?.disabled};
    opacity: 0.7;
    cursor: not-allowed;
    ${({ error }) => !!error && errorStyles};
  }

  :read-only {
    color: ${({ theme }) => theme.colors?.textSecondary};
  }

  ${({ hasIcon }) => hasIcon && 'padding-right: 30px'}
  ${({ error }) => !!error && errorStyles};
  ${({ size }) => inputStyles[size]};
`
