import { all, call, spawn, put } from 'redux-saga/effects'
import { logError } from '../services/logging'
import customerSagas from './customer'
import loggingSagas from './logging'
import navigationSaga from './navigation'
import offersSagas from './offers'
import settingsSaga from './settings'
import cartSaga from './cart'
import schedulingSaga from './scheduling'
import orderSaga from './order'
import configurationSaga from './configuration'
import validationSaga from './validations'
import sessionSaga from './session'
import formsSaga from './forms'
import documentsSaga from './documents'
import rpcSagas from './rpc'
import pollingSagas from './polling'
import * as actions from '../actions'
import { errors } from '../constants/content'
import { getEnv } from '../utils/env'

const sagas = [
  customerSagas,
  loggingSagas,
  navigationSaga,
  offersSagas,
  settingsSaga,
  cartSaga,
  schedulingSaga,
  orderSaga,
  configurationSaga,
  validationSaga,
  sessionSaga,
  formsSaga,
  documentsSaga,
  rpcSagas,
  pollingSagas
]

export default function* rootSaga() {
  const { HOST_ENV } = getEnv()
  let restartRootSaga = true

  // Run all sagas & restarts them if they fail
  yield all(
    sagas.map(saga =>
      spawn(function* allSagas() {
        while (restartRootSaga) {
          if (HOST_ENV === 'test') {
            restartRootSaga = false
          }

          try {
            yield call(saga)
            break
          } catch (error) {
            logError(error)
            // Show error and stop any hanging spinners
            yield put(actions.resetPageLoading())
            yield put(actions.setFormErrorAlert('sagaError', errors.fatalError))
          }
        }
      })
    )
  )
}
