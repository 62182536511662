import axios from 'axios'
import { GET_CALENDAR_URL } from '../constants/urls'
import { parseSchedulingCalendars } from '../utils/scheduling'

export async function getCalendar({ fxbuyflowSessionId }) {
  const { data: res } = await axios.get(
    `${GET_CALENDAR_URL}/${fxbuyflowSessionId}`
  )

  return parseSchedulingCalendars({
    ...res.data,
    messages: res.messages
  })
}
