import axios from 'axios'
import {
  defaultOrderRoute,
  defaultOrderStatusRoutes,
  orderRoutes
} from '../constants/navigation'
import {
  POST_CUSTOMER_ACTION_STATUS_URL,
  POST_WORKFLOW_STEP_URL,
  GET_BUNDLE_BUILDER_DATA_URL,
  POST_BUNDLE_BUILDER_DATA_URL,
  POST_AUTH_ORDER_URL,
  POST_WORKFLOW_STEP_RESUBMIT_URL,
  POST_SAVE_BAN_URL
} from '../constants/urls'
import { getLocalAuthenticationToken } from '../devTools'
import { getEnv } from '../utils/env'
import enabledFeatures from '../__tests__/__mocks__/data/enabledFeatures'
import { createRedirectError } from '../utils/error'

const { HOST_ENV } = getEnv()

export async function getEnabledFeatures() {
  /* Storing feature toggle API response in mock. We Have to revisit later 
     to decide if we even need this and refactor the feature toggle logic */

  // try {
  //   const { data } = await axios.post(POST_ENABLED_FEATURES_URL, {
  //     agentId,
  //     opportunityId
  //   })
  //   return data.map(d => d.featureID)
  // } catch (error) {
  //   return []
  // }

  return enabledFeatures.map(f => f.featureID)
}

export async function getWorkflowStep({ fxbuyflowSessionId }) {
  try {
    const { data } = await axios.post(POST_WORKFLOW_STEP_URL, {
      fxbuyflowSessionId
    })

    // TODO: Remove when workflow API is updated in all envs
    if (!data.defaultRoute) {
      return {
        ...data,
        ...defaultOrderStatusRoutes[data.workflowStepId]
      }
    }

    return {
      ...data,
      defaultRoute: orderRoutes[data.defaultRoute],
      continueOrderRoute: orderRoutes[data.continueOrderRoute]
    }
  } catch {
    return defaultOrderRoute
  }
}

export function updateSessionOrderStatus(opportunityId, actionStatusId) {
  return axios.post(POST_CUSTOMER_ACTION_STATUS_URL, {
    opportunityId,
    actionStatusId
  })
}

export async function getBundleBuilderData(fxbuyflowSessionId) {
  const { data } = await axios.get(
    `${GET_BUNDLE_BUILDER_DATA_URL}/${fxbuyflowSessionId}`
  )
  return data
}

export async function updateBundleBuilderData(data) {
  try {
    await axios.post(POST_BUNDLE_BUILDER_DATA_URL, data)
    return true
  } catch (err) {
    const { response } = err
    throw response.data.messageList[0]
  }
}

export async function getAuthenticationToken(correlationId) {
  if (HOST_ENV === 'local') {
    return getLocalAuthenticationToken()
  }

  try {
    const { data } = await axios.post(
      POST_AUTH_ORDER_URL,
      { correlationId },
      { disableAuthorization: true }
    )
    const { customerContext, fxbuyflowSessionId, token } = data

    return {
      ...customerContext,
      accountNumber: customerContext.banNumber,
      fxbuyflowSessionId,
      accessToken: token.accessToken
    }
  } catch (error) {
    throw createRedirectError(error)
  }
}

export async function getResubmitWorkflowStep({
  opportunityId,
  sessionId,
  fxbuyflowSessionId
}) {
  const { data } = await axios.post(POST_WORKFLOW_STEP_RESUBMIT_URL, {
    opportunityId,
    sessionId,
    fxbuyflowSessionId
  })

  return {
    ...data,
    defaultRoute: orderRoutes[data.defaultOrderRoute],
    continueOrderRoute: orderRoutes[data.continueOrderRoute]
  }
}

export async function saveBanModalInfo(payload) {
  try {
    await axios.post(POST_SAVE_BAN_URL, payload)
    return true
  } catch (error) {
    return false
  }
}
