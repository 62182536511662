import {
  optionsMap,
  TOGGLE_ERROR_DUPLICATE,
  TOGGLE_ERROR_EMPTY
} from '../../constants/settings'

export const isValidToggle = (combinations, toggle) => {
  const notNull = Object.keys(optionsMap).reduce(
    (empty, option) => toggle[option] !== null || empty === true,
    false
  )

  if (notNull) {
    const valid = combinations.reduce((allValid, combination) => {
      const singleCombination = Object.keys(optionsMap).reduce(
        (singleValid, key) =>
          !!(
            (combination[key] !== toggle[key] && singleValid === false) ||
            singleValid === true ||
            combination.toggleID === toggle.toggleID
          ),
        false
      )
      return singleCombination && allValid
    }, true)
    return {
      message: valid ? null : TOGGLE_ERROR_DUPLICATE,
      valid
    }
  }
  return { message: TOGGLE_ERROR_EMPTY, valid: false }
}
