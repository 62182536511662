import { takeLatest, put, select, call, all, take } from 'redux-saga/effects'
import * as actions from '../../actions'
import * as voiceValidations from '../../utils/validations/voice'
import { configFieldNames } from '../../constants/form'
import { getValidationError } from '../../utils/validations/core'
import {
  selectPortedVoiceLines,
  selectHasExistingServiceItem,
  selectComcastTnLines,
  selectIsDLnotListedorPublished,
  selectNonValidatedMoveVoiceLines,
  selectConfigItemByType,
  selectMoveOptionsByLOB,
  selectNonValidatedPortedMoveVoiceLines
} from '../../selectors/configuration'
import { getPortableRateCenterDetails } from '../../services/configuration'
import {
  REQUIRED_CRCP_ERROR,
  crcpStatus,
  voiceSections,
  configTypes,
  voiceLineItemTypes,
  voiceItemIds
} from '../../constants/configuration'
import {
  mapVoiceLineRateCenters,
  getPortedLinesSuccessMessage,
  mapComcastTnLineRateCenters
} from '../../utils/configuration'
import voiceErrors from '../../constants/errors/voice'
import { errors } from '../../constants/content'
import { selectIsAdvancedMoveOrder } from '../../selectors/order'

export function* validateSectionOneSaga(action) {
  const state = yield select()
  const { error } = getValidationError(voiceValidations.sectionOne, state)

  if (action) {
    yield put(actions.clearConfigError(configFieldNames.voice))
  }

  if (error) {
    yield put(actions.setConfigError(configFieldNames.voice, error))
    return error
  }

  yield put(actions.setupVoiceLines())

  if (action?.type === actions.VALIDATE_VOICE_SECTION_ONE) {
    yield put(actions.setCurrentVoiceConfigSection(voiceSections.configLines))
    yield put(actions.setLastUpdatedVoiceSection(voiceSections.configLines))
  } else {
    // Need to wait when validating all sections
    yield take(actions.SET_CONFIGURATION)
  }

  return false
}

export function* validateSectionTwoSaga(action) {
  const isSectionValidation =
    action?.type === actions.VALIDATE_VOICE_SECTION_TWO
  const state = yield select()
  const { error } = getValidationError(voiceValidations.sectionTwo, state)
  const portedLines = yield select(selectPortedVoiceLines)
  const nonValidatedPortedLines = portedLines.filter(
    line => !line.isValidated && !line.attributes.allowManualInput
  )
  const nonValidatedPortedLinesList = nonValidatedPortedLines.map(
    line => line.phoneNumber
  )

  const comcastTnLines = yield select(selectComcastTnLines)
  const nonValidatedComcastTnLines = comcastTnLines?.filter(
    line => !line.isValidated && !line.attributes.allowManualInput
  )
  const nonValidatedComcastTnLinesList = comcastTnLines?.map(
    line => line.phoneNumber
  )

  const { opportunityId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )

  if (action) {
    yield put(actions.clearConfigError(configFieldNames.voice))
  }

  if (error) {
    if (error.message === REQUIRED_CRCP_ERROR) {
      const { data, message } = error
      yield all(data.invalidLines.map(line => put(actions.setVoiceLine(line))))
      yield put(actions.setConfigError(configFieldNames.voice, message))
      return error
    }
    yield put(actions.setConfigError(configFieldNames.voice, error))
    return error
  }

  const nonValidatedMoveVoiceLines = selectNonValidatedMoveVoiceLines(state)
  const isAdvancedMoveOrder = selectIsAdvancedMoveOrder(state)
  const moveStatuses = selectMoveOptionsByLOB(configTypes.voice)(state)
  const nonValidatedPortedMVoiceLines = selectNonValidatedPortedMoveVoiceLines(
    state
  )
  if (
    isSectionValidation &&
    isAdvancedMoveOrder &&
    nonValidatedPortedMVoiceLines.length &&
    !!moveStatuses.length
  ) {
    const dLNLine = nonValidatedMoveVoiceLines.find(({ isDLN }) => isDLN)
    const directoryListing = selectConfigItemByType(
      voiceLineItemTypes.directoryListing
    )(state)

    if (dLNLine && directoryListing.id === voiceItemIds.publishedDirListing) {
      yield put(
        actions.setConfigError(
          configFieldNames.voice,
          voiceErrors.validateNotLocalPortDLNError
        )
      )
      return voiceErrors.validateNotLocalPortDLNError
    }
    // new saga to handle CRCP check for existing voice lines for move order
    yield put(actions.validateMoveVoiceLines())
    yield take(actions.SET_FORM_FIELD)
  }

  if (
    isSectionValidation &&
    (nonValidatedPortedLines.length || nonValidatedComcastTnLines.length) &&
    moveStatuses.length === 0
  ) {
    const dlnLine = nonValidatedPortedLines.find(line => line.isDLN)

    try {
      yield put(actions.togglePageLoading())
      const { rateCenterDetails } = yield call(getPortableRateCenterDetails, {
        opportunityId,
        phoneNumbers: [
          ...nonValidatedPortedLinesList,
          ...nonValidatedComcastTnLinesList
        ],
        fxbuyflowSessionId
      })

      if (dlnLine) {
        const dlnCrcpDetails = rateCenterDetails.find(
          rateCenter => rateCenter.phoneNumber === dlnLine.phoneNumber
        )
        const shouldByPassDlnCheck = selectIsDLnotListedorPublished(state)
        if (
          dlnCrcpDetails.crCpStatus === crcpStatus.CRCP_ELIGIBLE &&
          !shouldByPassDlnCheck
        ) {
          yield put(
            actions.setConfigError(
              configFieldNames.voice,
              voiceErrors.validateNotLocalPortDLNError
            )
          )

          return voiceErrors.validateNotLocalPortDLNError
        }
      }

      const updatedPortedLines = mapVoiceLineRateCenters(
        portedLines,
        rateCenterDetails
      )

      const updatedComcastTnLines = mapComcastTnLineRateCenters(
        comcastTnLines,
        rateCenterDetails
      )

      const updatedPortedAndComcastTnLines = [
        ...updatedPortedLines,
        ...updatedComcastTnLines
      ]

      const errorMessage = voiceValidations.validateEligiblePortedLines(
        updatedPortedAndComcastTnLines
      )

      yield all(
        updatedPortedAndComcastTnLines.map(line =>
          put(actions.setVoiceLine(line))
        )
      )
      if (errorMessage) {
        yield put(actions.setWarningAlert(configFieldNames.voice, errorMessage))
        return errorMessage
      }
      yield put(
        actions.setSuccessAlert(
          configFieldNames.voice,
          getPortedLinesSuccessMessage(updatedPortedAndComcastTnLines)
        )
      )
    } catch (err) {
      console.error('getPortableRateCenterDetails', err)
      yield all(
        portedLines.map(line =>
          put(
            actions.setVoiceLine({
              ...line,
              isValidated: false,
              isCRC: true,
              attributes: { ...line.attributes, allowManualInput: true }
            })
          )
        )
      )
      return yield put(
        actions.setConfigError(configFieldNames.voice, errors.crcpFailure)
      )
    } finally {
      yield put(actions.togglePageLoading(false))
    }
  }

  // Only setup lines on next button and not save validation
  if (isSectionValidation) {
    yield put(actions.setupVoiceAdditionalInfo())
    yield put(
      actions.setCurrentVoiceConfigSection(voiceSections.configListings)
    )
    yield put(actions.setLastUpdatedVoiceSection(voiceSections.configListings))
  }

  return false
}

export function* validateSectionThreeSaga(action) {
  const state = yield select()
  const { error } = getValidationError(voiceValidations.sectionThree, state)

  if (action) {
    yield put(actions.clearConfigError(configFieldNames.voice))
  }

  if (error) {
    yield put(actions.setConfigError(configFieldNames.voice, error))
    yield put(actions.validatedVoice(false))
    return error
  }

  if (action?.type === actions.VALIDATE_VOICE_SECTION_THREE) {
    yield put(
      actions.setCurrentVoiceConfigSection(voiceSections.configValidated)
    )
    yield put(actions.setLastUpdatedVoiceSection(0))
    yield put(actions.validatedVoice(true))
    yield put(
      actions.setConfigAccordionSections({ [configTypes.voice]: false })
    )
  }

  return false
}

export function* validateSaveVoice() {
  const voice = yield select(state => state.configuration.voice)

  yield put(actions.clearConfigError(configFieldNames.voice))

  if (voice.validated === false) {
    yield put(
      actions.setConfigError(
        configFieldNames.voice,
        voiceErrors.validateVoiceIsValidError
      )
    )
    return [voiceErrors.validateVoiceIsValidError]
  }

  return [
    yield* validateSectionOneSaga(),
    yield* validateSectionTwoSaga(),
    yield* validateSectionThreeSaga()
  ]
}

export function* validateVoiceQuantitySaga({ payload }) {
  const { item, lineItem } = payload
  const hasExistingItem = yield select(
    selectHasExistingServiceItem(configTypes.voice, item.id)
  )

  if (item.quantity < item.initialQuantity && hasExistingItem) {
    const error = {
      message: `The action you have taken will remove ${item.initialQuantity -
        item.quantity} existing ${
        item.name
      }. Are you sure you want to proceed with this change?`,
      onOkAction: [
        actions.UPDATE_VOICE_LINE_QTY,
        actions.CLEAR_FORM_FIELD_STATUSES
      ],
      onCancelAction: actions.CLEAR_FORM_FIELD_STATUSES,
      payload: { name: configFieldNames.voice, item, lineItem }
    }
    yield put(actions.setConfigError(configFieldNames.voice, error))
  } else {
    yield put(actions.updateVoiceLineQuantity({ item, lineItem }))
  }
}

function* rootSaga() {
  yield takeLatest(actions.VALIDATE_VOICE_SECTION_ONE, validateSectionOneSaga)
  yield takeLatest(actions.VALIDATE_VOICE_SECTION_TWO, validateSectionTwoSaga)
  yield takeLatest(
    actions.VALIDATE_VOICE_SECTION_THREE,
    validateSectionThreeSaga
  )
  yield takeLatest(actions.VALIDATE_VOICE_QUANTITY, validateVoiceQuantitySaga)
}
export default rootSaga
