import { takeLatest, put, select, call } from 'redux-saga/effects'
import {
  officeLineItemTypes as lineTypes,
  officeConfigurationItems as configItemIds
} from '../../constants/configuration'
import { getConfigItem, getNetNewCameraCount } from '../../utils/configuration'
import * as actions from '../../actions/configuration'
import { configurationLineItemSaga } from './configuration'
import { selectConfigItemByType } from '../../selectors/configuration'
import { getSmartOfficePermitBanner } from '../../services/configuration'

const lineItemSagas = {
  [lineTypes.indoorCameras]: updateOfficeCloudStorageSaga,
  [lineTypes.outdoorCameras]: updateOfficeCloudStorageSaga,
  [lineTypes.cloudStorageOptIn60]: updateOfficeCloudStorageOptSaga,
  [lineTypes.cameraInstall]: updateOfficeCloudStorageOptSaga,
  [lineTypes.installmentPlan]: updateOfficeInstallPlanSaga
}

export function* updateOfficeCloudStorageSaga({ payload }) {
  const { item } = payload
  const { smartoffice } = yield select(({ configuration }) => configuration)
  const oppositeCamera = yield select(
    selectConfigItemByType(
      item.id === configItemIds.indoorCameras
        ? lineTypes.outdoorCameras
        : lineTypes.indoorCameras
    )
  )
  const cloudStorage30 = smartoffice[lineTypes.cloudStorage30]
  const cloudStorage60 = smartoffice[lineTypes.cloudStorage60]
  const cloudStorageOpt = smartoffice[lineTypes.cloudStorageOptIn60]
  const cameraInstall = smartoffice[lineTypes.cameraInstall]
  const cloudStorage60ConfigItem = getConfigItem(cloudStorage60)
  const selectedCloudStorage = getConfigItem(cloudStorageOpt)
  const selectedCloudStorage30 = getConfigItem(cloudStorage30)
  const totalCameraQty = (oppositeCamera?.quantity || 0) + item.quantity
  const cameraInstallConfigItems = Object.values(
    cameraInstall.configurationItems
  )

  const newCameraCount = getNetNewCameraCount(item, oppositeCamera)
  const selectedCameraInstall =
    Object.values(cameraInstall.configurationItems).find(
      configItem =>
        Math.min(newCameraCount, 15) >= configItem.minLimit &&
        Math.min(newCameraCount, 15) <= configItem.maxLimit
    ) || cameraInstallConfigItems[0]

  if (selectedCameraInstall) {
    yield put(
      actions.updateConfigItem({
        lineItem: cameraInstall,
        item: {
          ...selectedCameraInstall,
          quantity: newCameraCount,
          cartQuantity: newCameraCount > 0 ? 1 : 0,
          isSelected: !!newCameraCount
        }
      })
    )
  }

  yield put(
    actions.updateConfigItem({
      lineItem: cloudStorage30,
      item: {
        ...selectedCloudStorage30,
        isSelected: true,
        quantity: totalCameraQty
      }
    })
  )

  yield put(
    actions.updateConfigItem({
      lineItem: cloudStorage60,
      item: {
        ...cloudStorage60ConfigItem,
        isSelected: selectedCloudStorage.isSelected,
        quantity: selectedCloudStorage.isSelected ? totalCameraQty : 0
      }
    })
  )

  yield put(actions.updateConfigItem({ lineItem: payload.lineItem, item }))
}

export function* updateOfficeCloudStorageOptSaga({ payload }) {
  const { item } = payload
  const { smartoffice } = yield select(({ configuration }) => configuration)
  const outdoorCameras = yield select(
    selectConfigItemByType(lineTypes.outdoorCameras)
  )
  const indoorCameras = yield select(
    selectConfigItemByType(lineTypes.indoorCameras)
  )
  const cloudStorage60 = smartoffice[lineTypes.cloudStorage60]
  const cloudStorage60ConfigItem = getConfigItem(cloudStorage60)
  const totalCameraQty =
    (outdoorCameras?.quantity || 0) + (indoorCameras?.quantity || 0)

  yield put(
    actions.updateConfigItem({
      lineItem: cloudStorage60,
      item: {
        ...cloudStorage60ConfigItem,
        isSelected: item.isSelected,
        quantity: item.isSelected ? totalCameraQty : 0
      }
    })
  )

  yield put(actions.updateConfigItem({ lineItem: payload.lineItem, item }))
}

export function* updateOfficeInstallPlanSaga({ payload }) {
  const { item } = payload

  yield put(
    actions.updateConfigOffer({
      smartOfficeInstallPlan: {
        isPayInMonthsSelected: item.isSelected
      }
    })
  )
}

export function* getSmartOfficePermitBannerSaga() {
  const { fxbuyflowSessionId } = yield select(({ session }) => session)
  const { smartOfficePermitBanner } = yield call(
    getSmartOfficePermitBanner,
    fxbuyflowSessionId
  )

  yield put(actions.updateConfigOffer({ smartOfficePermitBanner }))
}

function* rootSaga() {
  yield takeLatest(
    actions.CONFIG_ITEM_OFFICE,
    configurationLineItemSaga(lineItemSagas)
  )
  yield takeLatest(
    actions.GET_SMARTOFFICE_PERMIT_BANNER,
    getSmartOfficePermitBannerSaga
  )
}

export default rootSaga
