import { takeLatest, put, call, select, take } from 'redux-saga/effects'
import * as actions from '../actions/settings'
import * as services from '../services/settings'
import { isValidToggle } from '../utils/validations/featureToggle'
import { sortBy } from '../utils/core'

export function* getAllFeaturesSaga() {
  try {
    yield put(actions.toggleSettingsLoading(true))
    const features = yield call(services.getFeatures)
    yield put(actions.setAllFeatures(features))
    yield put(actions.toggleSettingsLoading(false))
  } catch {
    yield put(actions.toggleSettingsLoading(false))
  }
}

export function* getFeatureOptionsSaga() {
  try {
    yield put(actions.toggleSettingsLoading(true))
    const { profileId, ...options } = yield call(services.getFeatureOptions)

    yield put(
      actions.setFeatureOptions({
        ...options,
        profileId: [...profileId].sort(sortBy('name'))
      })
    )
    yield put(actions.toggleSettingsLoading(false))
  } catch {
    yield put(actions.toggleSettingsLoading(false))
  }
}

export function* getFeatureDetailsSaga({ payload }) {
  const { id } = payload
  const { options } = yield select(({ settings }) => settings.features)

  yield put(actions.toggleSettingsLoading(true))

  if (!options) {
    yield put(actions.getFeatureOptions())
    yield take(actions.SET_FEATURE_OPTIONS)
    yield put(actions.getFeatureDetails(id))
  } else {
    try {
      const { combinations, ...feature } = yield call(services.getFeatures, id)
      yield put(
        actions.setFeatureDetails({
          ...feature,
          combinations: combinations.sort((a, b) => b.isActive - a.isActive),
          validCombinations: true
        })
      )
      yield put(actions.toggleSettingsLoading(false))
    } catch {
      yield put(actions.toggleSettingsLoading(false))
    }
  }
}

export function* updateFeatureSaga({ payload }) {
  const { feature } = payload

  try {
    yield put(actions.toggleSettingsLoading(true))
    yield call(services.updateFeature, feature)
    yield put(actions.setFeatureDetails(feature))
    yield put(actions.toggleSettingsLoading(false))
  } catch {
    yield put(actions.toggleSettingsLoading(false))
  }
}

export function* updateFeatureToggleSaga({ payload }) {
  const { feature, toggle } = payload
  const { featureID, combinations } = feature
  const { toggleID } = toggle
  try {
    yield put(actions.toggleSettingsLoading(true))

    // edge case
    toggle.region = toggle.division === null ? null : toggle.region

    const validToggle = isValidToggle(combinations, toggle)

    if (validToggle.valid) {
      let data = yield call(services.updateFeatureToggle, featureID, toggle)

      let updatedToggles =
        toggleID && data
          ? combinations.map(t => (t.toggleID === toggleID ? data : t))
          : [...combinations, data]

      if (!data) {
        // something went wrong and we need to make another call to get all toggles
        data = yield call(services.getFeatures, featureID)

        const updatedToggle = toggleID
          ? data.combinations.filter(t => t.toggleID === toggleID)[0]
          : data.combinations.sort(
            (a, b) =>
              Date.parse(b.lastModifiedDate) - Date.parse(a.lastModifiedDate)
          )[0]
        updatedToggles = toggleID
          ? combinations.map(t => (t.toggleID === toggleID ? updatedToggle : t))
          : [...combinations, updatedToggle]
      }
      yield put(
        actions.setFeatureDetails({
          ...feature,
          combinations: updatedToggles,
          validCombinations: true
        })
      )
    } else {
      yield put(
        actions.setFeatureDetails({
          ...feature,
          showToggleError: true,
          validCombinations: false,
          errorToggleId: toggle.toggleID,
          toggleErrorMessage: validToggle.message
        })
      )
    }
    yield put(actions.toggleSettingsLoading(false))
  } catch {
    yield put(actions.toggleSettingsLoading(false))
  }
}

export function* rootSaga() {
  yield takeLatest(actions.GET_ALL_FEATURES, getAllFeaturesSaga)
  yield takeLatest(actions.GET_FEATURE_OPTIONS, getFeatureOptionsSaga)
  yield takeLatest(actions.GET_FEATURE_DETAILS, getFeatureDetailsSaga)
  yield takeLatest(actions.UPDATE_FEATURE, updateFeatureSaga)
  yield takeLatest(actions.UPDATE_FEATURE_TOGGLE, updateFeatureToggleSaga)
}

export default rootSaga
