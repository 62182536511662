import * as actions from '../actions/loading'

export const initialState = {
  app: false,
  appCount: 0,
  appMessage: undefined,
  page: false,
  pageCount: 0,
  pageMessage: undefined
}

function loadingReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    default: {
      return state
    }

    case actions.RESET_LOADING: {
      return initialState
    }

    case actions.SET_LOADING: {
      let appCounter = state.appCount
      let pageCounter = state.pageCount
      let pageMsg = state.pageMessage
      let appMsg = state.appMessage

      if (payload.app !== undefined) {
        appCounter = Math.max(
          payload?.app ? (appCounter += 1) : (appCounter -= 1),
          0
        )
        appMsg = typeof payload.app === 'string' ? payload.app : appMsg
      }

      if (payload.page !== undefined) {
        pageCounter = Math.max(
          payload?.page ? (pageCounter += 1) : (pageCounter -= 1),
          0
        )
        pageMsg = typeof payload.page === 'string' ? payload.page : pageMsg
      }

      const updatedState = {
        app: appCounter > 0,
        appCount: appCounter,
        appMessage: appCounter > 0 ? appMsg : undefined,
        page: pageCounter > 0,
        pageCount: pageCounter,
        pageMessage: pageCounter > 0 ? pageMsg : undefined
      }

      return {
        ...updatedState
      }
    }

    case actions.UPDATE_LOADING_MESSAGE: {
      let appMsg = state.appMessage
      let pageMsg = state.pageMessage

      if (payload.type === 'app') {
        appMsg = payload.message
      }

      if (payload.type === 'page') {
        pageMsg = payload.message
      }

      return {
        ...state,
        appMessage: state.app ? appMsg : undefined,
        pageMessage: state.page ? pageMsg : undefined
      }
    }
  }
}

export default loadingReducer
