import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import history from '../history'
import navigation from './navigation'
import customer from './customer'
import forms from './forms'
import offers from './offers'
import settings from './settings'
import session from './session'
import cart from './cart'
import documents from './documents'
import scheduling from './scheduling'
import order from './order'
import configuration from './configuration'
import loading from './loading'
import layout from './layout'
import polling from './polling'

export default combineReducers({
  router: connectRouter(history),
  customer,
  forms,
  offers,
  navigation,
  settings,
  session,
  cart,
  documents,
  scheduling,
  order,
  configuration,
  loading,
  layout,
  polling
})
