export const cartTypes = {
  summary: 'summary',
  details: 'details'
}

export const cartLineTypes = {
  offer: {
    key: 'offer',
    title: null
  },
  internet: {
    key: 'internet',
    title: 'Internet'
  },
  voice: {
    key: 'voice',
    title: 'Voice'
  },
  tv: {
    key: 'tv',
    title: 'Video'
  },
  office: {
    key: 'smartoffice',
    title: 'SmartOffice'
  },
  discounts: {
    key: 'discounts',
    title: 'Discounts'
  },
  additionalOptions: {
    key: 'additionalOptions',
    title: 'Additional Options'
  },
  install: {
    key: 'install',
    title: 'install'
  }
}
