import axios from 'axios'
import { POST_LOGGING_URL } from '../constants/urls'
import { defaultError } from '../constants/logging'
import { getEnv } from '../utils/env'

const { HOST_ENV } = getEnv()

export function logError(error, opportunityId, offerId) {
  let message = {
    ...defaultError,
    timestamp: new Date(),
    OpportunityId: opportunityId,
    OfferId: offerId
  }

  if (error?.url) {
    message = {
      ...message,
      ApiName: error.url,
      ErrorCode: error.status,
      ErrorMessage: JSON.stringify(error)
    }
  } else {
    message = {
      ...message,
      ErrorMessage: error
    }
  }

  // TODO: Remove when API is ready
  if (HOST_ENV === 'test') {
    return axios.post(POST_LOGGING_URL, message)
  }

  return console.error(error)
}
