import * as actions from '../actions/documents'
import { availableDocumentTypes } from '../constants/documents'

export const initialState = {
  types: availableDocumentTypes,
  uploaded: null,
  proposalPreview: '',
  generatingPdf: false,
  isUploading: false,
  showUploadModal: false,
  missingDocuments: null,
  signNowUrl: null
}

function documentReducer(state = initialState, { type, payload = {} } = {}) {
  switch (type) {
    case actions.SELECT_DOC_TYPE: {
      const { docType } = payload

      return {
        ...state,
        types: Object.entries(state.types).reduce(
          (types, [id, doc]) => ({
            ...types,
            [id]: id === docType.id ? docType : { ...doc, isSelected: false }
          }),
          {}
        )
      }
    }

    case actions.REST_DOC_TYPES: {
      return {
        ...state,
        types: {
          ...initialState.types
        }
      }
    }
    case actions.SET_AVAILABLE_DOCUMENT: {
      const { docType, isActive = false } = payload

      return {
        ...state,
        types: {
          ...state.types,
          [docType]: {
            ...state.types[docType],
            isActive,
            isSelected: false,
            status: null
          }
        }
      }
    }
    case actions.SET_PDF_DATA: {
      return {
        ...state,
        pdfData: payload
      }
    }
    case actions.SET_PREVIEW_PROPOSAL: {
      return {
        ...state,
        proposalPreview: payload
      }
    }
    case actions.SET_PDF_GUID: {
      return {
        ...state,
        pdfGuid: payload
      }
    }
    case actions.SAVE_DOCUMENTS: {
      return {
        ...state,
        isUploading: true
      }
    }
    case actions.UPDATE_DOCUMENTS: {
      return {
        ...state,
        isUploading: false,
        showUploadModal: false,
        uploaded: payload
      }
    }
    case actions.TOGGLE_UPLOAD_MODAL: {
      return {
        ...state,
        showUploadModal: !state.showUploadModal
      }
    }
    case actions.TOGGLE_DOCUMENT_IS_UPLOADING: {
      return {
        ...state,
        isUploading: !state.isUploading
      }
    }
    case actions.SET_DOCUMENT_STATUS: {
      const { docType, status } = payload

      return {
        ...state,
        types: {
          ...state.types,
          [docType]: {
            ...state.types[docType],
            status
          }
        }
      }
    }
    case actions.SET_MISSING_DOCUMENTS: {
      return {
        ...state,
        missingDocuments: payload
      }
    }
    case actions.SET_SIGN_NOW_URL: {
      return {
        ...state,
        signNowUrl: payload.signNowUrl
      }
    }
    case actions.SET_MOVE_FORM_PDF_GUID: {
      return {
        ...state,
        moveFormPdfGuid: payload
      }
    }
    case actions.SET_MOVE_FORM_PDF_DATA: {
      return {
        ...state,
        moveFormPdfData: payload
      }
    }
    default: {
      return state
    }
  }
}

export default documentReducer
