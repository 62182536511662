import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { FaTimesCircle } from 'react-icons/fa'
import Input from './Input'
import Select from './Select'
import ListSelect from './ListSelect'
import Calendar from '../core/Calendar'
import UploadButton from '../core/UploadButton'
import TextArea from './TextArea'
import Checkbox from './Checkbox'
import Button from './Button'
import { visibleFocusStyles } from '../../theme'

const inputTypes = {
  select: Select,
  listSelect: ListSelect,
  calendar: Calendar,
  file: UploadButton,
  textarea: TextArea,
  checkbox: Checkbox
}

const Field = ({
  label,
  error,
  className,
  type,
  showClear,
  errorStyles,
  isRequired,
  ...props
}) => {
  const inputRef = useRef(null)
  const InputElement = inputTypes[type] || Input
  const refProp = type === 'text' && {
    ref: inputRef
  }

  function handleClear() {
    // Need to use ref to clear for defaultValue
    inputRef.current.value = ''
    props.onChange({ target: { value: '' } })
    inputRef.current.focus()
  }

  return (
    <StyledContainer className={className}>
      {label && <StyledLabel>{label}</StyledLabel>}
      {isRequired && <StyledLabel isRequired>*Required Field</StyledLabel>}
      <StyledInputContainer>
        <InputElement
          {...refProp}
          error={!!error}
          hasIcon={showClear}
          type={type}
          {...props}
        />
        {showClear && (
          <StyledButton
            type="accessible"
            onClick={handleClear}
          >
            <StyledClearIcon alt="clear field" />
            <span className="sr-only">Clear Field</span>
          </StyledButton>
        )}
      </StyledInputContainer>
      {error && typeof error !== 'boolean' && (
        <StyledError style={{ ...errorStyles }}>{error}</StyledError>
      )}
    </StyledContainer>
  )
}

Field.Select = props => (
  <Field
    {...props}
    type="select"
  />
)

Field.ListSelect = props => (
  <Field
    {...props}
    type="listSelect"
  />
)

Field.Calendar = props => (
  <Field
    {...props}
    type="calendar"
  />
)

Field.File = props => (
  <Field
    {...props}
    type="file"
  />
)

Field.TextArea = props => (
  <Field
    {...props}
    type="textarea"
  />
)

Field.Checkbox = props => (
  <Field
    {...props}
    type="checkbox"
  />
)

Field.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'select',
    'listSelect',
    'calendar',
    'file',
    'textarea',
    'checkbox'
  ]),
  label: PropTypes.node,
  showClear: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.node
  ]),
  size: PropTypes.oneOf(['default', 'xs', 'small', 'large']),
  className: PropTypes.string,
  errorStyles: PropTypes.object,
  isRequired: PropTypes.bool
}

Field.defaultProps = {
  type: 'text',
  size: 'default',
  showClear: false,
  isRequired: false,
  errorStyles: {}
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLabel = styled.span`
  margin-bottom: 2px;
  font-weight: 800;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors?.text};
  ${({ isRequired, theme }) =>
    isRequired &&
    css`
      color: ${theme.colors?.danger};
      font-weight: 500;
      font-size: 0.7rem;
    `};
`

const StyledInputContainer = styled.div`
  position: relative;
  height: 100%;
`

const StyledButton = styled(Button)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 7px;
  top: 0;
  bottom: 0;
  ${visibleFocusStyles.svg}
`

const StyledClearIcon = styled(FaTimesCircle)`
  fill: ${({ theme }) => theme.colors?.inputIcon};
  cursor: pointer;
`

const StyledError = styled.span`
  margin-top: 3px;
  color: ${({ theme }) => theme.colors?.danger};
  font-size: 0.8rem;
  font-weight: 500;
`

export default Field
