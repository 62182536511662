import React from 'react'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'

const Calendar = ({ customDateButton, ...props }) => (
  <>
    <DatePicker {...props} /> {customDateButton && customDateButton()}
  </>
)
Calendar.propTypes = {
  customDateButton: PropTypes.func
}
export default Calendar
