import {
  getConfigItem,
  getConfiguredVoiceLines,
  fillVoiceLines,
  setLineDefaults,
  getTotalQuantity,
  getConfigItemsArray,
  mapNativeIndexes,
  getHuntGroupLines,
  getVoiceLineProviders,
  getLobSortWeight,
  getAdditionalProgramming,
  getFinalMappedVoiceLines,
  getFinalMappedTollFreeLines
} from '../utils/configuration'
import {
  lineItemTypes,
  voiceItemIds,
  voiceLineItemTypes,
  voiceLineTypes,
  voiceLineTemplates,
  voiceLineGroupTypes,
  configTypes,
  internetConfigurationItems,
  videoConfigurationItems,
  byPassDlnValidationIds,
  tollFreeMoveLineTemplate
} from '../constants/configuration'
import { documentStatus, documentTypes } from '../constants/documents'
import { workflowStepIds } from '../constants/session'
import { isLineOfBusinessServiceable, selectCustomerDetails } from './customer'
import { selectProductCompatibility } from './cart'
import { selectIsAdvancedMoveOrder } from './order'

export function selectLineItemByType(type, lineOfBusiness) {
  return ({ configuration = {} }) => {
    if (lineOfBusiness) {
      return Object.values(configuration[lineOfBusiness]).find(
        conf => conf[type]
      )
    }
    const lob = Object.values(configuration).find(conf => conf && conf[type])
    return lob ? lob[type] : null
  }
}

export function selectConfigItemByType(type) {
  return state => {
    const lineItem = selectLineItemByType(type)(state)
    return getConfigItem(lineItem)
  }
}

// VOICE
export function selectInitialConfiguredVoiceLines(state) {
  const configuredLinesDefaults = {
    lineOfBusiness: configTypes.voice,
    type: lineItemTypes.configuredItems,
    configurationItems: []
  }

  const voiceLines = selectVoiceLines(state)
  const tollFreeLines = selectTollFreeLines(voiceLines.configurationItems)(
    state
  )

  return {
    [voiceLineGroupTypes.voiceLines]: {
      voiceLineGroup: voiceLineGroupTypes.voiceLines,
      ...configuredLinesDefaults,
      ...voiceLines
    },
    [voiceLineGroupTypes.tollFree]: {
      voiceLineGroup: voiceLineGroupTypes.tollFree,
      ...configuredLinesDefaults,
      ...tollFreeLines
    }
  }
}

export function selectVoiceAdditionalInfo(state) {
  const { site, account } = selectCustomerDetails(state)
  const configuredVoiceLines = selectConfiguredVoiceLines(state)
  const { primaryLine, providerDetails } = selectVoiceConfiguredItems(state)

  const allVoiceLines = Object.values(configuredVoiceLines).flat()
  const providers = getVoiceLineProviders(
    allVoiceLines,
    providerDetails.providers,
    site.siteAddress
  )
  const directoryListing = primaryLine.addOnCartItems.find(
    cartItem => cartItem.addOnType === voiceLineItemTypes.directoryListing
  )

  return {
    primaryLine: {
      ...primaryLine,
      addOnCartItems: [
        ...primaryLine.addOnCartItems,
        {
          id: voiceItemIds.publishedDirListing,
          configurationItem: {},
          ...directoryListing
        }
      ]
    },
    providerDetails: {
      loaName: providerDetails.loaName || account.name,
      providers
    }
  }
}

export function selectVoiceConfiguredItems({ configuration }) {
  return configuration.voice[lineItemTypes.configuredItems]
}

export function selectConfiguredVoiceLines(state) {
  function mapIsExistingNumber(lines, currentNumbers) {
    if (currentNumbers) {
      return lines.map(line => ({
        ...line,
        isExistingNumber: currentNumbers.includes(
          line.phoneNumber || line.tollFreeNumber
        )
      }))
    }

    return lines
  }

  const { configuredLines, includedNumbers } = selectVoiceConfiguredItems(state)

  const {
    [voiceLineGroupTypes.voiceLines]: voiceLines,
    [voiceLineGroupTypes.tollFree]: tollFree
  } = configuredLines || {}

  return {
    [voiceLineGroupTypes.voiceLines]: mapIsExistingNumber(
      voiceLines?.configurationItems || [],
      includedNumbers?.ims
    ),
    [voiceLineGroupTypes.tollFree]: mapIsExistingNumber(
      tollFree?.configurationItems || [],
      includedNumbers?.tollFree
    )
  }
}

export function selectIncludedVoiceLines(state) {
  const configuredItems = selectVoiceConfiguredItems(state)
  return getConfiguredVoiceLines(configuredItems)
}

export function selectGroupedConfiguredVoiceLines(state) {
  const configuredItems = selectVoiceConfiguredItems(state)
  let allLines = []

  if (configuredItems.configuredLines) {
    allLines = Object.values(configuredItems.configuredLines).flatMap(
      lineItem => lineItem?.configurationItems || []
    )
  } else {
    const configuredVoiceLines = getConfiguredVoiceLines(configuredItems)
    allLines = Object.values(configuredVoiceLines).flat()
  }

  return Object.values(voiceLineTypes).reduce(
    (groupedLines, lineType) => ({
      ...groupedLines,
      [lineType]: allLines.filter(line => line.lineType === lineType)
    }),
    {}
  )
}

export function selectVoiceLines(state) {
  const { voice } = state.configuration
  const {
    [voiceLineItemTypes.huntGroup]: huntGroup,
    [voiceLineItemTypes.voiceMail]: voiceMail,
    [voiceLineItemTypes.voiceLines]: additionalVoiceLinesItem
  } = voice
  const {
    [voiceItemIds.mobilityLine]: mobilityConfigItem,
    [voiceItemIds.basicLine]: basicConfigItem,
    [voiceItemIds.fullFeaturedLine]: fullFeatConfigItem
  } = additionalVoiceLinesItem.configurationItems
  const { lineTemplate } = additionalVoiceLinesItem

  const selectedHuntGroup = getConfigItem(huntGroup)
  const selectedVoiceMail = getConfigItem(voiceMail)
  const hgQty = getTotalQuantity(selectedHuntGroup)
  const {
    [voiceLineTypes.mobility]: mobilityLines,
    [voiceLineTypes.basicLine]: basicLines,
    [voiceLineTypes.fullFeatured]: fullFeaturedLines
  } = selectGroupedConfiguredVoiceLines(state)

  // Fill each line with current or create new with template
  const filledMobilityLines = fillVoiceLines({
    configItem: mobilityConfigItem,
    lines: mobilityLines,
    count: mobilityConfigItem?.quantity,
    template: { ...lineTemplate, ...voiceLineTemplates.mobility }
  })
  const filledBasicLines = fillVoiceLines({
    configItem: basicConfigItem,
    lines: basicLines,
    count: basicConfigItem?.quantity,
    template: {
      ...lineTemplate,
      ...voiceLineTemplates.basicLine
    }
  })
  const filledFullFeaturedLines = fillVoiceLines({
    configItem: fullFeatConfigItem,
    lines: fullFeaturedLines,
    count: fullFeatConfigItem?.quantity,
    template: {
      ...lineTemplate,
      ...voiceLineTemplates.fullFeatured
    }
  })
  const mappedLines = mapNativeIndexes([
    ...filledMobilityLines,
    ...filledFullFeaturedLines,
    ...filledBasicLines
  ])

  const isAdvancedMoveOrder = selectIsAdvancedMoveOrder(state)
  const finalMappedLines = getFinalMappedVoiceLines(
    mappedLines,
    isAdvancedMoveOrder
  )

  return {
    hgQty,
    voiceMailQty: selectedVoiceMail.quantity,
    configurationItems: setLineDefaults(finalMappedLines, { hgQty })
  }
}

export function selectTollFreeLines(currentVoiceLines = []) {
  return state => {
    const tollFreeLineItem = selectLineItemByType(voiceLineItemTypes.tollFree)(
      state
    )
    const tollFreeConfigItem = getConfigItem(tollFreeLineItem)
    const {
      [voiceLineTypes.tollFreeLine]: tollFreeLines
    } = selectGroupedConfiguredVoiceLines(state)
    const { addonTemplate: template } = tollFreeConfigItem
    const isAdvancedMoveOrder = selectIsAdvancedMoveOrder(state)

    // Fill each line with current or create new with template
    const tollFreeVoiceLines = fillVoiceLines({
      configItem: tollFreeConfigItem,
      lines: tollFreeLines,
      count: getTotalQuantity(tollFreeConfigItem),
      template: {
        ...template,
        ...voiceLineTemplates.tollFreeLine,
        ...(isAdvancedMoveOrder && {
          movesStatusSelections: tollFreeMoveLineTemplate
        })
      }
    })

    const nativeMappedVoiceLines = mapNativeIndexes(currentVoiceLines)

    const filledTollFreeLines = tollFreeVoiceLines.map(line => {
      let ringToLine = null

      if (line.ringToNumberLine) {
        ringToLine = nativeMappedVoiceLines.find(
          ({ lineId }) => lineId === line.ringToNumberLine.lineId
        )
      } else if (line.ringToNumber) {
        ringToLine = nativeMappedVoiceLines.find(
          ({ phoneNumber, nativeName }) =>
            phoneNumber === line.ringToNumber ||
            nativeName === line.ringToNumber
        )
      }

      return {
        ...line,
        ringToNumber: ringToLine?.ringToNumber || '',
        ringToNumberLine: { ...ringToLine }
      }
    })

    const finalFilledTollFreeLines = getFinalMappedTollFreeLines(
      filledTollFreeLines,
      isAdvancedMoveOrder
    )

    return {
      configurationItems: setLineDefaults(finalFilledTollFreeLines)
    }
  }
}

export function selectPortedVoiceLines(state) {
  const { voiceLines } = selectConfiguredVoiceLines(state)

  return voiceLines.filter(line => line.isPorted)
}

export function selectCrcVoiceLines(state) {
  const { voiceLines } = selectConfiguredVoiceLines(state)

  return voiceLines.filter(line => line.isCRC && !line.isPorted)
}

export function selectConfigProviderDetails(state) {
  const { configuredItems } = state.configuration.voice

  return configuredItems.providerDetails || {}
}

export function selectConfigDirectoryListing(state) {
  const { voiceLines } = selectConfiguredVoiceLines(state)

  if (voiceLines.length) {
    const { account, site } = selectCustomerDetails(state)
    const { siteAddress = {} } = site
    const dlLine = voiceLines.find(line => line.isDLN)
    const directoryListing = dlLine.addOnCartItems.find(
      item => item.addOnType === voiceLineItemTypes.directoryListing
    )
    const [hg1Line] = getHuntGroupLines(voiceLines)
    const [hg2Line] = getHuntGroupLines(voiceLines, 'HG2')

    return {
      accountType: 'Business',
      byPassValidation: 'No',
      callBlocking: 'No',
      callerID: 'Yes',
      dlOrderNotes: '',
      dlSpecialInstructions: 'None',
      dlTitle: 'No Selection',
      doNotDisplayAddress: false,
      headingCode: '',
      headerText: '',
      plaDisplayName: '',
      requestIntDialing: false,
      sicCode: '',
      callerIdDisplayName: account?.name.slice(0, 15),
      dlAddress1: siteAddress.address1,
      dlAddress2: siteAddress.address2,
      dlCity: siteAddress.city,
      dlState: siteAddress.state,
      dlZip: siteAddress.postalCode,
      dlDisplayName: account?.name,
      ...directoryListing?.configurationItem,
      dlNumber: dlLine.phoneNumber || dlLine.nativeName,
      huntGroup1PilotNumber: hg1Line?.phoneNumber || hg1Line?.nativeName || '',
      huntGroup2PilotNumber: hg2Line?.phoneNumber || hg2Line?.nativeName || ''
    }
  }

  return null
}

export function selectPrimaryVoiceLine(state) {
  const { voiceLines } = selectConfiguredVoiceLines(state)
  return voiceLines.find(line => line.isPrimaryLine)
}

export function selectDefaultConfigItem(type) {
  return state => {
    const lineItem = selectLineItemByType(type)(state)
    return (
      Object.values(lineItem.configurationItems).find(i => i.isDefault) || false
    )
  }
}

export function selectDowngradableVoiceItems(state) {
  const voiceLineItem = selectLineItemByType(voiceLineItemTypes.voiceLines)(
    state
  )
  const voiceLines = getConfigItemsArray(voiceLineItem)

  const voiceMailLineItem = selectLineItemByType(voiceLineItemTypes.voiceMail)(
    state
  )
  const voiceMail = getConfigItem(voiceMailLineItem)

  const tollFreeLineItem = selectLineItemByType(voiceLineItemTypes.tollFree)(
    state
  )
  const tollFree = getConfigItem(tollFreeLineItem)

  const huntGroupLineItem = selectLineItemByType(voiceLineItemTypes.huntGroup)(
    state
  )
  const huntGroup = getConfigItem(huntGroupLineItem)

  return [...voiceLines, voiceMail, tollFree, huntGroup]
}

export function selectVoiceLineByNumber(number) {
  return state => {
    const configuredVoiceLines = selectConfiguredVoiceLines(state)
    return Object.values(configuredVoiceLines)
      .flat()
      .find(line => line.phoneNumber === number)
  }
}

export function selectAdditionalProgramming(state) {
  const { [configTypes.tv]: items } = state.configuration
  return getAdditionalProgramming(items)
}

export function selectIpnProgrammingLineItems(state) {
  const additionalProgramsLineItems = selectAdditionalProgramming(state)
  const compatibleIds = selectProductCompatibility(
    configTypes.tv,
    videoConfigurationItems.x1
  )(state)

  return additionalProgramsLineItems.filter(lineItem => {
    const configItem = getConfigItem(lineItem)
    return configItem && compatibleIds.includes(configItem.id)
  })
}

export function selectIsVoiceValidated(state) {
  return !!state.configuration?.voice?.validated
}

export function selectIsConfigurationDisabled(state) {
  const types = state?.documents?.types || {}
  const workflowStepId = state.session?.workflowStepId

  if (workflowStepId >= workflowStepIds.CONTRACT_ACCEPTED) {
    return true
  }

  return Object.values(types).some(
    ({ status, id }) =>
      status === documentStatus.approved ||
      (id === documentTypes.send && status === documentStatus.pending)
  )
}

export function selectExistingService(state) {
  return state.order?.existingServices
}

export function selectHasExistingLineOfBusiness(lob) {
  return ({ order }) => {
    return !!order.existingServices?.productDetails?.[lob]
  }
}

export function selectExistingServiceItemById(lob, itemId) {
  return ({ order }) => {
    return order.existingServices?.productDetails?.[lob]?.cartItemDetails?.find(
      item => item.shoppingElementId === itemId
    )
  }
}

export function selectHasExistingServiceItem(lob, itemId) {
  return state => {
    return !!selectExistingServiceItemById(lob, itemId)(state)
  }
}

export function selectAvailableBoltOnLOBs(state) {
  const { configuration } = state
  const { boltOns = [] } = configuration.offer || {}

  const availableBoltOns = boltOns.reduce((lobs, { lineOfBusiness }) => {
    const isServiceable = isLineOfBusinessServiceable(state)(lineOfBusiness)

    if (isServiceable) {
      return !configuration[lineOfBusiness] ? [...lobs, lineOfBusiness] : lobs
    }

    return lobs
  }, [])

  return availableBoltOns.sort((a, b) => {
    return getLobSortWeight(a) - getLobSortWeight(b)
  })
}

export function selectBoltOnByLOB(lineOfBusiness) {
  return state => {
    const { boltOns = [] } = state.configuration.offer || {}

    return boltOns.find(boltOn => boltOn.lineOfBusiness === lineOfBusiness)
  }
}

export function selectIsConfigItemSelfInstallCompatible(configItem) {
  return state => {
    const compatibleIds = selectProductCompatibility(
      configTypes.internet,
      internetConfigurationItems.selfInstallStandard
    )(state)

    return compatibleIds.includes(configItem.id)
  }
}

export function selectIsCbmAddedToConfig(state) {
  return !!state.cart.summary?.additionalServices?.isCbmAdded
}

export function selectHasVoiceConfiguration(state) {
  return !!state.configuration?.voice
}

export function selectIsVoiceComcastTNselected(state) {
  const hasVoice = selectHasVoiceConfiguration(state)
  const { configuredItems } = state.configuration?.voice || {}
  const { voiceLines = [] } = configuredItems?.configuredLines || {}

  if (!hasVoice) {
    return false
  }

  return !!voiceLines?.configurationItems?.some(line => line?.isComcastTN)
}

export function selectComcastTnLines(state) {
  const { voiceLines } = selectConfiguredVoiceLines(state)

  return voiceLines.filter(line => line.isComcastTN)
}

export function selectIsDLnotListedorPublished(state) {
  const directoryListing = selectConfigItemByType(
    voiceLineItemTypes.directoryListing
  )(state)

  return byPassDlnValidationIds.includes(directoryListing.id)
}

export function selectMoveOptionsByLOB(lineOfBusiness) {
  return state => {
    return state.configuration[lineOfBusiness]?.movesStatusSelections || []
  }
}

export function selectHasExistingTelephoneLines(lineOfBusiness) {
  return state => {
    if (lineOfBusiness !== configTypes.voice) {
      return false
    }

    return !!state.configuration[lineOfBusiness]?.configuredItems
      ?.includedNumbers?.ims?.length
  }
}

export function selectNonValidatedMoveVoiceLines(state) {
  const { voiceLines } = selectConfiguredVoiceLines(state)
  return voiceLines.filter(line => !line.isValidated)
}

export function selectNonValidatedPortedMoveVoiceLines(state) {
  const { voiceLines } = selectConfiguredVoiceLines(state)
  return voiceLines.filter(line => !line.isValidated && line.phoneNumber)
}

export function selectMovePartialDisconnectsByLob(lob, isTnGrid) {
  return state => {
    const { movesPartialDisconnectLobs = [] } = state.configuration?.offer || {}
    const DisconnectLob = movesPartialDisconnectLobs.find(
      discLob => discLob.lineOfBusiness === lob
    )

    if (isTnGrid) {
      return DisconnectLob?.telephoneGrid || []
    }
    return DisconnectLob ? DisconnectLob.statuses || [] : []
  }
}

export function selectMoveVoiceTelephoneNumberGrid(lob) {
  return state => {
    if (lob === configTypes.voice) {
      const isTnGrid = true
      const telephoneGrid = selectMovePartialDisconnectsByLob(
        configTypes.voice,
        isTnGrid
      )(state)

      return telephoneGrid
    }
    return []
  }
}

export function selectHasMissingTnLines(state) {
  const { configuration = {} } = state || {}
  if (!configuration?.voice) {
    return false
  }

  const { voiceLines } = selectConfiguredVoiceLines(state)

  return voiceLines?.some(line => !!line.isTNMissing)
}
