export const optionsMap = {
  division: 'divisions',
  region: 'regions',
  corp: 'corps',
  role: 'roles',
  profileId: 'profileId'
}

export const optionsDisplayMap = {
  profileId: 'profile'
}

export const TOGGLE_ERROR_DUPLICATE =
  'There is a duplicate toggle. Please update the toggle and save again.'

export const TOGGLE_ERROR_EMPTY = 'The toggle cannot be empty.'
