import { arrayToObject, generateSequenceArray } from './core'
import {
  voiceItemIds,
  voiceLineItemTypes,
  videoConfigurationItems,
  nonAdditionalProgramTypes,
  lineItemTypeLookups,
  crcpStatus,
  lineItemTypes,
  voiceLineTypes,
  voiceLineGroupTypes,
  configTypes,
  CRCP_ELIGIBLE,
  PORTING_ELIGIBLE,
  ignoredVideoTypes,
  weightedLobs,
  installLineItemTypes,
  VALID_INSTALL_TYPES,
  staticIpJustifications,
  moveVoiceNewLine
} from '../constants/configuration'
import callingAreaDestinations from '../data/callingAreaDestinations'
import { isValidEmail } from './validations/core'

export function findType(lookup, lineItem) {
  const { configurationItems } = lineItem

  // find the first configuration item that is in any of the look ups
  const lookupKey = Object.keys(lookup).find(key =>
    configurationItems.some(item => item.id === key)
  )

  return lookupKey ? lookup[lookupKey] : lineItem.type
}

export function getType(lineItem, category) {
  if (category === 'install') {
    const isValidInstallType = VALID_INSTALL_TYPES.includes(lineItem.type)
    return isValidInstallType ? lineItem.type : installLineItemTypes.proInstall
  }

  // Needed for tv channels because they can have the same type
  if (
    category === 'addOns' &&
    lineItem.lineOfBusiness === configTypes.tv &&
    (!ignoredVideoTypes.some(type => type === lineItem.type) ||
      lineItem.type === 'NONE')
  ) {
    return `${lineItem.type}-${lineItem.configurationItems[0].id}`
  }

  if (lineItemTypeLookups[lineItem.type]) {
    return findType(lineItemTypeLookups[lineItem.type], lineItem)
  }

  return lineItem.type
}

export function configurationParser(configData, isMoveSelected) {
  const boltOnMap = arrayToObject(configData.offer.boltOns, 'lineOfBusiness')

  function mapShoppingTypes(lineItems) {
    return Object.entries(lineItems).reduce(
      (retShoppingTypes, [itemKey, itemValue]) => {
        const itemConfigurations = (itemValue || []).reduce(
          (retItem, item = {}) => {
            let ancillaryOptions = null
            const type = getType(item, itemKey)
            const hasAncillaryPrices = item.configurationItems.some(
              c => !!c.ancillaryPrices?.length
            )
            const isReadOnly = item.configurationItems.some(c => c.isReadOnly)

            if (hasAncillaryPrices) {
              ancillaryOptions = item.configurationItems.flatMap(configItem =>
                (configItem.ancillaryPrices.length
                  ? configItem.ancillaryPrices
                  : [configItem]
                ).map(ancillary => ({ ...ancillary, id: configItem.id }))
              )
            }

            return {
              ...retItem,
              [type]: {
                ...item,
                shoppingType: itemKey,
                configurationItems: mapConfigurationItems(
                  item.configurationItems,
                  itemKey,
                  type
                ),
                type,
                hasAncillaryPrices,
                ancillaryOptions,
                isReadOnly
              }
            }
          },
          {}
        )

        return {
          ...retShoppingTypes,
          ...itemConfigurations
        }
      },
      {}
    )
  }

  function addIncludedQuantity(includedLines, voiceLines) {
    return voiceLines.map(line => {
      return (
        line && {
          ...line,
          configurationItems: {
            ...Object.entries(line.configurationItems).reduce(
              (ret, [key, value]) =>
                includedLines[value.type]
                  ? {
                      ...ret,
                      [key]: {
                        ...value,
                        includedQuantity: includedLines[value.type]
                      }
                    }
                  : { ...ret, [key]: { ...value, includedQuantity: 0 } },
              {}
            )
          }
        }
      )
    })
  }

  function getDefaultConfiguredItems(configuredItems = {}) {
    if (
      configuredItems.e911Notification?.isOptedIn &&
      configuredItems.e911Notification?.isReadOnly
    ) {
      const { email } = configuredItems.e911Notification
      const isEmailValid = isValidEmail(email)

      return {
        ...configuredItems,
        e911Notification: {
          ...configuredItems.e911Notification,
          email: isEmailValid ? email : '',
          isReadOnly: isEmailValid
        }
      }
    }

    return configuredItems
  }

  const configuredCart = configData.cartItems.reduce(
    (ret, data) => {
      const boltOn = boltOnMap[data.lineOfBusiness] && {
        isBoltOn: true,
        allowDisconnect: boltOnMap[data.lineOfBusiness].allowDisconnect
      }

      return {
        ...ret,
        [data.lineOfBusiness]: {
          ...data,
          ...mapShoppingTypes(data.items, data.lineOfBusiness),
          ...boltOn,
          configuredItems: {
            type: lineItemTypes.configuredItems,
            lineOfBusiness: data.lineOfBusiness,
            ...getDefaultConfiguredItems(data.configuredItems)
          }
        }
      }
    },
    { offer: configData.offer }
  )

  function checkComcastTnCRCLines(primaryLine, addtLines) {
    if (!isMoveSelected) {
      return false
    }
    const addtlLinesCheck = addtLines.some(
      addLine => addLine.isComcastTN && addLine.isCRC
    )
    const primaryLineCheck = primaryLine.isComcastTN && primaryLine.isCRC
    return addtlLinesCheck || primaryLineCheck
  }

  function checkVoiceMoveStatusSelections(primaryLine, addtLines) {
    const hasNoMoveStatuses = addtLines.every(
      line => !line.movesStatusSelections
    )
    // false when coming back doc page
    if (!primaryLine.movesStatusSelections || hasNoMoveStatuses) {
      return false
    }

    return true
  }

  function checkHasTnMissingLine(primaryLine, addtLines) {
    const hasTnMissingAdtlLine = addtLines.some(
      addLine => !!addLine.isTNMissing
    )

    return primaryLine.isTNMissing || hasTnMissingAdtlLine
  }

  const configuredVoice = configuredCart[configTypes.voice]
  if (configuredVoice) {
    const { includedLines, primaryLine, additionalLines } = configuredVoice[
      lineItemTypes.configuredItems
    ]
    const { [voiceLineItemTypes.voiceLines]: voiceLines } = configuredVoice
    const transformedVoiceLines = addIncludedQuantity(includedLines, [
      voiceLines
    ])

    const hasComcastTnandCRCSelected = checkComcastTnCRCLines(
      primaryLine,
      additionalLines
    )
    const hasVoiceMoveStatusSelections = checkVoiceMoveStatusSelections(
      primaryLine,
      additionalLines
    )

    const hasTnMissingVoiceLine = checkHasTnMissingLine(
      primaryLine,
      additionalLines
    )

    if (hasVoiceMoveStatusSelections) {
      return {
        ...configuredCart,
        [configTypes.voice]: {
          ...configuredVoice,
          ...arrayToObject(transformedVoiceLines, 'type'),
          configuredItems: {
            ...configuredVoice[lineItemTypes.configuredItems],
            primaryLine: {
              ...primaryLine,
              ...(hasVoiceMoveStatusSelections && {
                attributes: {
                  ...primaryLine.attributes,
                  allowManualInput: primaryLine.isCRC
                }
              })
            },
            additionalLines: additionalLines.map(line =>
              hasVoiceMoveStatusSelections
                ? {
                    ...line,
                    attributes: {
                      ...line.attributes,
                      allowManualInput: line.isCRC
                    }
                  }
                : line
            )
          }
        }
      }
    }

    if (hasComcastTnandCRCSelected) {
      return {
        ...configuredCart,
        [configTypes.voice]: {
          ...configuredVoice,
          ...arrayToObject(transformedVoiceLines, 'type'),
          configuredItems: {
            ...configuredVoice[lineItemTypes.configuredItems],
            primaryLine: {
              ...primaryLine,
              ...(primaryLine.isComcastTN && {
                isComcastTnEditable: true
              })
            },
            additionalLines: additionalLines.map(line =>
              line.isComcastTN
                ? {
                    ...line,
                    isComcastTnEditable: true
                  }
                : line
            )
          }
        }
      }
    }

    /* when move is unselected on doc page and landing on config page
    we have to set comcasttn false for all voicelines */
    if (!isMoveSelected) {
      const updatedAdditionalLines = additionalLines.map(line =>
        line.isComcastTN
          ? {
              ...line,
              isComcastTN: hasTnMissingVoiceLine ? line.isComcastTN : false,
              phoneNumber: hasTnMissingVoiceLine ? line.phoneNumber : '',
              isCRC: hasTnMissingVoiceLine ? line.isCRC : false,
              isComcastTnEditable: !!hasTnMissingVoiceLine
            }
          : line
      )

      return {
        ...configuredCart,
        [configTypes.voice]: {
          ...configuredVoice,
          ...arrayToObject(transformedVoiceLines, 'type'),
          configuredItems: {
            ...configuredVoice[lineItemTypes.configuredItems],
            primaryLine: {
              ...primaryLine,
              ...(primaryLine.isComcastTN && {
                isComcastTN: hasTnMissingVoiceLine
                  ? primaryLine.isComcastTN
                  : false,
                phoneNumber: hasTnMissingVoiceLine
                  ? primaryLine.phoneNumber
                  : '',
                isComcastTnEditable: !!hasTnMissingVoiceLine
              })
            },
            additionalLines: updatedAdditionalLines
          }
        }
      }
    }

    return {
      ...configuredCart,
      [configTypes.voice]: {
        ...configuredVoice,
        ...arrayToObject(transformedVoiceLines, 'type')
      }
    }
  }

  return configuredCart
}

export function mapConfigurationItems(configItems, shoppingType, lineItemType) {
  return configItems.reduce((acc, configItem, _, all) => {
    const duplicateIds = all.filter(a => a.id === configItem.id)
    const useSyntheticId = duplicateIds.length > 1
    const id = useSyntheticId ? getSyntheticId(configItem) : configItem.id
    const hasAncillaryPrices = !!configItem.ancillaryPrices?.length

    return {
      ...acc,
      [id]: {
        isDefault: configItem.isSelected,
        initialQuantity: configItem.quantity,
        existingQuantity: 0,
        lineItemType,
        ...configItem,
        useSyntheticId,
        hasAncillaryPrices,
        shoppingType: shoppingType || configItem.shoppingType
      }
    }
  }, {})
}

export function getSyntheticId(item) {
  return `${item.id}-${item.priceReferenceId}-${item.minLimit}-${item.maxLimit}`
}

export function getConfigItemsArray({ configurationItems: items = [] } = {}) {
  return Array.isArray(items) ? items : Object.values(items)
}

export function toggleIpnLineItems(lineItems, x1ConfigItem) {
  return lineItems.reduce((ret, lineItem) => {
    const configItem = getConfigItem(lineItem)

    return [
      ...ret,
      {
        ...lineItem,
        isDisabled: !x1ConfigItem?.isSelected,
        configurationItems: {
          ...lineItem.configurationItems,
          [configItem.id]: {
            ...configItem,
            ...(!x1ConfigItem?.isSelected && {
              isSelected: false,
              quantity: 0
            })
          }
        }
      }
    ]
  }, [])
}

// Only returns selected item
export function getSelectedConfigItem(lineItem) {
  return getConfigItemsArray(lineItem).find(i => i.isSelected || i.quantity)
}

// Returns item even if it doesn't have a qty
export function getConfigItem(lineItem) {
  if (!lineItem) {
    return null
  }

  const items = getConfigItemsArray(lineItem) || []
  return items.find(i => i.isSelected || i.quantity) || items[0] || null
}

export function getTotalQuantity(item = {}) {
  return (item?.quantity || 0) + (item?.minLimit || 0)
}

export function getSelectedQuantity(item = {}) {
  const quantity =
    typeof item.cartQuantity === 'number' ? item.cartQuantity : item.quantity
  return item.includedQuantity ? quantity - item.includedQuantity : quantity
}

export function getIncludedMinLimit(item = {}) {
  return item.includedQuantity
    ? item.minLimit - item.includedQuantity
    : item.minLimit
}

export function getIsConfigDisabled(lineItem, configItem) {
  const isIncluded =
    configItem?.isIncluded && configItem?.minLimit === configItem?.maxLimit
  return lineItem?.isDisabled ?? isIncluded
}

export function getAllSelectedConfigItems(lineItem) {
  return getConfigItemsArray(lineItem).filter(i => i.isSelected && i.quantity)
}

export function getLobSortWeight(lob) {
  return weightedLobs[lob]
}

export function sortLinesOfBusiness(lobs = []) {
  return lobs.sort((a, b) => getLobSortWeight(a) - getLobSortWeight(b))
}

export function sortConfigItemsByPrice(items) {
  return items.sort((configItemA, configItemB) => {
    return (
      (configItemB.monthlyFinalPrice || configItemB.oneTimeFinalPrice) -
      (configItemA.monthlyFinalPrice || configItemA.oneTimeFinalPrice)
    )
  })
}

// VOICE
export function getConfiguredVoiceLines(configuredItems) {
  const lines = [
    { ...configuredItems.primaryLine, isPrimaryLine: true },
    ...configuredItems.additionalLines
  ]
  const linesByType = Object.values(voiceLineTypes).reduce(
    (lineMap, type) => ({
      ...lineMap,
      [type]: lines.filter(line => line.lineType === type)
    }),
    {}
  )

  const tollFreeLines = lines
    .flatMap(line =>
      line.addOnCartItems.map(addOn => ({
        ...addOn,
        parentNumber: line.phoneNumber || ''
      }))
    )
    .reduce(
      (addOns, addOn) =>
        addOn.id === voiceItemIds.tollFreeLine
          ? [
              ...addOns,
              {
                ...addOn,
                ...addOn.configurationItem,
                lineType: voiceLineTypes.tollFreeLine,
                ringToNumber:
                  addOn?.configurationItem.ringToNumber || addOn.parentNumber
              }
            ]
          : addOns,
      []
    )

  return {
    [voiceLineGroupTypes.voiceLines]: [
      ...linesByType[voiceLineTypes.mobility],
      ...linesByType[voiceLineTypes.fullFeatured],
      ...linesByType[voiceLineTypes.basicLine]
    ],
    [voiceLineGroupTypes.tollFree]: tollFreeLines
  }
}

export function mapVoiceLineGroups(configuredLines) {
  return Object.values(configuredLines).reduce(
    (lines, line) => ({
      ...lines,
      [line.voiceLineGroup]: line.configurationItems
    }),
    {}
  )
}

export function getLastNativeIndex(lines) {
  return lines.filter(line => !line.phoneNumber).length
}

export function mapNativeIndexes(lines, startIndex = 0) {
  let index = startIndex
  return lines.map(line => {
    if (line.phoneNumber || line.isPorted) {
      return line
    }

    index += 1
    return {
      ...line,
      nativeIndex: index,
      nativeName: `Native ${index}`
    }
  })
}

export function fillVoiceLines({
  configItem = {},
  lines = [],
  count = 0,
  template = {}
}) {
  return generateSequenceArray(count).map((_, index) => {
    const line = lines[index] || template
    const phoneNumber = line.phoneNumber || line.tollFreeNumber || ''
    return {
      ...template,
      initialPhoneNumber: line.initialPhoneNumber || phoneNumber,
      ...line,
      phoneNumber,
      name: line.name || configItem.name,
      priceReferenceId: line.priceReferenceId || configItem.priceReferenceId,
      id: line.id || configItem.id,
      lineId: `${configItem.id}-${index}`,
      isSelected: true
    }
  })
}

export function getHGIndex(huntGroup) {
  return Number(huntGroup.split('-')[1])
}

export function getHuntGroupLines(lines, type = 'HG1') {
  return lines
    .filter(({ [type]: hg }) => !!hg)
    .sort((a, b) => getHGIndex(a[type]) - getHGIndex(b[type]))
}

export function setLineDefaults(lineItems, { hgQty } = {}) {
  return lineItems.map(line => ({
    ...line,
    HG1: hgQty ? line.HG1 : '',
    HG2: hgQty > 1 ? line.HG2 : '',
    npaRateCenterOptions: {},
    attributes: {
      ...line.attributes
    },
    callingAreaDestination: line.callingAreaDestination || [
      callingAreaDestinations[0]
    ]
  }))
}

export function getVoiceLineProviders(lines, providers, defaultAddress) {
  const mappedProviders = arrayToObject(providers, 'providerName')
  const portedLines = lines.reduce(
    (filteredLines, { isPorted, currentProvider, lineType }) => {
      if (currentProvider && isPorted) {
        const isTollFreeLine = lineType === voiceLineTypes.tollFreeLine
        const parsedDefaultAddress = {
          streetAddress1: defaultAddress.address1,
          streetAddress2: defaultAddress.address2,
          city: defaultAddress.city,
          zipCode: defaultAddress.postalCode,
          state: defaultAddress.state
        }

        return {
          ...filteredLines,
          [currentProvider]: {
            ...filteredLines[currentProvider],
            providerName: currentProvider,
            accountNumber:
              mappedProviders[currentProvider]?.accountNumber || '',
            pinNumber: mappedProviders[currentProvider]?.pinNumber || '',
            ...(isTollFreeLine && {
              respAddress: {
                ...parsedDefaultAddress,
                ...mappedProviders[currentProvider]?.respAddress
              }
            }),
            ...(!isTollFreeLine && {
              loaAddress: {
                ...parsedDefaultAddress,
                ...mappedProviders[currentProvider]?.loaAddress
              }
            })
          }
        }
      }

      return filteredLines
    },
    {}
  )

  return Object.values(portedLines)
}

export function mapVoiceLineRateCenters(portedLines, rateCenterDetails) {
  return portedLines.map(portedLine => {
    const rateDetails = rateCenterDetails.find(
      details => details.phoneNumber === portedLine.phoneNumber
    )

    if (!rateDetails) {
      return portedLine
    }

    if (rateDetails.crCpStatus === crcpStatus.CRCP_NOT_ELIGIBLE) {
      return {
        ...portedLine,
        isValidated: false,
        attributes: {
          ...portedLine.attributes,
          error: crcpStatus.CRCP_NOT_ELIGIBLE
        }
      }
    }

    return {
      ...portedLine,
      isCRC: rateDetails.crCpStatus === crcpStatus.CRCP_ELIGIBLE,
      isValidated: true,
      attributes: {
        ...portedLine.attributes,
        ...rateDetails,
        allowManualInput: true, // Needed to display npa/rateCenter
        npa: portedLine.phoneNumber.slice(0, 3),
        nxx: portedLine.phoneNumber.slice(3, 6),
        error: false
      }
    }
  })
}

export function mapComcastTnLineRateCenters(comcastTnLines, rateCenterDetails) {
  const updatedComcastTnLines = mapVoiceLineRateCenters(
    comcastTnLines,
    rateCenterDetails
  )

  if (updatedComcastTnLines.length) {
    return updatedComcastTnLines.map(tnLine => ({
      ...tnLine,
      isComcastTnEditable: true
    }))
  }
  return []
}

export function parseRateCenterDetails(details) {
  return details.reduce(
    (ret, option) => ({
      ...ret,
      [option.npa]: ret[option.npa]
        ? [...ret[option.npa], option.rateCenter].sort()
        : [option.rateCenter]
    }),
    {}
  )
}

export function separateVoiceLines(voiceLines) {
  return voiceLines.reduce(
    (separatedLines, line) =>
      line.isPrimaryLine
        ? { ...separatedLines, primaryLine: line }
        : {
            ...separatedLines,
            additionalLines: [...separatedLines.additionalLines, line]
          },
    { primaryLine: {}, additionalLines: [] }
  )
}

export function getPrimaryLine(configuredLines) {
  return configuredLines.voiceLines.configurationItems.find(
    line => line.isPrimaryLine
  )
}

export function hasIncludedNumbers(configuration) {
  return (
    configuration.voice?.configuredItems?.includedNumbers &&
    Object.values(configuration.voice?.configuredItems?.includedNumbers).some(
      line => line.length
    )
  )
}

export function getPortedLinesSuccessMessage(lines) {
  return lines
    .reduce(
      (message, line) => [
        ...message,
        (line.isCRC ? CRCP_ELIGIBLE : PORTING_ELIGIBLE).replace(
          '<PHONE_NUMBER>',
          line.phoneNumber
        )
      ],
      []
    )
    .join('\n')
}

export function isVoiceSectionContinueDisabled(sectionIndex, targetSectionId) {
  return sectionIndex < targetSectionId && sectionIndex !== 0
}

// TV
// Returns all selected items, needed to cover the TVEquipment/Additional Outlets edge case
export function toggleAdditionalOutlets(
  lineItem,
  x1Selected,
  isLegacyVideoRestrictionEnabled,
  initialAdditionalOutlets
) {
  const totalOutletQty = Object.values(lineItem.configurationItems).reduce(
    (total, item) => total + item.quantity,
    0
  )

  const toggledOutlets = Object.entries(lineItem.configurationItems).reduce(
    (retConfig, [id, configItem]) => {
      const isStbHd = id === videoConfigurationItems.additionalOutletStbHd

      let prevQuantity = 0
      if (isLegacyVideoRestrictionEnabled) {
        const selectedAdditionalOutlets =
          initialAdditionalOutlets?.configurationItems[id]
        prevQuantity = selectedAdditionalOutlets?.quantity
      }
      const finalQuantity = isLegacyVideoRestrictionEnabled
        ? prevQuantity
        : configItem.quantity
      const quantity = x1Selected ? totalOutletQty : finalQuantity

      const shouldToggleOff = x1Selected && !isStbHd
      const isTVBoxDisabled = isStbHd ? !x1Selected : true
      const isLegacyVideoDisabled = isLegacyVideoRestrictionEnabled
        ? isTVBoxDisabled
        : false

      return {
        ...retConfig,
        [id]: {
          ...configItem,
          ...(shouldToggleOff
            ? { isSelected: false, quantity: 0 }
            : { isSelected: !!quantity, quantity }),
          isDisabled: isLegacyVideoDisabled
        }
      }
    },
    {}
  )

  return {
    ...lineItem,
    configurationItems: {
      ...toggledOutlets
    }
  }
}

export function getAdditionalProgramming(tvLineItems = {}) {
  return Object.values(tvLineItems).filter(
    item =>
      item.shoppingType === 'addOns' &&
      !nonAdditionalProgramTypes.includes(item.type)
  )
}

// Smartoffice
export function getNetNewCameraCount(
  camera,
  oppositeCamera = { quantity: 0, existingQuantity: 0 }
) {
  const newCount =
    camera.quantity -
    camera.existingQuantity +
    (oppositeCamera.quantity - oppositeCamera.existingQuantity)

  return newCount > 0 ? newCount : 0
}

export function checkSmartOfficeInCartItems({ cartItems }) {
  return cartItems?.some(
    cartItem => cartItem.lineOfBusiness === configTypes.office
  )
}

// INTERNET
export function checkIsOtherJustification(value) {
  return !!(
    value === staticIpJustifications.other ||
    !Object.values(staticIpJustifications).some(v => v === value)
  )
}

export function getUpdateTollFreeLines(tollFree, lineIds) {
  return lineIds.map(lineId => {
    return tollFree
      .map(tollFreeLine => {
        if (tollFreeLine.ringToNumberLine.lineId === lineId) {
          return tollFreeLine
        }
        return ''
      })
      .filter(Boolean)
  })
}

export function mapMoveVoiceLineRateCenters(moveVoiceLines, rateCenterDetails) {
  const updateMoveVoiceLines = mapVoiceLineRateCenters(
    moveVoiceLines,
    rateCenterDetails
  )

  if (updateMoveVoiceLines.length) {
    return updateMoveVoiceLines.map(vLine => {
      const rateDetails = rateCenterDetails.find(
        details => details.phoneNumber === vLine.phoneNumber
      )
      return {
        ...vLine,
        attributes: {
          ...vLine.attributes,
          ...rateDetails,
          ...(rateDetails && {
            allowManualInput:
              rateDetails?.crCpStatus !== crcpStatus.IN_RATE_CENTER
          })
        }
      }
    })
  }
  return []
}

export function getFinalMappedVoiceLines(mappedLines, isAdvancedMoveOrder) {
  if (!isAdvancedMoveOrder) {
    return mappedLines
  }

  return mappedLines.map(line => {
    const isNewNumberAssigned = line?.movesStatusSelections?.find(
      status => status.id === moveVoiceNewLine.id
    )
    const hasVoiceMoveStatusSelections = mappedLines.some(
      l => l.movesStatusSelections
    )

    return {
      ...line,
      ...(line?.nativeName &&
        line?.nativeIndex &&
        !isNewNumberAssigned &&
        hasVoiceMoveStatusSelections && {
          movesStatusSelections: [
            ...line?.movesStatusSelections,
            {
              id: moveVoiceNewLine.id,
              name: moveVoiceNewLine.name,
              isSelected: true
            }
          ]
        })
    }
  })
}

export function getFinalMappedTollFreeLines(mappedLines, isAdvancedMoveOrder) {
  if (!isAdvancedMoveOrder) {
    return mappedLines
  }

  return mappedLines.map(line => {
    const isNewNumberAssigned = line?.movesStatusSelections?.find(
      status => status.id === moveVoiceNewLine.id
    )
    return {
      ...line,
      ...(!line?.phoneNumber &&
        !isNewNumberAssigned && {
        movesStatusSelections: [
          ...line.movesStatusSelections,
          {
            id: moveVoiceNewLine.id,
            name: moveVoiceNewLine.name,
            isSelected: true
          }
        ]
      })
    }
  })
}
