import * as actions from '../../actions'
import { errors } from '../content'

export default {
  validateSecurityEdgeEmailError: {
    message: errors.invalidEmailForEdgeProduct,
    onOkAction: actions.HANDLE_EDGE_EMAIL_ALERT
  },
  checkStaticIpOptionsSetError: {
    message: errors.staticIpErrorJustification
  },
  validateSecurityEdgeErrorOnSave: {
    message: errors.securityEdgeCompatibility
  },
  checkOtherIpValueError: {
    message: errors.staticIpErrorJustification
  }
}
