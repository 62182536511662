import { LOCATION_CHANGE } from 'connected-react-router'
import { selectRouterMatch } from './selectors/navigation'

export const routerParamsMiddleware = () => next => action => {
  const { type, payload } = action

  if (type === LOCATION_CHANGE) {
    return next({
      ...action,
      payload: {
        ...action.payload,
        location: {
          ...action.payload.location,
          match: selectRouterMatch({ router: payload })
        }
      }
    })
  }

  return next(action)
}

export default [routerParamsMiddleware]
