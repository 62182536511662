import { takeEvery, call, select } from 'redux-saga/effects'
import { LOG_ERROR_MESSAGE } from '../actions/logging'
import { logError } from '../services/logging'

export function* logErrorMessageSaga({ payload }) {
  const { message } = payload
  const { opportunityId } = yield select(({ session }) => session)
  const selectedOffer = yield select(({ offers }) => offers.selectedOffer)

  yield call(logError, message, opportunityId, selectedOffer?.id)
}

function* rootSaga() {
  yield takeEvery(LOG_ERROR_MESSAGE, logErrorMessageSaga)
}

export default rootSaga
