import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../elements/Button'
import Loader from './Loader'

const UploadButton = ({
  children,
  displayMessage,
  onChange,
  onUpload,
  fileSizeLimit,
  file,
  ariaLabel
}) => {
  const inputRef = useRef(null)
  const [fileName, setFileName] = useState(file || 'No File Chosen')
  const [fileLoading, setFileLoading] = useState(false)
  const timeoutRef = useRef(null)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setFileName(file)
  }, [setFileName, file])

  function toggleLoading(loading) {
    if (loading) {
      window.addEventListener('focus', handleOnWindowFocus)
      setFileLoading(true)
      setHasError(false)
      inputRef.current.click()
    } else {
      window.removeEventListener('focus', handleOnWindowFocus)
      setFileLoading(false)
      clearTimeout(timeoutRef.current)
    }
  }

  function handleOnWindowFocus() {
    timeoutRef.current = setTimeout(() => {
      setFileLoading(false)
      setHasError(true)
      setFileName('No File Chosen')
      onChange(null)
    }, 4000)
    window.removeEventListener('focus', handleOnWindowFocus)
  }

  function handleOnChange(e) {
    toggleLoading(false)

    if (e.target.files[0] && !hasError) {
      setHasError(false)
      setFileName(e.target.files[0].name)
      if (onChange) {
        // Let the parent handle the validation
        onChange(e)
      }
      if (e.target.files[0].size < fileSizeLimit && onUpload) {
        // Only call onUpload with valid file size
        onUpload(e)
      }
    } else {
      onChange(null)
      setHasError(false)
      setFileName('No File Chosen')
    }
  }

  return (
    <StyledUploadContainer>
      <StyledButtonContainer>
        <StyledRow>
          <StyledButton
            type="primary"
            onClick={() => {
              toggleLoading(true)
            }}
            disabled={fileLoading}
          >
            <StyledButtonContentContainer>
              <StyledButtonContent>{children}</StyledButtonContent>
              {fileLoading && <StyledLoader
                isFull={false}
                isOverlay={false}
              />}
            </StyledButtonContentContainer>
          </StyledButton>

          <StyledFileName>{fileName}</StyledFileName>
        </StyledRow>
        <StyledRow>
          <StyledDisplayMessage>{displayMessage}</StyledDisplayMessage>
        </StyledRow>
      </StyledButtonContainer>

      <StyledInput
        ref={inputRef}
        type="file"
        onChange={handleOnChange}
        ariaLabel={ariaLabel}
        tabIndex="-1"
      />
    </StyledUploadContainer>
  )
}

UploadButton.propTypes = {
  children: PropTypes.string,
  displayMessage: PropTypes.string,
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  fileSizeLimit: PropTypes.number,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  ariaLabel: PropTypes.string
}

UploadButton.defaultProps = {
  children: 'Upload',
  displayMessage: '',
  file: null,
  fileSizeLimit: 0
}

const StyledButton = styled(Button)`
  position: relative;
`

const StyledFileName = styled.span`
  margin-left: 1rem;
  line-height: 2rem;
`
const StyledUploadContainer = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
`
const StyledInput = styled.input.attrs(props => ({
  ...props,
  'aria-label': props.ariaLabel
}))`
  cursor: inherit;
  display: block;
  width: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
`

const StyledRow = styled.div`
  margin-top: 5px;
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  margin-top: 1rem;
  margin-left: 1rem;
  flex-direction: column;
  justify-content: center;
`
const StyledDisplayMessage = styled.div`
  display: flex;
  flex: 1;
`

const StyledButtonContentContainer = styled.div`
  max-height: 100%;
  padding: 5px;
`
const StyledButtonContent = styled.div`
  display: flex;
  flex: 1;
`
const StyledLoader = styled(Loader)`
  position: absolute;
  right: 5px;
  bottom: 0;
  svg {
    max-height: 20px;
    width: 20px;
    right: -20px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`
export default UploadButton
