import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FeatureSwitch from '../components/core/FeatureSwitch'
import { selectAppType } from '../selectors/app'
import { AppTypeProvider } from '../hooks/useAppType'

export const AppContextContainer = ({
  enabledFeatures,
  disabledEnvs,
  appType,
  children
}) => {
  const featClasses = enabledFeatures.map(f => `feat-${f}`).join(' ')
  const appClassName = `app-type-${appType.toLowerCase()}`

  return (
    <div className={`${appClassName} enabled-features ${featClasses}`}>
      <FeatureSwitch
        features={enabledFeatures}
        disabledEnvs={disabledEnvs}
      >
        <AppTypeProvider appType={appType}>{children}</AppTypeProvider>
      </FeatureSwitch>
    </div>
  )
}

AppContextContainer.propTypes = {
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  disabledEnvs: PropTypes.arrayOf(PropTypes.string),
  appType: PropTypes.string,
  children: PropTypes.node
}

AppContextContainer.defaultProps = {
  enabledFeatures: [],
  disabledEnvs: [],
  appType: ''
}

export const mapStateToProps = state => ({
  enabledFeatures: state.session.enabledFeatures,
  appType: selectAppType(state)
})

export default connect(mapStateToProps)(AppContextContainer)
