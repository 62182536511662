import * as signalR from '@microsoft/signalr'
import { RPC_ORDER_SUBMIT_URL } from '../constants/urls'
import { getEnv } from './env'

const { HOST_ENV } = getEnv()
const LOG_LEVEL =
  HOST_ENV !== 'production' ? signalR.LogLevel.Debug : signalR.LogLevel.Error

export const rpcMessageTypes = {
  initOrderSubmit: 'Subscribe',
  sendOrder: 'SendOrderRequest',
  receiveOrder: 'ReceiveOrderResponse'
}

// Use singleton so we can disconnect from multiple locations in app
export const rpcConnection = (() => {
  let connection = null

  return {
    build(accessToken) {
      if (connection) {
        return connection
      }

      connection = new signalR.HubConnectionBuilder()
        .withUrl(RPC_ORDER_SUBMIT_URL, {
          accessTokenFactory: () => accessToken
        })
        .configureLogging(LOG_LEVEL)
        .build()

      return connection
    },

    start() {
      return connection?.start()
    },

    stop() {
      return connection?.stop()
    },

    on(...args) {
      return connection.on(...args)
    },

    invoke(...args) {
      return connection.invoke(...args)
    }
  }
})()
