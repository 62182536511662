import React from 'react'
import styled from 'styled-components'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route } from 'react-router-dom'
import { hot } from 'react-hot-loader'
import { store } from './store'
import history from './history'
import { GlobalStyle } from './theme'
import { ParentWindowProvider } from './hooks/useParentWindow'
import AppContextContainer from './containers/AppContextContainer'
import BundleBuilderRedirectContainer from './containers/BundleBuilderRedirectContainer'
import AuthorizationContainer from './containers/AuthorizationContainer'
import Head from './components/layout/Head'
import Pages from './pages'
import ThemeContainer from './containers/ThemeContainer'

export const App = () => {
  return (
    <Provider store={store}>
      <ThemeContainer>
        <AppContainer>
          <ConnectedRouter history={history}>
            <ParentWindowProvider>
              <AppContextContainer disabledEnvs={['test', 'local']}>
                <GlobalStyle />
                <BundleBuilderRedirectContainer />
                <AuthorizationContainer />
                <Route
                  path={['*/*/:pageId', '']}
                  component={Head}
                />
                <Pages />
              </AppContextContainer>
            </ParentWindowProvider>
          </ConnectedRouter>
        </AppContainer>
      </ThemeContainer>
    </Provider>
  )
}

const AppContainer = styled.div`
  && {
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: ${props => props.theme.colors?.background};
  }
`

export default hot(module)(App)
