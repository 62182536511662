export const LOCAL_TOKEN = 'EC_AUTH_TOKEN'
export const REG_THEME = 'REG_THEME'
export const DARK_THEME = 'DARK_THEME'
export const ACTION_NOT_SUPPORTED = 'ACTION_NOT_SUPPORTED'

export const workflowStepIds = {
  NOT_STARTED: 0,
  NEW: 100,
  PENDING_CONFIGURATION: 200,
  PENDING_CONTRACT_ACCEPTANCE: 300,
  CONTRACT_ACCEPTED: 400,
  ORDER_PENDING: 450,
  ORDER_SUBMITTED: 500,
  ORDER_REJECTED: 600,
  ORDER_REJECTED_CONFIG: 620,
  ORDER_REJECTED_DOCS: 640
}

export const appTypes = {
  EC: 'EC',
  NC: 'NC'
}

export const themeTypes = {
  regTheme: REG_THEME,
  darkTheme: DARK_THEME
}

export const sourceSystemTypes = {
  salesforce: 'salesforce',
  orionsales: 'orionsales'
}
