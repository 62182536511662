import axios, { CancelToken } from 'axios'
import {
  GET_OFFERS_URL,
  GET_OFFERS_DEFAULT_URL,
  GET_OFFER_VALIDITY_URL
} from '../constants/urls'
import { stringifyQueryString } from '../utils/navigation'

export async function getOfferDefaults({
  sessionId,
  division,
  opportunityId,
  fxbuyflowSessionId
}) {
  try {
    const { data } = await axios.post(GET_OFFERS_DEFAULT_URL, {
      sessionId,
      division,
      opportunityId,
      fxbuyflowSessionId
    })
    return data
  } catch {
    return { preSelectedLob: [], availableContractTerms: [] }
  }
}

let cancelGetOffers = null
export async function getOffers(
  sessionId,
  fxbuyflowSessionId,
  query,
  cancelPrevious
) {
  try {
    const url = `${GET_OFFERS_URL}/${sessionId}/${fxbuyflowSessionId}?${stringifyQueryString(
      query
    )}`

    // If pending request then cancel
    if (cancelGetOffers && cancelPrevious) {
      cancelGetOffers()
    }

    const { data, headers } = await axios.get(url, {
      cancelToken: new CancelToken(cancel => {
        cancelGetOffers = cancel
      })
    })

    // API pagination doesn't work so stop this helps to stop additional requests
    const hasMoreResults = data.length < query.$top
    const currentPage = Number(headers.currentpage)
    const totalPages = Number(headers.totalpages)

    return {
      results: data,
      metadata: {
        totalPages,
        currentPage: hasMoreResults ? totalPages : currentPage
      }
    }
  } catch (error) {
    return {
      results: [],
      metadata: {
        currentPage: 0,
        totalPages: 0
      }
    }
  }
}

export async function getOfferValidity({ offerId, sessionId }) {
  const { data: res } = await axios.post(GET_OFFER_VALIDITY_URL, {
    offerId,
    sessionId
  })

  return res?.data
}
