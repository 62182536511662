import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Authorization from '../components/auth/Authorization'
import * as actions from '../actions'

export const AuthorizationContainer = ({ setSessionData, ...props }) => {
  function handleOnUnauthorized() {
    setSessionData({ isAuthorized: false })
  }

  return (
    <Authorization
      {...props}
      onTokenRefresh={setSessionData}
      onUnauthorized={handleOnUnauthorized}
    />
  )
}

AuthorizationContainer.propTypes = {
  guid: PropTypes.string,
  accessToken: PropTypes.string,
  setSessionData: PropTypes.func
}

export const mapStateToProps = state => ({
  guid: state.session.guid,
  accessToken: state.session.accessToken
})

export const mapDispatchToProps = {
  setSessionData: actions.setSessionData
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizationContainer)
