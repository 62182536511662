import axios from 'axios'

export async function requestWithRetry(req, retries = 1, retryCount = 0) {
  try {
    return await (typeof req === 'string' ? axios.get(req) : axios(req))
  } catch (error) {
    if (retryCount < retries) {
      return requestWithRetry(req, retries, retryCount + 1)
    }

    throw error
  }
}
