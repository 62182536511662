import { getEnv } from '../utils/env'

const { REACT_APP_API_BASE_URL, ...ENV } = getEnv()

function joinPath(path, base = REACT_APP_API_BASE_URL) {
  return base + ENV[`REACT_APP_${path}`]
}

export const API_BASE_URL = REACT_APP_API_BASE_URL

/*---------------------------------------------------------------------------*/
// FEAT TOGGLES
/*---------------------------------------------------------------------------*/
// GET
export const GET_FEAT_ALL_URL = joinPath('GET_FEAT_ALL_PATH')
export const GET_FEAT_DETAILS_URL = joinPath('GET_FEAT_DETAILS_PATH')
export const GET_FEAT_LOCATIONS_URL = joinPath('GET_FEAT_LOCATIONS_PATH')
export const GET_FEAT_ROLES_URL = joinPath('GET_FEAT_ROLES_PATH')
export const GET_FEAT_PROFILES_URL = joinPath('GET_FEAT_PROFILES_PATH')

// POST
export const POST_FEAT_TOGGLE_URL = joinPath('POST_FEAT_TOGGLE_PATH')
export const POST_ENABLED_FEATURES_URL = joinPath('POST_ENABLED_FEATURES_PATH')

// PUT
export const PUT_FEAT_DETAILS_URL = joinPath('PUT_FEAT_DETAILS_PATH')
export const PUT_FEAT_TOGGLE_URL = joinPath('PUT_FEAT_TOGGLE_PATH')

/*---------------------------------------------------------------------------*/
// CUSTOMER DETAILS
/*---------------------------------------------------------------------------*/
// GET
export const GET_CUST_DETAILS_URL = joinPath('GET_CUSTOMER_DETAILS_PATH')
export const GET_CUSTOMER_ORDER_STATUS_URL = joinPath(
  'GET_CUSTOMER_ORDER_STATUS_PATH'
)
export const GET_BANNER_MESSAGES_URL = joinPath('GET_BANNER_MESSAGES_PATH')

// POST
export const POST_CUSTOMER_ACTION_STATUS_URL = joinPath(
  'POST_CUSTOMER_ACTION_STATUS_PATH'
)
export const POST_REFRESH_OPPORTUNITY_DETAILS = joinPath(
  'POST_REFRESH_OPPORTUNITY_DETAILS'
)
export const UPDATE_CUSTOMER_DETAILS_URL = joinPath(
  'UPDATE_CUSTOMER_DETAILS_PATH'
)
export const POST_BBLABELEMAIL_PATH = joinPath(
  'POST_BBLABELEMAIL_PATH'
)
export const POST_PROCESS_BANNER_INTERACTION_URL = joinPath('POST_PROCESS_BANNER_INTERACTION_PATH')



/*---------------------------------------------------------------------------*/
// CART
/*---------------------------------------------------------------------------*/
const cartURL = ENV.REACT_APP_CART_URL

// GET
export const CART_DETAILS_URL = joinPath('GET_CART_DETAILS_PATH', cartURL)
export const GET_SMARTOFFICE_PERMIT_BANNER_URL = joinPath(
  'GET_SMARTOFFICE_BANNER_PATH',
  cartURL
)

// POST
export const GET_CART_CONFIG_URL = joinPath('POST_CART_CONFIG_PATH', cartURL)
export const GET_CBM_CART_CONFIG_URL = joinPath(
  'POST_CBM_CART_CONFIG_PATH',
  cartURL
)
export const SAVE_CART_URL = joinPath('SAVE_CART_PATH', cartURL)
export const SAVE_CONTINUE_CART_URL = joinPath(
  'SAVE_CONTINUE_CART_PATH',
  cartURL
)
export const SAVE_CONTINUE_CBM_CART_URL = joinPath(
  'SAVE_CONTINUE_CBM_CART_PATH',
  cartURL
)
export const SAVE_TO_SALESFORCE_ASYNC = joinPath(
  'SAVE_TO_SALESFORCE_ASYNC',
  cartURL
)
export const SWITCH_VIDEO_OFFER_URL = joinPath(
  'SWITCH_VIDEO_OFFER_PATH',
  cartURL
)
export const POST_CART_UPSELL_CONFIG_URL = joinPath(
  'POST_CART_UPSELL_CONFIG',
  cartURL
)

/*---------------------------------------------------------------------------*/
// CALENDAR
/*---------------------------------------------------------------------------*/
export const CALENDAR_BASE_URL = ENV.REACT_APP_CALENDAR_URL

// GET
export const GET_CALENDAR_URL = joinPath('GET_CALENDAR_PATH', CALENDAR_BASE_URL)
export const GET_CHECK_PREWIRE_ASYNC_URL = joinPath(
  'GET_CHECK_PREWIRE_PATH',
  CALENDAR_BASE_URL
)

/*---------------------------------------------------------------------------*/
// CUST PROFILE
/*---------------------------------------------------------------------------*/
const custProfURL = ENV.REACT_APP_CUST_PROF_URL
// GET
export const GET_CUST_DEBT_URL = joinPath('GET_CUST_DEBT_PATH', custProfURL)
export const POST_SAVE_BAN_URL = joinPath('POST_SAVE_BAN_PATH', custProfURL)

// POST
export const POST_CUST_SERVICES_URL = joinPath(
  'POST_CUST_SERVICES_PATH',
  custProfURL
)
export const POST_CBM_LAUNCH_URL = joinPath('POST_CBM_LAUNCH_PATH', custProfURL)

/*---------------------------------------------------------------------------*/
// Quick Quote
/*---------------------------------------------------------------------------*/
const quickquoteURL = ENV.REACT_APP_QUICKQUOTE_URL

export const POST_QQ_LAUNCH_URL = joinPath('POST_QQ_LAUNCH_PATH', quickquoteURL)

/*---------------------------------------------------------------------------*/
// DOCUMENTS
/*---------------------------------------------------------------------------*/
const documentsBaseURL = ENV.REACT_APP_DOCUMENTS_URL
// GET
export const GET_PDF_URL = joinPath('GET_PDF_PATH', documentsBaseURL)
export const GET_DOCUMENTS_INFO_URL = joinPath(
  'GET_DOCUMENTS_INFO_PATH',
  documentsBaseURL
)
export const GET_DOCUMENT_URL = joinPath('GET_DOCUMENT_PATH', documentsBaseURL)
export const GET_PREVIEW_URL = joinPath('GET_PREVIEW_PATH')
export const GET_MISSING_DOCUMENTS_URL = joinPath(
  'GET_MISSING_DOCUMENTS_PATH',
  documentsBaseURL
)
export const GET_PROJECT_CODES_URL = joinPath(
  'GET_PROJECT_CODES_PATH',
  documentsBaseURL
)

// POST
export const POST_PROPOSAL_URL = joinPath('POST_PROPOSAL_PATH')
export const POST_PDF_GENERATION_URL = joinPath(
  'POST_PDF_GENERATION_PATH',
  documentsBaseURL
)
export const POST_VERIFY_BY_PHONE_URL = joinPath(
  'POST_VERIFY_BY_PHONE',
  documentsBaseURL
)
export const POST_DOCUMENT_URL = joinPath(
  'POST_DOCUMENT_PATH',
  documentsBaseURL
)
export const POST_DELETE_DOCUMENT_URL = joinPath(
  'POST_DELETE_DOCUMENT_PATH',
  documentsBaseURL
)
export const POST_DOCUMENT_ACCEPTANCE_TYPE_URL = joinPath(
  'POST_DOCUMENT_ACCEPTANCE_TYPE_PATH',
  documentsBaseURL
)
export const POST_EXPIRE_DOCUMENTS_URL = joinPath(
  'POST_EXPIRE_DOCUMENTS_PATH',
  documentsBaseURL
)
export const POST_DOCS_SAVE_CONTINUE_URL = joinPath(
  'POST_DOCS_SAVE_CONTINUE_PATH',
  documentsBaseURL
)
export const POST_SIGN_NOW_URL = joinPath('POST_SIGN_NOW_PATH')
export const POST_MOVE_FORM_GENERATION_URL = joinPath(
  'POST_MOVE_FORM_GENERATION_PATH',
  documentsBaseURL
)

/*---------------------------------------------------------------------------*/
// OFFERS
/*---------------------------------------------------------------------------*/
// GET
export const GET_OFFERS_URL = joinPath('GET_OFFERS_PATH')

// POST
export const GET_OFFERS_DEFAULT_URL = joinPath('GET_OFFERS_DEFAULT_PATH')
export const GET_OFFER_VALIDITY_URL = joinPath('GET_OFFER_VALIDITY_PATH')

/*---------------------------------------------------------------------------*/
// ORDER SUBMIT
/*---------------------------------------------------------------------------*/
// POST
export const POST_SUBMIT_UPDATE_SF_URL = joinPath('POST_SUBMIT_UPDATE_SF_PATH')

// GET
export const GET_ORDER_SUBMIT_DETAILS = joinPath('GET_ORDER_DETAILS_PATH')

/*---------------------------------------------------------------------------*/
// WORKFLOW
/*---------------------------------------------------------------------------*/
// POST
export const POST_WORKFLOW_STEP_URL = joinPath('POST_WORKFLOW_STEP_PATH')
export const POST_WORKFLOW_STEP_RESUBMIT_URL = joinPath(
  'POST_WORKFLOW_STEP_RESUBMIT_PATH'
)

/*---------------------------------------------------------------------------*/
// BUNDLE BUILDER
/*---------------------------------------------------------------------------*/
// GET
export const GET_BUNDLE_BUILDER_DATA_URL = joinPath(
  'GET_BUNDLE_BUILDER_DATA_PATH'
)

// POST
export const POST_BUNDLE_BUILDER_DATA_URL = joinPath(
  'POST_BUNDLE_BUILDER_DATA_PATH'
)

/*---------------------------------------------------------------------------*/
// AUTH
/*---------------------------------------------------------------------------*/
// POST
export const POST_AUTH_ORDER_URL = joinPath('POST_AUTH_ORDER_PATH')

/*---------------------------------------------------------------------------*/
// TN MANAGEMENT
/*---------------------------------------------------------------------------*/
// POST
export const POST_TN_MANAGEMENT_DIRECTORY_LISTINGS_URL = joinPath(
  'POST_TN_MANAGEMENT_DIRECTORY_LISTING_PATH'
)
export const POST_TN_MANAGEMENT_NATIVE = joinPath(
  'POST_TN_MANAGEMENT_NATIVE_PATH'
)
export const POST_TN_MANAGEMENT_PORTABLE = joinPath(
  'POST_TN_MANAGEMENT_PORTABLE_PATH'
)

/*-------------------*/
/* LOGGING           */
/*-------------------*/
const loggingBaseURL = ENV.REACT_APP_LOGGING_URL

// POST
export const POST_LOGGING_URL = joinPath('POST_LOG_PATH', loggingBaseURL)

/*-------------------*/
/* RPC               */
/*-------------------*/
const rpcBaseUrl = ENV.REACT_APP_RPC_URL

// POST
export const RPC_ORDER_SUBMIT_URL = joinPath(
  'RPC_ORDER_SUBMIT_PATH',
  rpcBaseUrl
)

/*-------------------*/
/* ORDER MOVE        */
/*-------------------*/

export const GET_ORDER_MOVE_DETAILS_URL = joinPath(
  'GET_ORDER_MOVE_DETAILS_PATH'
)
export const SAVE_ORDER_MOVE_DETAILS_URL = joinPath(
  'SAVE_ORDER_MOVE_DETAILS_PATH'
)
export const SAVE_CONTINUE_BAN_MOVE_URL = joinPath(
  'SAVE_CONTINUE_BAN_MOVE_DETAILS_PATH'
)
export const CANCEL_BAN_MOVE_DETAILS_URL = joinPath(
  'CANCEL_BAN_MOVE_DETAILS_PATH'
)
export const GET_BAN_DETAILS_URL = joinPath('GET_BAN_DETAILS_PATH', custProfURL)
