import React, { useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import featureList from '../../constants/features'
import { FeatureSwitchContext } from '../../context'
import { getEnv } from '../../utils/env'

const Feature = ({ name, fallback, children }) => {
  const { HOST_ENV } = getEnv()
  const { disabledEnvs, features } = useContext(FeatureSwitchContext)
  const feature =
    features.includes(name) || disabledEnvs.includes(HOST_ENV)
      ? children
      : fallback

  return useMemo(() => feature, [feature])
}

const FeatureSwitch = ({ features, disabledEnvs, children }) => (
  <FeatureSwitchContext.Provider value={{ features, disabledEnvs }}>
    {children}
  </FeatureSwitchContext.Provider>
)

Feature.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node
}

Feature.defaultProps = {
  fallback: false
}

FeatureSwitch.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string),
  disabledEnvs: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node
}

FeatureSwitch.defaultProps = {
  features: [],
  disabledEnvs: []
}

FeatureSwitch.features = featureList
FeatureSwitch.Feature = Feature

export default FeatureSwitch
