export const calendarTypes = {
  prewire: 'prewire',
  cddd: 'cddd',
  doi: 'doi',
  install: 'install',
  escalation: 'escalation'
}

export const timeSlots = [
  {
    id: 'AM',
    time: 'AM'
  },
  {
    id: 'PM',
    time: 'PM'
  },
  {
    id: 'ANY',
    time: 'ANY'
  }
]

export const NO_CALENDAR_DATES = 'NO_CALENDAR_DATES'

/* ABT */
export const ABT_REQUIRED = 'ABT_REQUIRED'
export const ABT_ADDRESS_CREATE_SUCCESS = 'ABT_ADDRESS_CREATE_SUCCESS'
export const ABT_ADDRESS_CREATE_FAILURE = 'ABT_ADDRESS_CREATE_FAILURE'

/* GENESIS */
export const GENESIS_IHAT = 'GENESIS_IHAT'
